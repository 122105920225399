.guide-hero-section {
    background-color: #0384FB;
    background-size: cover;
    background-position: center;
    height: 50vh;
    display: flex;
    align-items: center;
    color: white;
}

.guide-hero-section-content {
    padding: 20px; 
    border-radius: 8px;
    width: 50%;
    padding-left: 100px;
}

.guide-hero-section-content h1 {
    font-size: 3em;
    margin: 0;
}

.guide-hero-section-content p {
    font-size: 1.2em;
    margin: 10px 0;
}

.guide-hero-section-content button {
    background-color: white;
    color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    border-radius: 4px;
}

.guide-hero-section-content button:hover {
    background-color: #e2effd;
}

.guide-content-container {
    padding: 20px;
    width: 1000px;
    margin: 30px auto;
}

.guide-content-header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.guide-content-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.guide-content-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 30%;
    padding: 20px;
    box-sizing: border-box;
    transition: border-bottom 0.3s ease;
}

.guide-content-card:hover {
    border-bottom: 2px solid #007bff;
}

.guide-content-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.guide-content-card-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.guide-content-card-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
}

.guide-content-card-title {
    color: #00263e;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 12px;
    margin-top: 0;
    padding:  0 20px;
}

.guide-content-card-description {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 26px;
    margin-top: 0;
    padding:  0 20px;
}

.guide-content-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.guide-content-page-button,
.guide-content-next-button {
    background-color: #95c7fd;
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.guide-content-page-button.active,
.guide-content-page-button:hover,
.guide-content-next-button:hover {
    background-color: #2b92ff;
}

.guide-content-next-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
