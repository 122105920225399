.job-filter {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.job-filter-input {
  font-size: 16px;
  cursor: pointer;
  border: 1px solid rgb(3 132 251);
  border-radius: 4px;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(0 38 68);
  background-color: white;
  width: 180px;
  text-align: left;
  padding-left: 10px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.job-filter-input::placeholder {
  color: rgb(0 38 68);
}

/* .job-filter-input:focus {
    border: 1px solid rgb(3, 132, 251);
    outline: none;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  } */

.job-filter-menu {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 1000;
  display: block;
  width: 250px;
  max-height: 200px;
  /* Adjust height as needed */
  overflow-y: auto;
  padding: 5px 0;
  margin: 2px 0 0;
  margin-top: 0px;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid rgb(3, 132, 251);
  border-radius: 4px;
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */
  color: rgb(0 0 0 / 95%);
  font-weight: 400;
}

.job-filter-menu span {
  color: rgb(3 132 251);
  font-size: 13px;
}

.job-filter-menu::-webkit-scrollbar {
  width: 8px;
  /* Adjust width as needed */
}

.job-filter-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.job-filter-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.job-filter-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.job-filter-item {
  padding: 16px;
  cursor: pointer;
}

.job-filter-item:hover {
  background-color: #f5f5f5;
}

.job-filter:hover .job-filter-input,
.job-filter:hover .dropdown-icon {
  color: white;
  /* Change to the color you want */
  cursor: pointer;
}

.job-filter:hover .job-filter-input {
  background-color: #007bff;
}

.job-filter-input.clicked {
  animation: clickAnimation 0.3s ease;
}

@keyframes clickAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width:938px) {
  .job-filter {
    margin-right: 60px;
  }
  .job-filter-menu {
    width: 230px;
    top: 52px;
  }
  .job-filter-item {
    padding-left: 10px;
  }
}