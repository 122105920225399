.admin-panel-auction-list {
  font-family:"Roboto", Helvetica, Arial, sans-serif;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
  margin: 0 auto;
  margin-top: 20px;
  width: 95%;
  padding-top: 20px;
  margin-bottom: 20px;
}
.admin-panel-auction-list-container{
  width: 95%;
  margin: 0 auto;

}
.admin-panel-filters label{
  font-size: 1rem !important;
  font-weight: bold;
}
.admin-panel-filters {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.admin-filter-button-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.admin-panel-filters input,
.admin-panel-filters select {
  padding: 12px!important;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
}

.admin-panel-filters div {
  width: 30%;
  min-width: 250px;
  margin-bottom: 10px;
}

.admin-panel-filters input {
  width: 96%;
}

.admin-panel-filters input:focus {
  border: 2px solid #696cff;
  /* Change border color on focus */
  outline: none;
  /* Remove default outline */
}

.admin-panel-filters select {
  width: 96%;
  cursor: pointer;
}
.admin-panel-filters select:focus{
  border: 2px solid #696cff;
}
.admin-panel-reset-btn,
.admin-panel-search-btn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  font-weight: 600;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(120, 122, 119, 0.4);    
}

.admin-panel-reset-btn {
  background: #6c757d;
  color: #ffffff;
}

.admin-panel-search-btn {
  background: #28a745;
  color: #ffffff;
}

.admin-panel-auction-list table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
}

.admin-panel-auction-list thead {
  background: #f8f9fa;
}

.admin-panel-auction-list th,
td {
  padding: 12px;
  border: 1px solid #dee2e6;
  border-left: none;
  border-right: none;
  min-width: 160px;
}

.admin-panel-auction-list th {
  text-align: left;
}
/* .admin-select-option-label{
  padding-left: 10px;
} */
.admin-panel-table-container{
  overflow-x: auto;
}
.admin-panel-table-container::-webkit-scrollbar {
  display: none;
}