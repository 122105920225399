/* General Navbar Styles */
#bigger-screen-navbar {
  background: none;
  position: fixed;
  z-index: 999;
  height: 80px;
  width: 100%;
}

.icon-margin {
  margin-left: 10px;
  /* Adjust the value to your desired space */
}

.nav-bar-menu-header {
  font-size: 16px;
  font-weight: 500;
  color: #f1f1f1;
}

.nav-sell-tag {
  color: #f1f1f1;
  text-decoration: none;
  font-size: 16px !important;
  font-weight: 500 !important;
}

/* Navbar when hovered or scrolled */
.nav-sell-tag:hover {
  color: #f1f1f1;
  text-decoration: none;
}

nav.nav-hovered {
  background: white;
}

nav.nav-hovered .navbar-item,
nav.nav-hovered .navbar-item a,
nav.nav-hovered .login,
nav.nav-hovered .nav-bar-menu-header,
nav.nav-hovered .nav-sell-tag {
  color: black ;
}

nav.nav-hovered .Sign-Up a {
  color: rgb(218, 0, 0) !important;
}

/* Wrapper styles */
#bigger-screen-navbar .wrapper {
  max-width: 100%;
  margin: auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav.wrapper {
  max-width: 100%;
  margin: auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Logo styles */
.logo-small {
  height: 30px;
  width: auto;
  padding-top: 0px;
}

.wrapper .logo a {
  text-decoration: none;
}

/* Navbar Menu styles */
.navbar-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 180px;
}

.navbar-item {
  position: relative;
  margin: 0 20px;
  cursor: pointer;
}

.navbar-item a {
  text-decoration: none;
  color: #f1f1f1;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

/* Hover state */
/* .navbar-item:hover a {
  color: #0384FB !important;
  text-decoration: none;
} */

/* Active state */
/* .navbar-item.active a {
  color: #0384FB !important;
  text-decoration: none;
} */

/* Navbar Actions styles */
.navbar-actions a {
  margin: 0 10px;
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
}

.navbar-actions .login:hover {
  color: blue;
}

.navbar-actions .Sign-Up {
  color: #fff;
  background: #0384FB;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-actions .Sign-Up:hover {
  background: #6ab3f8;
}

/* Dropdown Menu styles */
.dropdown-menu {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.dropdown-content {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  z-index: 970px;
}

@media (max-width: 768px) {
  .dropdown-content {
    flex-direction: column;
  }
}

/* Login & SignUp styles */
.login-signUp {
  font-size: 14px;
  font-weight: 500;
  width: 220px;
  /* display: flex;
  justify-content: center; */
}

.login-signUp .login {
  color: #fff;
  padding-right: 20px;
}

/* Scrolled state styles */
.nav-scrolled {
  background-color: white;
  color: black;
}

.nav-scrolled .login {
  color: #333;
}

.nav-scrolled .Sign-Up {
  color: #F0F6FE;
}

/* Additional Content styles */
.content-2 {
  display: flex;
  gap: 10px;
}

.content-2 div {
  width: 32%;
  line-height: 20px;
}

.content-2 div h5 {
  color: #979797;
  font-size: 13px;
  font-weight: 450;
  letter-spacing: 1px;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 55px;
}

.content-2 div img {
  width: 100%;
  height: 200px;
}

.content-2 p {
  width: 100%;
  color: rgb(3, 132, 251);
  cursor: pointer;
}

.content-2 span {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #00263e;
}

/* Responsive styles */
@media screen and (max-width: 916px) {
  .login-signUp {
    padding-right: 30px;
  }

  .wrapper .nav-links {
    padding-left: 170px;
  }
}

@media all and (max-width: 1027px) {
  .menu-icon {
    display: block;
  }

  .content-2 p {
    width: auto;
    color: rgb(3, 132, 251);
    cursor: pointer;
  }

  .resources-mega-box {
    position: absolute;
    top: 80px;
    background-color: aliceblue;
    width: 100%;
    height: 80vh;
    left: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  .resources-mega-box .content {
    line-height: normal;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .resources-mega-box .content-2 {
    width: 91%;
    margin: 0 auto;
  }

  .nav-links li a {
    color: #00263e;
  }
}

@media all and (max-width: 700px) {
  .content-2 div img {
    height: 150px;
  }

  .content-2 div h5 {
    font-size: 10px;
  }
}

@media all and (max-width: 495px) {
  .content-2 {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .content-2 div {
    width: 80%;
  }

  .content-2 div img {
    width: 100%;
    height: auto;
  }

  .content-2 div h5 {
    font-size: 15px;
  }
}

/* Style for the drop menu and its items */
.type-of-auctions {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 40px;
}

.type-of-auctions:hover {
  background: #F0F6FE;
}

.type-of-auctions .nav-drop-header {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 30px 0 20px 0;
  color: #00263e;
  border-bottom: 1px solid rgb(202, 202, 202);
}

.type-of-auctions:hover .nav-drop-header {
  border-bottom: 1px solid rgb(0, 0, 0);
}

.type-of-auctions .nav-drop-items {
  display: flex;
  flex-direction: column;
  padding: 10px 0 40px 0;
}

.nav-drop-items .nav-drop-item {
  text-decoration: none;
  color: #00263e;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
}

.nav-drop-items .nav-drop-item:hover {
  color: #0384FB;
}

.navbar-dropdown {
  position: relative;
  display: inline-block;
}

.navbar-dropdown-button {
  background-color: transparent;
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-bottom: 5px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

/* .navbar-dropdown-button:hover {
  background-color: rgb(60, 157, 248);
} */

.navbar-dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right:0px;
  top: 50px;
}

.navbar-dropdown-content a {
  padding: 12px 16px;
  color: #00263e;
  font-size: 16px;
  font-weight: 400;
  display: block;
}

.navbar-dropdown-content a:hover {
  background-color: #f1f1f1;
}

.navbar-dropdown-content.show {
  display: block;
  font-weight: 400;
  font-size: 14px;
  z-index: 1000;
}

a {
  cursor: pointer;
  cursor: pointer;
}