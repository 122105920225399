.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
}

.carousel-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* Adjust the height as needed */
  cursor: pointer;
  border-radius: 10px;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.exit-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 0, 0, 0.8);
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
  color: white;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}


.modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  user-select: none;
  z-index: 1001;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.slick-slide img {
  display: inline;
}

.map-container {
  width: 90%;
  height: 300px;
  margin-top: 20px;
}

.location-button,
.street-view-button,
.photos-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.location-button:hover,
.street-view-button:hover,
.photos-button :hover {
  background-color: #f0f0f0;
}

.button-container {
  display: flex;
  flex-direction: initial;
  gap: 10px;
  /* Adjust the gap as needed */
}

.button-overlay {
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 60px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
}

.location-button,
.street-view-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.location-button:hover,
.street-view-button:hover {
  background-color: #f0f0f0;
}

.button-container {
  display: flex;
  flex-direction: initial;
  gap: 10px;
  /* Adjust the gap as needed */
}

.photos-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.photo-image {
  margin: 5px;
  width: calc(20% - 10px);
  height: auto;
}

.bottom {
  width: 100%;
}

.fancy-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.fancy-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width:1150px) {
  .button-overlay {
    top: 70%;
  }
}

@media screen and (max-width:1000px) {
  .button-overlay {
    top: 65%;
  }
}

@media screen and (max-width:810px) {
  .button-overlay {
    top: 50%;
  }
}

@media screen and (max-width:680px) {
  .button-overlay {
    top: 60%;
  }
}

@media screen and (max-width:550px) {
  .button-overlay {
    gap: 10px;
    padding: 5px;
  }
}

@media screen and (max-width:500px) {
  .button-overlay {
    top: 40%;
  }
}

@media screen and (max-width:450px) {
  .button-overlay {
    top: 52%;
  }
}
@media screen and (max-width:400px) {
  .button-overlay {
    top: 55%;
  }
}