/* AdminRegistrationList.css - Part 1 */

/* Main Container */
.admin-registrations-container {
    padding: 20px;
    background-color: #f9fafb;
    min-height: calc(100vh - 60px);
    margin-top:20px;
  }
  
  /* Header Styles */
  .admin-registrations-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .admin-registrations-header h1 {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin: 0;
  }
  
  .admin-registrations-actions {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  
  /* Filter Styles */
  .filter-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 0 12px;
  }
  
  .filter-container svg {
    color: #6b7280;
    margin-right: 8px;
  }
  
  .status-filter {
    padding: 8px 12px;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #374151;
    cursor: pointer;
    min-width: 150px;
    appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
  }
  
  .status-filter:focus {
    outline: none;
  }
  
  /* Refresh Button */
  .refresh-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 14px;
    color: #374151;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .refresh-button:hover {
    background-color: #f3f4f6;
  }
  
  .refresh-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  /* AdminRegistrationList.css - Part 2 */

/* Stats Cards */
.admin-registrations-stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-bottom: 24px;
  }
  
  .stat-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-top: 4px solid #6b7280;
  }
  
  .stat-card.pending {
    border-top-color: #f59e0b;
  }
  
  .stat-card.approved {
    border-top-color: #10b981;
  }
  
  .stat-card.rejected {
    border-top-color: #ef4444;
  }
  
  .stat-value {
    font-size: 28px;
    font-weight: 700;
    color: #111827;
    margin-bottom: 4px;
  }
  
  .stat-label {
    font-size: 14px;
    color: #6b7280;
  }
  
  /* Table Styles */
  .admin-registrations-list {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    overflow-x:auto;
    margin-bottom: 24px;
  }
  
  .registrations-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .registrations-table th {
    background-color: #f3f4f6;
    padding: 12px 16px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #374151;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .registrations-table td {
    padding: 12px 16px;
    border-bottom: 1px solid #e5e7eb;
    font-size: 14px;
    color: #374151;
  }
  
  .registrations-table tr:last-child td {
    border-bottom: none;
  }
  
  .registrations-table tr:hover {
    background-color: #f9fafb;
  }
  /* AdminRegistrationList.css - Part 3 */

/* Status Badges */
.status-badge {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 9999px;
    font-size: 12px;
    font-weight: 500;
  }
  
  .status-badge.pending {
    background-color: #fef3c7;
    color: #92400e;
  }
  
  .status-badge.approved {
    background-color: #d1fae5;
    color: #065f46;
  }
  
  .status-badge.rejected {
    background-color: #fee2e2;
    color: #b91c1c;
  }
  
  .status-badge svg {
    margin-right: 4px;
  }
  
  /* Table Cell Formatting */
  .submission-time {
    font-size: 12px;
    color: #6b7280;
    margin-top: 4px;
  }
  
  .user-email {
    font-size: 12px;
    color: #6b7280;
    margin-top: 2px;
  }
  
  /* Action Buttons */
  .view-details-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    background-color: #0284c7;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .view-details-button:hover {
    background-color: #0369a1;
  }
  
  /* Loading and Empty States */
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    color: #6b7280;
  }
  
  .loading-container svg {
    margin-bottom: 16px;
    color: #0284c7;
  }
  
  .no-registrations {
    background-color: #fff;
    padding: 40px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    color: #6b7280;
  }
  
  /* Pagination */
  .pagination-controls {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 20px;
  }
  /* AdminRegistrationList.css - Part 4 */

/* Pagination Buttons */
.pagination-controls button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 1px solid #e5e7eb;
    background-color: #fff;
    color: #374151;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .pagination-controls button:hover {
    background-color: #f3f4f6;
  }
  
  .pagination-controls button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .pagination-controls .page-number {
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #374151;
  }
  
  /* Modal Styles */
  .registration-detail-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  
  .registration-detail-modal {
    background-color: white;
    border-radius: 8px;
    width: 100%;
    max-width: 800px;
    max-height: 90vh;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 15px rgba(0, 0, 0, 0.1);
  }
  
  .registration-detail-modal-header {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .registration-detail-modal-header h2 {
    font-size: 20px;
    font-weight: 600;
    color: #111827;
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #6b7280;
    cursor: pointer;
  }
  
  .registration-detail-modal-content {
    padding: 24px;
    overflow-y: auto;
    max-height: calc(90vh - 180px);
  }
  
  .registration-detail-section {
    margin-bottom: 24px;
  }
  
  .registration-detail-section h3 {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
    margin-top: 0;
    margin-bottom: 12px;
  }
  
  .registration-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9fafb;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 16px;
  }
  
  .registration-date {
    font-size: 13px;
    color: #6b7280;
  }
  
  .registration-status {
    padding: 4px 10px;
    border-radius: 9999px;
    font-size: 13px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 6px;
  }
  
  .registration-status.approved {
    background-color: #d1fae5;
    color: #065f46;
  }
  
  .registration-status.rejected {
    background-color: #fee2e2;
    color: #b91c1c;
  }
  
  .registration-status.pending {
    background-color: #fef3c7;
    color: #92400e;
  }
  
  .registration-auction-info,
  .registration-buyer-info {
    background-color: #f9fafb;
    border-radius: 6px;
    padding: 16px;
  }
  
  .registration-detail-item {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
  }
  
  .registration-detail-item:last-child {
    margin-bottom: 0;
  }
  
  .registration-detail-item .label {
    font-size: 13px;
    color: #6b7280;
    margin-bottom: 4px;
  }
  
  .registration-detail-item .value {
    font-size: 14px;
    color: #111827;
    font-weight: 500;
  }
  
  /* Agreement List */
  .registration-agreements {
    background-color: #f9fafb;
    border-radius: 6px;
    padding: 16px;
  }
  
  .agreement-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .agreement-list li {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #374151;
  }
  
  .agreement-list li svg {
    color: #10b981;
    margin-right: 8px;
    flex-shrink: 0;
  }
  
  /* Modal Actions */
  .registration-detail-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 16px 24px;
    border-top: 1px solid #e5e7eb;
    background-color: #f9fafb;
  }
  
  .approve-button,
  .reject-button,
  .close-modal-button {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .approve-button {
    background-color: #10b981;
    color: white;
    border: none;
  }
  
  .approve-button:hover {
    background-color: #059669;
  }
  
  .reject-button {
    background-color: #ef4444;
    color: white;
    border: none;
  }
  
  .reject-button:hover {
    background-color: #dc2626;
  }
  
  .close-modal-button {
    background-color: white;
    color: #374151;
    border: 1px solid #d1d5db;
  }
  
  .close-modal-button:hover {
    background-color: #f3f4f6;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .admin-registrations-stats {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .registrations-table thead {
      display: none;
    }
    
    .registrations-table, 
    .registrations-table tbody, 
    .registrations-table tr, 
    .registrations-table td {
      display: block;
      width: 100%;
    }
    
    .registrations-table tr {
      margin-bottom: 16px;
      border: 1px solid #e5e7eb;
      border-radius: 6px;
      overflow: hidden;
    }
    
    .registrations-table td {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      text-align: right;
      position: relative;
    }
    
    .registrations-table td::before {
      content: attr(data-label);
      font-weight: 600;
      text-align: left;
      margin-right: 8px;
    }
    
    .agreement-list {
      grid-template-columns: 1fr;
    }
    
    .registration-detail-modal {
      width: 95%;
    }
  }