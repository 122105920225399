.intutive-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.intutive-container h2 {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 48px;
    text-align: center;
    color: #00263e;
}

.animation-container-header {
    font-size: 17px;
    font-weight: 400;
    text-align: left;
}

.intutive-container .animation-container {
    display: flex;
    gap: 10px;
    justify-content: center;
    overflow-x: scroll;
    scrollbar-width: none;
}

.animation-container>div {
    width: 18%;
    height: auto;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    cursor: pointer;
}

.animation-container .animated-line {
    width: 100%;
    height: 5px;
    background-color: rgb(228 228 228);
}

.animation-container p {
    color: #333;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    width: 90%;
}

.animated-line div {
    height: 100%;
    background-color: rgb(3 132 251);
    transition: width 5s ease-in-out;
    width: 0%;
}

p {
    transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

p.hidden {
    visibility: hidden;
    opacity: 0;
}

p.visible {
    visibility: visible;
    opacity: 1;
    text-align: left;
}

@media screen and (max-width:1000px) {
    .animation-container div {
        width: 23%;
    }
}

.intutive-video-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.intutive-video-container img {
    width: 90%;
    height: auto;
}

@media screen and (max-width:830px) {
    .intutive-container .animation-container {
        justify-content: flex-start;
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media screen and (max-width:850px){
    .intutive-animation-and-video-container{
         display: flex;
         flex-direction: column-reverse;
    }
}
@media screen and (max-width:500px){
    .intutive-container h2 {
        font-size: 24px;
    }    
}