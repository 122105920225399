.nav-common-background{
    /* background-image: url("../../assets/images/vihara/hero_pattern-01.webp"); */
    background-size: cover;
    /* Cover the entire area of the div */
    background-position: center;
    /* Center the background image */
    background-repeat: no-repeat;
    background-color: #00263E;
    /* Do not repeat the image */
    height: 60px;
    /* Or the height you desire */
    width: 100%;
    z-index:99999;
    /* position: absolute; */
}