.sellers-containers{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    align-items: center;
}
.sellers-containers h1{
    font-size: 40px;
    line-height: 42px;
    color: #00263e;
    text-align: center;
    font-weight: 450;
    padding-left: 20px;
    padding-right: 20px;
    width: 70%;
}
.circle-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;
}
.circle-container div{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle-one {
    background-color: #0384FB;
    /* Use negative margin to create horizontal overlap */
    margin-right: -40px; /* 20% of the width (200px) */
}

.circle-two {
    background-color: #d14d63cf ;
    z-index: 1; /* This makes sure it's on top of the first and third circles */
    /* Use negative margin to create horizontal overlap */
    margin-right: -40px; /* 20% of the width (200px) */
}

.circle-three {
    background-color: #e3d951;
    z-index: 0; /* This keeps it behind the second circle */
}
.description-container{
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.description-container div{
    width: 45%;
}
.description-container h3{
    text-transform: uppercase;
    font-size: 25px;
}
.description-container p{
    color: #353535;
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
}
.description-1{
    color:#0384FB ;
}
.description-2{
    color:#6ac8ec ;
}
.circle-container h3{
    font-size: 25px;
    color: #f2f8f0;
}
@media all and (max-width:500px){
    .sellers-containers h1{
       font-size: 25px;
    }
    .circle-container div{
        width: 170px;
        height: 170px;
    }
}
@media screen and (max-width:400px){
    .circle-container div{
        width: 145px;
        height: 145px;
    }
}