.property-details-main {
    margin: auto;
    font-family: Nunito, "Helvetica Neue", helvetica, arial, sans-serif;
    /* padding: 0;
    margin: 0; */
    width: 85%;
    margin-bottom: 20px;
}

/* ============================= 
======PropertyAddress========== 
================================*/

.property-address-main-details {
    width: 100%;
    height: fit-content;
    margin-top: 20px;
    display: flex;
    margin-bottom: 20px;


}

.circle-dot {
    color: rgb(23, 155, 23);
    font-size: 12px;
}

.circle-dot-boundary {
    margin-top: 5px;
}

.type-of-property {
    padding-left: 10px;
    font-size: 16px;
}

.property-street-address {
    color: #5c676e;
    font-size: 30px;
    font-weight: 400;
    margin: 0;
}

.property-couty-and-state {
    color: #5c676e;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    /* margin-top: 3px; */
}

.user-and-save {
    color: #5c676e;
}

.property-deatial-main-save-and-details,
.property-deatial-main-address-and-type {
    width: 50%;
}

.property-deatial-main-save-and-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;


}

.user-and-save {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

}

.property-save {
    color: rgb(230, 73, 73);
}

.property-save-heart {
    font-weight: 500;
    margin-left: 5px;
}

.property-views {
    margin-right: 20px;
    display: flex;
    gap: 5px;
}

.propetry-batroom-room-and-other {
    color: #5c676e;
    font-size: 20px;
    margin-top: 10px;
}

.bed-divider {
    font-weight: 200;
    /* padding-left: 5px; */
    padding-right: 10px;
}

.area-unit {
    font-size: 14px;
}

.property-type-sale {
    display: flex;
    align-items: center;
    padding-top: 0px;
}

.info-icon {
    color: #5c676e;
    font-size: 12px;
    padding: 2px;
    padding-left: 5px;

}


/* =================================
==//Property Basic Details==========
==================================== */
.property-image-and-insites {
    width: 85%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 0 auto;
    margin-top: 127px;
    scroll-margin-top: 187px;
    position:relative;
}

.propetry-image-slide-show {
    width: 60%;
    height: 100%;
    border-radius: 10px;
}

.propetry-image-slide-show-image {
    width: 100%;
    height: 100%;
}

/* .property-detials-property-instites{
    padding-left: 20px;

} */

.price-insite-head {
    color: #5c676e;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.286em;
    font-family: Nunito, "Helvetica Neue", helvetica, arial, sans-serif;
    margin: 0;
}


.property-realestate-vales {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 12px;
    margin-top: 25px;
    border-bottom: 1px solid rgb(187, 187, 187);
}

.Est-price-value {
    color: #5c676e;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.286em;
    font-family: Nunito, "Helvetica Neue", helvetica, arial, sans-serif;
    margin-bottom: 10px;
}

.compare-est-price {
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;

}

.see-comparable-sales {
    color: #4183c4;
    text-decoration: none;

}

.property-price-source {
    line-height: 1.286em;
    font-family: Nunito, "Helvetica Neue", helvetica, arial, sans-serif;
    margin-bottom: 6px;
    font-style: italic;
    font-size: 12px;
}

.open-bid-price-and-tag {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid rgb(187, 187, 187);
}


/* ========================================
=============PROPERTY DETAIL AND BIDDING ===
++++++++++++++++++++++++++++++++++++++++++++ */


.man-background-property-detail {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecee;
    border-top: 1px solid #e9ecee;
    padding-top: 41px;
    padding-bottom: 48px;
    margin: auto;
    color: #263238;
    display: flex;
    justify-content: center;
    /* padding-left: 20%; */
    height: 600px;
    font-family: "Nunito", "Helvetica Neue", helvetica, arial, sans-serif;
    padding-left: 10%;
    padding-right: 10%;
}

.property-table-heading {
    color: #263238;
    font-size: 24px;
    font-weight: 600;
}

.propert-detail-card {
    width: 80%;
}

/* .property-detail-table
{
    

    
}
.property-detail-table-grid{
    
} */
.table-column-detail {
    padding: 16px 0;
    border-bottom: 1px solid rgb(218, 218, 218);
    display: flex;
    width: 90%;
}

.propety-asset {
    width: 50%;
    display: flex;
}

.property-asset-detail,
.property-asset-value {
    width: 50%;
    line-height: 1.286em;
    color: #5c676e;
    font-size: 16px;
    font-family: Nunito, "Helvetica Neue", helvetica, arial, sans-serif;
    word-break: break-word;
}

.property-asset-detail {
    font-weight: 400;
}

.property-asset-value {

    font-weight: 700;


}



/* 
=======================================
========inperson-auction-card==========
======================================= */

.inperson-auction-card {
    width: 350px;
    height: 450px;
    /* border: 1px solid green; */
    /* position: absolute;
    top: 85%;
    left: 55%; */
    margin-top: -120px;
    z-index: 1;
    border: 1px solid rgba(34, 36, 38, .15);
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15);
}

.inperson-upper-section {
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: fit-content;
    border-bottom: 1px solid rgba(34, 36, 38, .15);
}

.inperson-heading {
    color: #5c676e;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.286em;
    font-family: Nunito, "Helvetica Neue", helvetica, arial, sans-serif;
    margin-bottom: 16px;
    margin-top: 18px;
}

.save-for-update-button {
    background-color: #036eb5;
    border-color: #036eb5;
    padding: 12px 36px;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    text-transform: none;
    box-shadow: none;
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
}

.why-save-tag {
    color: #036eb5;
    font-size: 16px;
    text-decoration: none;
    font-weight: 400;
    line-height: 1.286em;
    -webkit-font-smoothing: antialiased;
    padding: 10px 0;
    font-family: Nunito, "Helvetica Neue", helvetica, arial, sans-serif;
}

.auction-detail-section {
    width: 100%;
    min-height: 300px;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.prepare-for-the-auction {
    color: #036eb5;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.286em;
    font-family: Nunito, "Helvetica Neue", helvetica, arial, sans-serif;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 10px;

}

.propert-auction-date {
    display: flex;
    justify-content: space-between;
}

.property-date-auction {
    width: 70%;
    align-items: flex-end;
}

.date-section-bidding {
    margin: 0 15px;
    padding: 20px 0;
    border-bottom: 1px solid rgb(182, 182, 182);

}

.auction-start-time {
    display: flex;
    /* width: 100%; */
    justify-content: space-between;
    padding: 10px 0;
    margin: 0px 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(192, 192, 192);
}

.loaction-border-set {
    margin: 0 15px;

}

.auction-location-detail {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    box-sizing: inherit;
    color: #036eb5;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.286em;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Nunito, "Helvetica Neue", helvetica, arial, sans-serif;

}

.property-auction-location-container {
    text-align: center;
    margin-bottom: 10px;
}

.property-detail-auction-container {
    border: 1px solid #d1deea;
    border-radius: 10px;
    width: 35%;
    overflow: hidden;
}

.property-detail-auction-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

.property-detail-auction-timer {
    display: flex;
    justify-content: space-between;
}

.property-detail-auction-timer div {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
}

.two-dots h4 {
    color: #000 !important;
}

.property-detail-auction-timer h4 {
    margin: 0;
    color: #118BF4;
    font-size: 24px;
    font-weight: 500;
}

.property-detail-auction-bid-info {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    align-items: flex-start;
}

.property-detail-auction-bid-info h4 {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.property-detail-highest-bid {
    color: #333;
    font-size: 24px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin-top: 15px;
}

.property-detail-view-bids {
    text-decoration: none;
    border: none;
    color: #118BF4;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    margin-left: 5px;
}

.property-detail-auction-details {
    margin-top: 20px;
}

.property-detail-auction-details div {
    display: flex;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 15px;
}

.property-detail-auction-detail-value {
    float: right;
}

.property-detail-bid-controls {
    margin-top: 20px;
    display: flex;
    align-items: center;
    border: 1px solid #D1DEEA;
    padding: 10px;
    border-radius: 5px;
}

.property-detail-bid-input {
    text-align: center;
    width: 100px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 550;
    color: #333;
}

.property-detail-bid-controls button {
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 550;
}

.property-detail-place-bid {
    margin-top: 20px;
}

.property-detail-auction-timer-container {
    background-color: #F0F4F8;
    padding: 20px;
    padding-top: 10px;
}

.property-detail-place-bid-button {
    background-color: rgb(3 132 251);
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    font-weight: 550;
    padding-left: 15px;
    padding-right: 15px;
}

.submit-checkout-form-button {
    width: 90%;
    padding: 12px;
    background-color: rgb(3 132 251);
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 550;
    margin: 0 auto;
    margin-top: 12px;
    margin-bottom:12px;
    cursor: pointer;
    text-transform: uppercase;
}

.property-detail-dynamic-bidding-info {
    margin-top: 10px;
    margin-bottom: 20px;
}

.property-detail-dynamic-bidding-link {
    text-decoration: underline;
    padding-left: 22px;
    color: #118BF4;
    font-size: 12px;
    font-weight: 500;
}

.dotted-border {
    border-bottom: 1px dashed;
}
.dotted-border div{
     padding:5px;
}
.property-detail-auction-details-tooltip {
  position: absolute;
  top: calc(100% - 150px);
  right: -20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 35%;
  z-index: 1000;
  padding: 5px;
  font-size:10px;
}

.property-detail-auction-details-tooltip::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 20px;
  width: 14px;
  height: 14px;
  background-color: white;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  transform: rotate(45deg);
}

.property-detail-auction-details-tooltip-content {
  line-height: 1.5;
  color: #333;
  font-size: 14px;
}
.input-place-bid-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.property-condition-container {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    gap: 30px;
}

.property-detail-page-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 10px; */
    margin-top: 20px;
    width: 60%;
}

.property-detail-page-status {
    margin-bottom: 8px;
}

.property-detail-page-status-badge {
    background-color: rgb(0 0 0 / 50%);
    color: white;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
}

.property-detail-page-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.property-detail-page-address {
    font-size: 20px;
    margin: 0;
    margin-bottom: 8px;
    font-weight: bold;
}

.property-detail-page-location {
    font-size: 14px;
    color: #555;
    margin-bottom: 16px;
}

.property-detail-page-get-directions {
    color: #007bff;
    text-decoration: none;
    margin-left: 10px;
}

.property-detail-page-stats {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.property-detail-page-stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
}

.property-detail-page-stat-value {
    font-size: 20px;
    font-weight: bold;
}

.property-detail-page-stat-label {
    font-size: 12px;
    color: #777;
}

/* Container styling */
.property-detail-page-alert-container {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    width: 30%;
    font-family: Arial, sans-serif;
    margin-top: 20px;
    height: 80px;
}

.property-detail-page-alert-container h4 {
    margin-bottom: 0px;
    margin-top: 10px;
}

.property-detail-page-alert-container p {
    margin-bottom: 10px;
}

/* Toggle switch container */
.property-detail-page-toggle-switch {
    width: 50px;
    height: 25px;
    background-color: #ccc;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Toggle knob styling */
.property-detail-page-toggle-knob {
    width: 21px;
    height: 21px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left 0.3s ease;
}

/* Styles when the toggle is on */
.property-detail-page-toggle-switch.on {
    background-color: green;
}

.property-detail-page-toggle-switch.on .property-detail-page-toggle-knob {
    left: 27px;
}

/* Styles when the toggle is off */
.property-detail-page-toggle-switch.off {
    background-color: #ccc;
}

.property-detail-page-toggle-switch.off .property-detail-page-toggle-knob {
    left: 2px;
}

.property-detail-page-alert-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 33%;
}

.property-detail-page-description {
    width: 100%;
}

.property-detail-page-description h4,
p {
    font-size: 16px;

}

.property-detail-page-full-details-container {
    display: flex;
    justify-content: space-between;
    width: 85%;
    font-family: Arial, sans-serif;
    gap: 40px;
    margin: 0 auto;
    scroll-margin-top: 187px;
}

.property-detail-page-full-details-content {
    width: 49%;
    border: 1px solid #d1deea;
    border-radius: 10px;
    overflow: hidden;
}

.property-detail-page-full-details-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
}

.property-detail-page-full-details-views {
    display: flex;
    align-items: center;
}

.property-detail-page-full-details-eye-icon {
    margin-right: 5px;
}

.property-detail-page-full-details-views-count {
    font-weight: bold;
}

.property-detail-page-full-details-section {
    padding-left: 20px;
    border-bottom: 1px solid #d1deea;
}

.property-detail-page-full-details-subtitle {
    font-weight: bold;
    margin-bottom: 5px;
}

.property-detail-page-full-details-notices {
    width: 33%;
}

.property-detail-page-full-details-notice {
    display: flex;
    align-items: flex-start;
    border: 1px solid #d1deea;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.property-detail-page-full-details-notice-icon {
    font-size: 2em;
    margin-right: 10px;
}

.property-detail-page-full-details-cash-icon {
    color: green;
}

.property-detail-page-full-details-warning-icon {
    color: orange;
}

.property-detail-page-view-and-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #d1deea;
    padding: 20px;
}

.property-detail-page-full-details-section ul {
    padding-left: 12px;
    padding-right: 10px;
}

.property-detail-page-full-details-section ul li {
    margin-bottom: 10px;
}

.property-detail-page-investment-calculator {
    font-family: Arial, sans-serif;
    width: 500px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.property-detail-page-calculator-container {
    display: flex;
    justify-content: space-between;
}

.property-detail-page-inputs-section {
    width: 45%;
}

.property-detail-page-slider-input {
    margin-bottom: 20px;
}

.property-detail-page-slider-input label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.property-detail-page-slider-input input {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.property-detail-page-slider-input span {
    display: block;
    text-align: right;
    font-size: 0.9em;
    color: gray;
}

.property-detail-page-results-section {
    width: 45%;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.property-detail-page-results-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.property-detail-page-investability {
    background-color: #007bff;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.property-detail-page-investability-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.property-detail-page-disclaimer {
    margin-top: 20px;
    font-size: 0.8em;
    color: gray;
    text-align: center;
}

#rentalEstimate {
    scroll-margin-top: 187px;
}
.dynamic-bidding {
    display: inline-block;
}

.dynamic-bidding__trigger {
    color: #2563eb;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
}

.dynamic-bidding__trigger:hover {
    text-decoration: underline;
}

.dynamic-bidding__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
    z-index: 1000;
}

.dynamic-bidding__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.dynamic-bidding__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.dynamic-bidding__title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #111827;
}

.dynamic-bidding__close {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 16px;
    padding: 4px 8px;
    padding-right: 14px;
}

.dynamic-bidding__content {
    padding: 1rem 0;
    color: #374151;
    line-height: 1.5;
    padding-top: 0px;
}

/* Animation classes */
.dynamic-bidding__modal-enter {
    opacity: 0;
    transform: translate(-50%, -45%);
}

.dynamic-bidding__modal-enter-active {
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: all 200ms ease-out;
}

.dynamic-bidding__modal-exit {
    opacity: 1;
    transform: translate(-50%, -50%);
}

.dynamic-bidding__modal-exit-active {
    opacity: 0;
    transform: translate(-50%, -45%);
    transition: all 150ms ease-in;
}

.dynamic-bidding__overlay-enter {
    opacity: 0;
}

.dynamic-bidding__overlay-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-out;
}

.dynamic-bidding__overlay-exit {
    opacity: 1;
}

.dynamic-bidding__overlay-exit-active {
    opacity: 0;
    transition: opacity 150ms ease-in;
}
@media screen and (max-width:1100px) {
    .property-details-main {
        width: 90%;
    }

    .man-background-property-detail {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media screen and (max-width:1000px) {
    .property-details-main {
        width: 98%;
    }

    .man-background-property-detail {
        padding-left: 1%;
        padding-right: 1%;
    }
}

@media screen and (max-width:930px) {
    .propetry-image-slide-show {
        width: 55%;
        height: 88%;
    }
}

@media screen and (max-width:900px) {
    .property-image-and-insites {
        width: 90%;
    }

    .property-condition-container {
        width: 90%;
    }

    .property-detail-page-full-details-container {
        width: 90%;
    }
}

@media screen and (max-width:910px) {
    .propetry-image-slide-show {
        height: 82%;
    }
}

@media screen and (max-width:822px) {
    .propetry-image-slide-show {
        height: 80%;
    }
}

@media screen and (max-width:810px) {
    .propetry-image-slide-show {
        height: 385px;
    }

    .property-image-and-insites {
        flex-direction: column;
        height: auto;
    }

    .propetry-image-slide-show {
        width: 100%;
    }

    .property-detail-auction-container {
        width: 55%;
    }

    .property-detials-property-instites {
        width: 95%;
        margin-top: 80px;
    }

    .man-background-property-detail {
        flex-direction: column-reverse;
        height: auto;
    }

    .inperson-auction-card {
        width: 100%;
    }

    .property-detail-table-grid {
        width: 100%;
    }

    .inperson-auction-card {
        margin-top: 0px;
    }

    .property-detail-table {
        width: 100%;
    }

    .propert-detail-card {
        width: 100%;
    }

    .auction-detail-section {
        height: auto;
    }

    .property-table-heading {
        padding-left: 10px;
    }

    .table-column-detail {
        width: 97%;
        padding-left: 10px;
    }

    .inperson-auction-card {
        box-shadow: none;
        border: none;
        border-radius: none;
    }
    .property-condition-container {
        flex-direction: column-reverse;
    }
    .property-detail-page-alert-container {
        width: 52%;
    }
    .property-detail-page-card{
        width: 100%;
    }
    .property-detail-page-full-details-container {
        flex-direction: column-reverse;
    }
    .property-detail-page-full-details-notices {
        width: 70%;
    }
    .property-detail-page-full-details-content {
        width: 100%;
    }
}

@media screen and (max-width:720px) {
    .property-image-and-insites {
        margin-top: 20px;
    }
}

@media screen and (max-width:568px) {
    .propetry-image-slide-show {
        height: 385px;
    }
}

@media screen and (max-width:660px) {
    .property-address-main-details {
        flex-direction: column;
    }

    .property-deatial-main-save-and-details {
        width: 100%;
    }

    .property-deatial-main-address-and-type {
        width: 100%;
    }

    .propetry-batroom-room-and-other {
        text-align: right;
    }
}

@media screen and (max-width:632px) {
    .propety-asset {
        width: 98%;
    }

    .property-asset-value {
        text-align: right;
    }

    .table-column-detail {
        flex-direction: column;
        gap: 10px;
    }

    .property-detail-auction-container {
        width: 65%;
    }
}

@media screen and (max-width:575px) {
    .property-detail-auction-container {
        width: 75%;
    }

    .propetry-image-slide-show {
        width: 100%;
    }
}
@media screen and (max-width:500px){
    .property-detail-page-alert-container {
        width: 65%;
    }
}
@media screen and (max-width: 450px) {
    .propetry-image-slide-show {
        height: 310px;
    }
    .property-detail-auction-container {
        width: 90%;
    }
}











































































































































































































/* 

.basic-details .card {
    width: 60%;
    height: 450px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s;
    margin: 0 auto;
    text-align: center;
  }
  
  .basic-details .card:hover {
    transform: scale(1.05);
  }
  
  .property-image {
    width: 100%;
    height: 70%;
    object-fit: cover;
  }
  
  .property-info {
    padding: 10px;
    text-align: center;
  }
  
  .photos-button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .property-links button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 10px 5px;
  }
  
  .slideshow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .slideshow-content {
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
  }
  
  .slideshow-images {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slideshow-images img {
    margin: 10px 0;
    width: 80%;
    height: auto;
    border-radius: 10px;
  }
   */