/* ===========================================
=============PrepearingMainHeroSection===========
============================================= */
.preparing-main-hero-section {
    margin: auto;
    display: flex;
    margin-top: 100px;
    margin-bottom: 90px;
    max-width: 1000px;
    padding-left: 22px;
    padding-right: 22px;
    width: 90%;
}

.preparing-main-hero-section-left {
    width: 50%;
    padding-left: 11px;
    padding-right: 11px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.preparing-main-hero-section-left-head {
    margin-bottom: 4px;
    text-transform: uppercase;
    white-space: normal;
    letter-spacing: 1px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    color: #5484FB;
}

.preparing-main-hero-section-left-heading {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 48px;
    margin-bottom: 18px;
    margin-top: 0;
    color: #00263e;
}

.preparing-main-hero-section-left-para {

    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 26px;
    margin-top: 0;
}

.preparing-main-hero-section-right {
    width: 50%;
}

.preparing-main-hero-section-right-image {
    width: 100%;
    height: 300px;
}


/* ===================================================
================StrategicSection====================
===================================================== */

.strategic-section-outer-container {
    background: #f0f6fe;
    width: 100%;
}

.strategic-section-main-container {
    margin: auto;
    padding: 50px 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}


.strategic-section-heading {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 48px;
    color: #00263e;
    text-align: center;
}

.strategic-section-other-component {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.strategic-section-other-component-inner-elements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.strategy-section-inner-image {
    width: 35px;
}

.strategy-section-inner-para {
    margin-top: 20px;
    text-align: center;
    font-weight: 700;
    width: 70%;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.5rem;
    color: rgb(0 38 62);
    letter-spacing: 1px;
}

.strategy-section-inner-para:hover {
    color: #0384Fb;
}

.strategy-my-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    font-size: 16px;

}

/* ========================================
===========OnlineBiddingProcess============
=========================================== */

.online-bidding-process-outer-section {
    margin: auto;
    display: flex;
    margin-top: 60px;
    margin-bottom: 100px;
    width: 95%;
}

.online-bidding-process-left-section {
    width: 33%;

}

.online-bidding-main-heading {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 48px;
    color: #00263e;
    margin-top: 0;
}

.online-bidding-left-section-blocks {
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-weight: 700;
    font-size: 16px;
}

.online-bidding-left-section-blocks div {
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(204 204 204);
}

.online-bidding-left-section-block span {
    color: rgb(3 132 251);
}

.online-bidding-process-right-section {
    width: 60%;
    margin-left: 10%;

}

.onlinebidding-right-head {
    font-size: 16px;
    font-weight: bold;
    color: #0384Fb;
    padding-bottom: 20px;
    line-height: 26px;
    margin-top: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.onlinebidding-right-heading {
    color: #00263e;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 12px;
    margin-top: 0;
    white-space: normal;
}

.online-bidding-right-para p {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 26px;
    margin-top: 0;
}

/* =======================================
==============QOUTE-SECTION===============
========================================== */

.quote-main-inner-section {
    margin: auto;
    display: flex;
    padding: 80px 0;
    margin-bottom: 100px;
    margin-top: 40px;
}

.qoute-big-to-small {
    height: 80px;
    width: 80px;
}

.quote-image-section-left {
    width: 20%;
}

.quote-right-section-container {
    width: 80%;
}

.quoted-para-of-quote-section {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
    margin-bottom: 26px;
    margin-top: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: white;
    width: 90%;
}

.quote-author-name {
    font-size: 16px;
    font-weight: 700;
    color: white;
    letter-spacing: 1px;
    line-height: 24px;
    margin-top: 0;
    text-transform: uppercase;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    margin: 0;
    margin-bottom: 2px;

}

.quote-author-designation {
    font-family: Times New Roman, serif;
    font-size: 15px;
    font-style: italic;
    line-height: 20px;
    margin-top: 0;
    color: white;
}


/* ================================================
==An integrated platform to do your due diligence==
===================================================*/

.integrated-platform-main-container {
    margin: 0 auto;
    margin-top: 60px;
    width: 90%
}

.integrated-platform-head {
    margin: 0;
    color: #0384Fb;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
    line-height: 26px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.integrated-platform-main-heading {
    color: #00263e;
    font-size: 23px;
    margin: 0;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 12px;
    margin-top: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.integrated-platform-main-para {
    color: #333;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 26px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.integrated-platform-main-section {
    display: flex;
    margin: 0 auto;
    margin-bottom: 70px;
    justify-content: space-between;
    margin-bottom: 100px;
    width: 90%;
}

.platform-section-main-image {
    width: 49%;
    padding-top: 5px;

}

.platform-section-main-img {
    width: 100%;

}

.integrated-section-main-right-section {
    width: 49%;

}

.integrated-platform-main-heading {
    padding-left: 60px;
}

.integrated-platform-main-para {
    padding-left: 60px;
}

.integrated-platform-main-para-main {
    padding: 0;
    margin-bottom: 80px;
    width: 95%;
}

.integrated-platform-main-heading-main {
    padding: 0;
}

.integrated-platform-learnmore-button {
    text-decoration: none;
    color: white;
    background: #0384Fb;
    padding: 14px 40px 12px 20px;
    position: relative;
    border-radius: 4px;
    border-width: 1px solid #0384Fb;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    min-width: 200px;
    text-align: center;
    text-transform: uppercase;
    margin-left: 60px;
}

/* ==========================================
===========Inverted==========================
============================================= */
.integrated-platform-main-section-inverted {
    margin: auto;
    display: flex;
    margin-top: 60px;
    margin-bottom: 60px;
    justify-content: space-between;
    width: 90%;
}

.integrated-section-main-right-section-inverted {
    width: 50%;
}

.integrated-platform-main-heading-inverted {
    color: #00263e;
    font-size: 24px;
    margin: 0;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 12px;
    margin-top: 0;
    padding-right: 70px;
    font-family: Roboto, Helvetica, Arial, sans-serif;

}

.integrated-platform-main-para-inverted {
    color: #333;
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 26px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    padding-right: 70px;

}

.integrated-platform-learnmore-button-inverted {
    text-decoration: none;
    color: white;
    background: #0384Fb;
    padding: 14px 40px 12px 20px;
    position: relative;
    border-radius: 4px;
    border-width: 1px solid #0384Fb;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    min-width: 200px;
    text-align: center;
    text-transform: uppercase;

}

.basic-image-section {
    margin: auto;
    margin-top: 60px;
}




.preparing-online-section-sharing {
    margin: auto;
    display: flex;
    align-items: center;
    margin-bottom: 80px;
    padding-left: 10%;
}

.preparing-online-section-sharing p {
    font-weight: 700;
    color: #333;
    font-size: 20px;
}

.preparing-online-section-sharing-icon {
    padding-left: 30px;
    display: flex;
    gap: 10px;
    color: #0384Fb;

}

.bidding-in-auction-card {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 12px #00000026;
    padding: 20px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 40px;
    width: 350px;
}

.bidding-in-auction-card-logo {
    width: 100px;
    height: auto;
    margin-right: 20px;
}

.bidding-in-auction-card-text {
    font-family: Arial, sans-serif;
}

.bidding-in-auction-card-text h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #595959;
}

.bidding-in-auction-card-text p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #666;
    font-weight: 500;
}

.bidding-card-container {
    padding-left: 1%;
}

.prepare-online-image-and-title {
    display: flex;
}

@media screen and (max-width:1000px) {

    .preparing-main-hero-section-left-heading,
    .strategic-section-heading {
        font-size: 30px;
    }
}

@media screen and (max-width:768px) {
    .preparing-main-hero-section {
        flex-direction: column;
    }

    .preparing-main-hero-section-left,
    .preparing-main-hero-section-right {
        width: 100%;
    }

    .preparing-main-hero-section-right-image {
        height: auto;
    }

    .strategic-section-other-component {
        flex-direction: column;
    }

    .prepare-online-plus-sign {
        display: none;
    }

    .strategic-section-other-component-inner-elements {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 95%;
    }

    .strategy-section-inner-para {
        margin-top: 0px;
        width: 80%;
        text-align: left;
    }

    .online-bidding-process-outer-section {
        flex-direction: column;
    }

    .online-bidding-process-right-section {
        margin-left: 0%;
        width: 100%;
    }

    .online-bidding-process-left-section {
        width: 100%;
    }

    .onlinebidding-right-head {
        margin-top: 50px;
        line-height: 10px;
    }

    .quote-main-inner-section {
        flex-direction: column;
        padding-left: 30%;
    }

    .quote-image-section-left {
        width: 10%;
    }

    .quoted-para-of-quote-section {
        font-size: 20px;
        line-height: 46px;
    }

    .integrated-platform-main-section {
        flex-direction: column;
    }

    .integrated-platform-main-heading {
        padding-left: 0px;
    }

    .integrated-platform-main-para {
        padding-left: 0px;
    }

    .integrated-section-main-right-section {
        width: 100%;
    }

    .platform-section-main-image {
        width: 100%;
    }
    .integrated-platform-learnmore-button{
        margin-left: 0px;
    }
    .integrated-platform-main-section-inverted{
        flex-direction: column;
    }
    .preparing-main-hero-section-left{
        padding-left: 0px;
    }
    .bidding-card-container {
        padding-left: 0%;
    }
    .integrated-section-main-right-section-inverted{
        width: 100%;
        margin-bottom: 20px;
    }
    .integrated-platform-learnmore-button-inverted{
        margin-bottom: 20px;
    }
    .integrated-platform-main-section{
        width: 100%;
    }
    .integrated-platform-main-heading{
         margin-top: 20px;
    }
    .integrated-platform-learnmore-button-inverted{
        margin-bottom: 20px;
    }
    .integrated-platform-main-para-inverted{
        padding-right: 0px;
    }
    .integrated-platform-main-section{
        margin-bottom: 40px;
        width: 90%;
    }
    .platform-section-main-img {
        width: 95%;
    }
}
@media screen and (max-width:490px){
    .bidding-in-auction-card{
        padding-left: 10px;
        padding-right: 10px;
        width: 87%;
    }
}
@media screen and (max-width:450px) {

    .preparing-main-hero-section-left-heading,
    .strategic-section-heading {
        font-size: 24px;
    }
}