.investement-calculator-container {
  width: 85%;
  border-radius: 10px;
  border: 1px solid #d1deea;
  margin: 0 auto;
  margin-top: 30px;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 20px;
  scroll-margin-top: 130px;
}

.investement-calculator-container h2 {
  font-size: 20px;
  font-weight: 500;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
  background-color: #d1deea;
}

.investment-calculator-function {
  padding-left: 20px;
  width: 60%;
  position: relative;
}
.investment-calculator-function > :last-child {
  margin-bottom: auto;
  position: absolute;
  width: 100%;
  bottom: 10px;
  font-size: 12px;
}
.investment-calculator-function-details {
  display: flex;
  justify-content: space-between;
}

.investment-calculator-function-details-numbers {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  width: 26%;
  gap: 10px;
}

.investment-calculator-function-details-numbers > div {
  width: 95%;
  background: #f4f7fa;
  border: none;
  margin: 0px;
  /* padding: 20px; */
  border-radius: 15px;
  color: black;
  min-width: 150px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.investment-calculator-function-details-numbers div div {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.investment-calculator-function div {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 10px;
}

.investment-calculator-function-details-numbers-capital-investment {
  background: #7ab2d0 !important;
  color: #fff !important;
  position: relative;
}

.investment-calculator-function-details-numbers-capital-investment::after {
  content: "";
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #7ab2d0 transparent transparent;
  display: inline-block;
  margin-left: -9px;
  margin-top: 23px;
  position: absolute;
}

.purchase-container,
.finance-container,
.rental-container,
.expenses-container {
  position: relative;
  width: 20%;
  background: #7ab2d0;
  border: none;
  border-radius: 0px;
  margin: 0px;
  /* padding: 20px; */
  border-radius: 15px;
  color: #fff;
  min-width: 150px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
}

.finance-container,
.rental-container,
.expenses-container {
  background-color: #f4f7fa;
  color: black;
  margin-right: 20px;
}

.purchase-container::after {
  content: "";
  position: absolute;
  top: 25px;
  right: -8px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #7ab2d0;
  display: block;
  width: 0;
}

.investment-calculator-slider-container {
  width: 200px;
  /* Adjust the width as needed */
  margin: 20px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px !important;
}

.investment-calculator-slider-label {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #1c3d69;
  /* Dark blue color */
  margin-bottom: 10px;
  display: block;
}

.investment-calculator-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  background: #1c3d69;
  /* Dark blue color */
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  border-radius: 3px;
}

.investment-calculator-slider:hover {
  opacity: 1;
}

.investment-calculator-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #1c3d69;
  /* Dark blue border */
  border-radius: 50%;
  cursor: pointer;
}

.investment-calculator-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #fff;
  border: 2px solid #1c3d69;
  /* Dark blue border */
  border-radius: 50%;
  cursor: pointer;
}

.investment-calculator-slider-container {
  font-weight: bold;
  min-width: 150px;
  max-width: 150px;
}

.investment-calculator-input {
  box-sizing: border-box;
  height: 48px;
  width: 177px;
  border: 1px solid #d1deea;
  border-radius: 2px;
  background-color: #ffffff;
  outline: none;
  padding: 5px 5px 5px 10px;
  color: #000000;
  font-family: "graphikmedium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.investment-calculator-input-rehab-cost {
  box-sizing: border-box;
  height: 48px;
  width: 60px;
  border: 1px solid #d1deea;
  border-radius: 2px;
  background-color: #ffffff;
  outline: none;
  padding: 5px 5px 5px 10px;
  color: #000000;
  font-family: "graphikmedium";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.investment-calculator-rehab-cost-input-container {
  gap: 20px !important;
}

.investement-calculator-rehab-cost-first-input {
  width: 97px;
}

.investemtn-calculator-purchase-text {
  max-width: 30% !important;
}
.investibility {
  padding-top: 0px !important;
}
.investibility > div:nth-child(1) {
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  background-color: #118bf4;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  justify-content: center;
}
@media screen and (max-width: 1150px) {
  .investment-calculator-function-details {
    flex-direction: column;
  }
  .investment-calculator-function {
    width: 90%;
  }
  .investment-calculator-function > :last-child {
    position: static;
  }
  .investment-calculator-function {
    margin-bottom: 30px;
  }
  .investment-calculator-function-details-numbers {
    padding-left: 30px;
    padding-right: 0px;
  }
  .investment-calculator-function-details-numbers {
    width: 40%;
  }
}
@media screen and (max-width: 748px) {
  .investment-calculator-function div {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  .investment-calculator-slider-container {
    margin: 0px;
  }
}
@media screen and (max-width: 685px) {
  .investemtn-calculator-purchase-text {
    max-width: 50% !important;
  }
}
@media screen and (max-width: 560px) {
  .investment-calculator-function-details-numbers {
    width: 65%;
  }
}
@media screen and (max-width: 420px) {
  .investment-calculator-function-details-numbers {
    width: 80%;
  }
}
@media screen and (max-width: 410px) {
  .investemtn-calculator-purchase-text {
    max-width: 70% !important;
  }
}
@media screen and (max-width: 900px) {
  .investement-calculator-container {
    width: 90%;
  }
}
