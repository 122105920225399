.portal-favorites-container {
    width: 100%;
    box-sizing: border-box;
}

.portal-favorites-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
}

.portal-favorites-card {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 15px;
}

.portal-favorites-property-image {
    width: 20%;
}

.portal-favorites-property-image img {
    width: 100%;
    height: 80px;
    border-radius: 5px;
}

.portal-favorites-details {
    width: 70%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    justify-content: space-between;
}

.portal-favorites-property-address {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    margin-bottom: 0px;
    margin-top: 0px;
}

.portal-favorites-time-left,
.portal-favorites-starting-bid,
.portal-favorites-status {
    font-size: 12px;
    color: #787878;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 20px;
}

.portal-favorites-view-details {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50px;
    padding: 15px 12px;
    cursor: pointer;
    margin-right: 10px;
}

.portal-favorites-view-details:hover {
    background-color: #0056b3;
}

.portal-favorites-delete {
    background-color: transparent;
    color: #888;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.portal-favorites-delete:hover {
    color: #c61d3a;
}

.portal-favorites-property-details-title {
    display: flex;
    width: 90%;
    margin: 0 auto;
    gap: 30px;
    margin-bottom: 20px;
    font-size: 12px;
    padding-left: 20px;
}

.portal-favorites-property-details-title div:nth-child(1) {
    width: 20%;
}

.portal-favorites-property-card-container {
    width: 90%;
    margin: 0 auto;
}

.portal-view-details-delete-container {
    display: flex;
    align-items: center;
}

@media screen and (max-width:656px) {
    .portal-favorites-property-card-container {
        width: 100%;
        border-left: none;
        border-right: none
    }

    .portal-favorites-card {
        border: none;
        padding: 10px;
    }
}
@media screen and (max-width:586px){
    .portal-favorites-property-image {
        display: none;
    }
    .portal-favorites-details {
        width: 95%;
        display: flex;
        align-items: center;
        margin-left: 20px;
        justify-content: space-between;
    }
}
@media screen and (max-width:460px){
    .portal-favorites-details {
        width: 100%;
        display: flex;
        align-items: center;
        margin-left: 0px;
        justify-content: space-between;
    }
    .portal-view-details-delete-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
}