.portal-purchase-header {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 500;
  padding-left: 20px;
}
.purchase-profile-header {
  border-bottom: 1px solid #d1deea;
}
.purchase-profile-header h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.purchase-profile-add {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.purchase-profile-add-box {
  width: 225px;
  height: 180px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 20px;
}

.purchase-profile-add-icon {
  background-color: #f6fafe;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchase-profile-add-text {
  margin-top: 10px;
  color: #d6e1ec;
  font-weight: bold;
}

.purchase-profile-save-time {
  background-color: #f1f8ff;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 5px;
  width: 88%;
}

.purchase-profile-save-time h3 {
  color: #57bd65;
}

.purchase-profile-save-time p {
  margin-bottom: 20px;
}

.purchase-profile-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.purchase-profile-input-field {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.purchase-profile-char-limit {
  font-size: 14px;
  color: #888;
}

.purchase-profile-agent-info,
.purchase-profile-buyer-info,
.purchase-profile-agent-info-details {
  margin: 0 auto;
  width: 90%;
}
.purchase-profile-buyer-icon {
  font-size: 26px;
}
.purchase-profile-section-title {
  background-color: #f8f8f8;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 2%;
  border-top: 1px solid rgb(209 222 234);
  border-bottom: 1px solid rgb(209 222 234);
  /* margin-bottom: 20px; */
}
.edit-buyer-information {
  font-size: 16px;
  color: #118cf4;
  font-weight: 500;
  cursor: pointer;
  margin-left: auto;
  padding-right: 30px;
}
.purchase-profile-section-title h4 {
  font-size: 18px;
}

.purchase-profile-radio-group {
  margin-bottom: 25px;
  margin-top: 5px;
}

.purchase-profile-radio-group label {
  font-size: 16px;
  font-weight: normal;
}

.purchase-profile-radio-group input[type="radio"] {
  margin-right: 10px;
  margin-top: 3px;
}

.purchase-profile-continue-btn {
  background-color: #007bff;
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  padding: 15px 30px;
  font-weight: 700;
  margin-bottom: 30px;
}

.purchase-profile-continue-btn:hover {
  background-color: #0056b3;
}
.purchase-profile-character-limit-container {
  display: flex;
  align-items: center;
  gap: 40px;
}
.purchase-profile-input label {
  font-size: 14px;
  font-weight: 500;
  color: #6c7178;
  margin-bottom: 20px;
}
.purchase-radio-input-container {
  display: flex;
  gap: 12px;
  margin-top: 10px;
}
.purchase-radio-input-container div{
    display: flex;
    align-items: flex-start;
}
.purchase-profile-radio-group > label {
  margin-bottom: 20px;
}
.purchase-profile-error {
  color: red;
  font-size: 0.9em;
  margin-top: 4px;
  display: block;
}
.purchase-profile-submitted-info {
  padding-left: 2%;
}
.buyer-information-form {
  max-width: 500px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.buyer-information-form-group {
  margin-bottom: 15px;
}

/* label {
  display: block;
  margin-bottom: 5px;
} */

/* input,
select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input:focus,
select:focus {
  border-color: #007bff;
  outline: none;
} */

.buyer-information-error {
  color: red;
  font-size: 12px;
}

.buyer-information-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.buyer-information-button:hover {
  background-color: #0056b3;
}
@media screen and (max-width: 500px) {
  .portal-purchase-header {
    font-size: 18px;
    justify-content: center;
  }
} 
