.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 1em;
  background-color: #00263E;
  /* Assuming a dark theme from the image */
  color: #fff;

}

.footer-content {
  width: 80%;
  padding: 50px 0px;
}

.footer-section {
  display: flex;
  justify-content: space-around;
  width: 75%;
  /* flex-wrap: wrap; */
}

.brand {
  margin-bottom: 1em;
  display: flex;
  align-items: flex-start;
  width: 20%;
}

.brand .logo-small {
  padding-top: 0px;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-section-links {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.footer-section-links strong {
  margin-bottom: 10px;
  font-weight: 400;
}

.footer-section-links ul {
  list-style: none;
  padding: 0;
  margin: 0;

}

.footer-section-links li {
  margin: 0;
  margin-bottom: 0.25em;
  padding: 10px 0;
  font-size: .8rem;

}

.footer-section-links li a {
  color: aliceblue;
  text-decoration: none;
  font-weight: 400;
}

.footer-section-links li a:hover {
  text-decoration: underline;
}

.footer-icons {
  margin: 0 auto;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;
}

.select-country {
  width: 200px;
  height: 100%;
  border: 1px solid black;
}

.social-media-icons {
  display: flex;
  gap: 40px;
}
.social-media-icons a{
    color: white;
}
.copy {
  border-top: 1px solid #444;
  /* Assuming there is a lighter border line in the image */
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.copy .terms-and-privacy {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 250;
  line-height: 22px;
}

.terms-and-privacy-link {
  text-decoration: none;
  color: white;
}

.terms-and-privacy-link:hover {
  text-decoration: underline;
}

.footer-copy-write-section {
  font-size: 13px;
  font-weight: 400;
}

.footer-items-to-hide-larger-screen {
  display: none;
}

@media screen and (max-width:1100px) {
  .footer-section {
    flex-wrap: wrap;
  }

  .footer-content {
    width: 100%;
  }
}

@media screen and (max-width:765px) {
  .brand {
    display: none;
  }

  .footer-section {
    justify-content: flex-start;
    width: 90%;
  }

  .footer-links {
    justify-content: center;
  }

  .footer-icons {
    justify-content: center;
  }

  .social-media-icons {
    width: 80%;
    display: flex;
    justify-content: space-around;
  }
}

.brand-2 {
  display: none;
}

@media screen and (max-width:685px) {
  .copy {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .copy .terms-and-privacy {
    align-items: center;
  }

  .brand-2 {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: auto;
  }

  .logo-small-2 {
    width: 120px;
  }
}

@media screen and (max-width:550px) {
  .footer-section-links {
    width: 150px;
  }

  .footer-section {
    justify-content: flex-start;
  }

  .terms-and-privacy div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media screen and (max-width:390px) {
  .footer-items-to-hide-larger-screen {
    display: block;
  }
  .footer-items-to-hide-smaller-screen {
    display: none;
  }
}