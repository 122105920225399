.portal-my-searches-container {
  width: 100%;
  padding: 0 0 30px 0;
}

.portal-my-searches-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 20px;
}

.portal-my-searches-history-link {
  font-size: 0.9em;
  color: #0073e6;
  text-decoration: underline;
}

.portal-my-searches-last-updated {
  font-size: 12px;
  text-align: right;
  color: #888;
  padding-right: 10px;
}

.portal-my-searches-content {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  padding: 0 20px;
}

.portal-my-searches-content p {
  font-size: 16px;
  font-weight: 400;
  color: #555;
  text-align: center;
  margin-top: 40px;
  padding-left: 25px;
  padding-right: 25px;
}

/* New styles for saved search list */
.saved-searches-list {
  margin-top: 20px;
}

.saved-search-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
}

.saved-search-item:hover {
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  transform: translateY(-2px);
}

.saved-search-details {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  flex: 1;
}

.saved-search-details h3 {
  margin: 0 0 5px 0;
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.search-date {
  margin: 0;
  font-size: 12px;
  color: #888;
}

.saved-search-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-loading {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

/* Additional styling for empty state */
.empty-searches {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  text-align: center;
}

.empty-searches-icon {
  font-size: 48px;
  color: #ccc;
  margin-bottom: 16px;
}

.empty-searches-text {
  font-size: 16px;
  color: #666;
  max-width: 400px;
  line-height: 1.5;
}

/* Button styling */
.run-search-button {
  background-color: #0384fb;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.run-search-button:hover {
  background-color: #0268c7;
}

.delete-search-button {
  background-color: white;
  color: #ff4d4f;
  border: 1px solid #ff4d4f;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.delete-search-button:hover {
  background-color: #fff1f0;
}

@media screen and (max-width: 768px) {
  .saved-search-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .saved-search-actions {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .portal-profile-my-search-header {
    text-align: center;
  }
  
  .portal-my-searches-header {
    justify-content: center;
  }
  
  .saved-search-actions {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }
  
  .run-search-button, .delete-search-button {
    width: 100%;
  }
}