.property-sample {
  display: flex;
  padding: 10px;
  z-index: -1;
}

.property-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow-y: scroll;
  gap: 18px;
  width: 65%;
  height: auto;
  max-height: 600px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  align-items: stretch;
  row-gap: 0px;
}

.property-cards::-webkit-scrollbar {
  width: 8px;
  /* Adjust width as needed */
}

.property-cards::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.property-cards::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.property-cards::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.property-location {
  width: 35%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.buy-navbar-extra-filters-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  gap: 40px;
  width: 100%;
  flex-wrap: wrap;
  padding-bottom: 0px;
  /* margin-bottom: 20px; */
}
.buy-navbar-extra-filters-title-section {
  min-width: 276px;
  flex: 1;
}
.buy-navbar-extra-filters-title-section h1 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.buy-navbar-extra-filters-title-section h1 span {
  color: #007bff;
  cursor: pointer;
  position: relative;
}

.buy-navbar-extra-filters-title-section p {
  font-size: 14px;
  color: gray;
}

.buy-navbar-extra-filters-title-section p span {
  color: #007bff;
  position: relative;
  cursor: pointer;
}

.buy-navbar-extra-filters-title-section a {
  color: #007bff;
  text-decoration: none;
}

.buy-navbar-extra-filters-buttons {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
  min-width: 276px;
}

.buy-navbar-extra-filters-special-promo-btn,
.buy-navbar-extra-filters-nearby-btn,
.buy-navbar-extra-filters-export-btn,
.buy-navbar-extra-filters-print-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  height: 40px;
}

.buy-navbar-extra-filters-special-promo-btn:hover,
.buy-navbar-extra-filters-nearby-btn:hover,
.buy-navbar-extra-filters-export-btn:hover,
.buy-navbar-extra-filters-print-btn:hover {
  background-color: #0056b3;
}

.buy-navbar-extra-filters-icons {
  font-size: 14px;
}
/* Container for the dropdown */
.most-filter-filter {
  position: relative;
  display: inline-block;
}

/* Trigger button styling */
.most-filter-trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Dropdown menu styling */
.most-filter-dropdown {
  position: absolute;
  top: 130%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000;
  min-width: 190px;
}

/* List styling inside dropdown */
.most-filter-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Dropdown list item styling */
.most-filter-item {
  cursor: pointer;
  font-weight: 400;
  padding: 8px 12px;
}
.most-filter-item span {
  color: #000000 !important;
  font-weight: 400;
}
/* Remove border for the last item */
.most-filter-item:last-child {
  border-bottom: none;
}

/* Hover effect for dropdown items */
.most-filter-item:hover {
  background-color: #f0f0f0;
}
/* Container for the state dropdown */
.all-state-filter {
  position: relative;
  display: inline-block;
  margin: 10px;
}

/* Trigger styling for states dropdown */
.state-filter-trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Dropdown menu for states */
.state-filter-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1000;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
  min-width: 200px;
}

/* State list styling */
.state-filter-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* State list item styling */
.state-filter-item {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

/* Label styling for checkboxes */
.state-checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
}

/* Checkbox styling */
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* Hover effect for state items */
.state-filter-item:hover {
  background-color: #f0f0f0;
}
.selected-states-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.selected-states {
  border: 1px solid #e2eaf2;
  border-radius: 8px;
  padding: 10px;
  color: #118bf4;
  font-size: 14px;
  font-weight: 500;
  max-height: 20px;
}
.selected-states span{
  cursor: pointer;
}
/* Container styling */
.no-properties-container {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9fafb;
  color: #374151;
  width: fit-content;
  max-width: 600px;
  margin-left: 10px;
}

/* Title styling */
.no-properties-title {
  margin: 0 0 12px 0;
  color: #111827;
  font-size: 16px;
  font-weight: 600;
}

/* Description paragraph styling */
.no-properties-description {
  margin: 0 0 12px 0;
  font-size: 14px;
  color: #4b5563;
}

/* List styling */
.no-properties-list {
  padding-left: 20px;
  margin: 0;
  color: #4b5563;
  font-size: 14px;
  line-height: 1.5;
}

.no-properties-list li {
  margin-bottom: 8px;
}

@media screen and (max-width: 1220px) {
  .property-cards {
    width: 65%;
  }

  .property-location {
    width: 35%;
  }
}

@media screen and (max-width: 1060px) {
  .property-cards {
    width: 100%;
  }

  .property-location {
    width: 0%;
  }
}

@media screen and (max-width: 500px) {
  .property-sample {
    padding: 0px;
  }
}
