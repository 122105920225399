.making-bidding-easier {
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 50px;
    width: 80%;
    text-align: center;
}

.making-bidding-easier button {
    border: none;
    border-radius: 5px;
    background-color: rgb(3 132 251);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: auto;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 50px;
}

.making-bidding-easier button:hover {
    background-color: #0056b3;
}

.making-bidding-easier h1 {
    font-size: 40px;
    line-height: 42px;
    color: #00263e;
    text-align: center;
}
@media screen and (max-width:500px){
    .making-bidding-easier h1 {
        font-size: 25px;
    }
    .making-bidding-easier {
      width: 100%;
    }
}
