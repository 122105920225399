.sell-properties-hero-background {
    background-image: url('./assets/back.png');
    /* Adjust path if necessary */
    background-size: cover;
    background-position: center;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.sell-properties-hero-text {
    text-align: center;
    padding-top: 300px;
}

.sell-properties-hero-light-blue {
    font-size: 48px;
    line-height: 100%;
    font-weight: 700;
    color: #00aaff;
    display: block;
    font-family: "Bebas Neue", sans-serif;
}

.sell-properties-hero {
    font-size: 48px;
    line-height: 100%;
    font-weight: 700;
    color: #00ff00;
    /* Adjust the color as needed */
    display: block;
    font-family: "Bebas Neue", sans-serif;
}


.take-a-moment-section {
    margin-top: 50px;
}

.take-a-moment-text {
    color: #00263e;
    font-size: 1.625rem;
    font-weight: 500;
    text-align: center;
    width: 42%;
    margin: auto;
    margin-bottom: 1rem auto;
    line-height: 40px;

}


.seller-form-main {
    background-color: #fff;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    padding: 20px;
    padding-top: 0px;
}

.seller-form-properties-form-container {
    background-color: #fff;
    padding: 20px;
    max-width: 800px;
    margin: auto;
    /* box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); */
    border-radius: 10px;
}

.seller-form-properties-form {
    display: flex;
    flex-direction: column;
}

.seller-form-properties-form-heading {
    color: #00263e;
    font-size: 25px;
    margin-bottom: 10px;
    font-variant-numeric: ordinal;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Nunito Sans", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize !important;
    margin-bottom: 13px;
}

.seller-form-properties-form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.seller-form-properties-form-item {
    flex: 1;
    min-width: calc(50% - 40px);
    /* Adjust the width to create a responsive two-column layout */
    display: flex;
    flex-direction: column;
    position: relative;
}

.seller-form-properties-form-item label {
    color: #00263e;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;

}

.seller-form-properties-input {
    flex: 1;
    padding: 10px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #00263e;
    font-family: "Nunito Sans", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}

.seller-form-properties-input option {
    padding: 10px;
    font-size: 16px;
    background-color: white;
    color: #00263e;
    font-weight: 400;
    font-family: "Nunito Sans", sans-serif;

}

.seller-form-properties-input option:hover {
    background-color: #5897fb;
    color: white;
}

.seller-form-properties-input::placeholder {
    color: #00263e;
}

.seller-form-properties-submit {
    background-color: #007bff;
    color: white;
    padding: 17px 20px 15px !important;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    width: 200px;
    margin: auto;
    font-family: Roboto, sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
}

/* .seller-form-properties-submit:hover {
    background-color: #0056b3;
} */

.seller-form-properties-footer {
    margin-top: 20px;
    text-align: left;
    color: #333;
    font-size: 15px;
    font-weight: 400;
    color: RGB(51, 51, 51);
    line-height: 26px;
}

.seller-form-properties-footer a {
    color: #333;
}

.seller-form-desired-price-range-group {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.seller-properties-range-item {
    width: auto;
}

.range-seller-form-properties-input {
    width: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #888;
    font-family: "Nunito Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}

.seller-properties-range-item label {
    color: #00263e;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
    background-color: #fff;
    border-radius: 10px;
    font-family: "Nunito Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
}

input:focus {
    outline: none;
    border: 1px solid rgb(3, 132, 251);
}

select:focus {
    outline: none;
    border: 1px solid rgb(3, 132, 251);
}

.mortageRange {
    width: 46.5%;
}

.seller-form-properties-input.red-border {
    border: 1px solid red;
}

.seller-form-properties-input.red-border::placeholder {
    color: red;
}

.select-wrapper {
    position: relative;
    display: inline-block;
}

.select-wrapper select {
    width: 100%;
    padding: 10px 40px 10px 10px;
    /* Adjust padding to fit the icon */
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #fff;
    appearance: none;
    /* Remove default dropdown styling */
    font-size: 16px;
    /* Adjust as needed */
}

.select-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust based on padding */
    transform: translateY(-50%);
    pointer-events: none;
    color: #687685;
}

.sell-property-custom-dropdown {
    position: relative;
    width: 100%;
    cursor: pointer;
}

.sell-property-custom-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}

.sell-property-custom-dropdown-header span {
    flex-grow: 1;
    font-size: 13px;
}

.sell-property-custom-dropdown-icon {
    margin-left: 10px;
    color: grey;
}

.sell-property-custom-dropdown-list {
    position: absolute;
    top: 60%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    z-index: 10;
    padding-left: 0px;
    max-height: 160px;
    overflow-y: auto;
}

.sell-property-custom-dropdown-list-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
    list-style: none;
    font-size: 13px;
}

.sell-property-custom-dropdown-list-item:hover {
    background-color: #5897fb;
    color: white;
}

.sell-property-custom-dropdown-list-item:last-child {
    border-bottom: none;
}

.mortage-cutom-drop-down-input-container {
    font-size: 13px;
    height: 25px;
    width: 50%;
    background: #fff;
    color: #000;
    margin-bottom: 0;
    box-shadow: 0px 2.293px 1.293px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.mortage-cutom-drop-down-input {
    font-size: 13px;
    width: 50%;
    background: #fff;
    color: #000;
    margin-bottom: 0;
    padding: 7px 24px;
    padding-left: 5px;
    margin-top: 3px;
    margin-bottom: 3px;
    outline: none;
    border: none;
}

.mortage-cutom-drop-down-input:focus {
    outline: none;
    border: none;
}

.mortage-input-container {
    display: flex;
    align-items: center;
    font-size: 13px;
    padding-left: 10px;
    gap: 10px;
}

.mortage-input-container span {
    margin-top: 5px;
}

.thank-you-modal-title {
    font-size: 24px;
    font-weight: 500;
}

.thank-you-modal-description {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 26px;
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

.thank-you-modal-description a {
    color: #333;
}

@media screen and (max-width:1375px) {
    .take-a-moment-text {
        width: 50%;
    }
}

@media screen and (max-width:1160px) {
    .take-a-moment-text {
        width: 60%;
    }
}

@media screen and (max-width:1000px) {
    .thank-you-modal-title {
        font-size: 22px;
    }
}

@media screen and (max-width:970px) {
    .take-a-moment-text {
        width: 70%;
    }
}

@media screen and (max-width:768px) {
    .seller-form-properties-form-group {
        flex-direction: column;
    }

    .take-a-moment-text {
        width: 75%;
    }
}

@media screen and (max-width:450px) {
    .take-a-moment-text {
        width: 85%;
    }
}