.portal-my-offers-container {
    width: 100%;
}

.portal-my-offers-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    gap: 20px;
}

.portal-my-offers-history-link {
    font-size: 0.9em;
    color: #0073e6;
    text-decoration: underline;
}

.portal-my-offers-last-updated {
    font-size: 12px;
    text-align: right;
    color: #888;
    padding-right: 10px;
}

.portal-my-offers-content {
    font-size: 16px;
    font-weight: 400;
    color: #555;
}

.portal-my-offers-content p {
    font-size: 16px;
    font-weight: 400;
    color: #555;
    text-align: center;
    margin-top: 40px;
}
@media screen and (max-width:500px){
    .portal-my-offers-header {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .portal-my-offers-header {
        font-size: 18px;
        margin-bottom: 5px;
    }
    .portal-my-offers-history-link {
        margin-bottom:20px ;
    }
}