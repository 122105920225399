.continue-with-google {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #ADC4DA;
    border-radius: 7px;
    width: 65%;
    margin-top: 10px;
    justify-content: center;
    font-size: 14px;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 15px;
}

.continue-with-google img {
    width: 25px;
    height: 25px;
}