.foreclosure-section-one-auction-section {
    background-color: white;
    padding: 50px 20px;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
    padding-top: 0px;
}

.foreclosure-section-one-title {
    font-size: 40px;
    margin-bottom: 0.5rem;
    color: #00263e;
    font-weight: 450;
    line-height: 60px;
}

.foreclosure-section-one-cards {
    display: flex;
    justify-content: center;
    gap: 10px;
    gap: 30px;
    color: #00263e;
}

.foreclosure-section-one-card {
    background-color: #F0F6FE;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 25px;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.foreclosure-section-one-card:hover{
    transform: scale(1.1);
}
.foreclosure-section-one-icon-container {
    display: flex;
    justify-content: flex-start;
    align-items: left;
}

.foreclosure-section-one-icon {
    font-size: 30px;
    color: #00263e;
}

.foreclosure-section-one-card-title {
    font-size: 26px;
    font-weight: 600;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.foreclosure-section-one-card-text {
    font-size: 14px;
    margin-bottom: 20px;
    color: #333;
    flex-grow: 1;
    line-height: 26px;
}

.foreclosure-section-one-learn-more {
    background-color: transparent;
    border: 1px solid #007bff;
    border-radius: 5px;
    color: #007bff;
    font-size: 14px;
    padding: 10px 30px;
    text-transform: uppercase;
    cursor: pointer;
    align-self: flex-start;
}

.foreclosure-section-one-learn-more:hover {
    background-color: #007bff;
    color: white;
}


/* =====================================================
====Foreclosure Bidding process==========================
======================================================== */

.foreclosure-bidding-main {
    background-color: #F0F6FE;
    padding-bottom: 50px;
    padding: 0px 0;
    margin-bottom: 50px;
}

.foreclosure-bidding-process-section {
    /* light blue background */
    padding: 120px 20px;
    text-align: center;
    margin: auto;
}

.foreclosure-bidding-process-title {
    font-size: 40px;
    margin-bottom: 10px;
    color: #00263e;
    font-weight: 450;
}

.foreclosure-bidding-process-subtitle {
    font-size: 18px;
    margin-bottom: 40px;
    color: #666;
}

.foreclosure-bidding-process-steps {
    display: flex;
    justify-content: center;
    gap: 40px;
    
}

.foreclosure-bidding-process-step {
    text-align: center;
    max-width: 200px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

ul.alice-carousel__dots {
    margin-top: 0px;
}

.foreclosure-bidding-process-step-number {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
    /* bright blue */
    margin-bottom: 10px;
    transition: transform 0.3s ease;
    border: 3px solid #ffffff;
    background-color: #ffffff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.foreclosure-bidding-process-step-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.foreclosure-bidding-process-step-description {
    font-size: 14px;
    color: #333;
    display: none;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    padding: 10px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 10000;
    margin-bottom: 20px;
}


.foreclosure-bidding-process-step:hover .foreclosure-bidding-process-step-number {
    transform: scale(1.9);
}

.foreclosure-bidding-process-step:hover .foreclosure-bidding-process-step-description {
    display: block;
}


/* +========================================================
================= Learn State Requirment =================*/


.learn-state-requirement-section {
    background-color: white;
    padding: 50px 20px;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
    padding-top: 0px;
}

.learn-state-requirement-title {
    font-size: 36px;
    margin-bottom: 10px;
    color: #00263e;
}

.learn-state-requirement-subtitle {
    font-size: 16px;
    color: #666;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.learn-state-requirement-states {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 35px;
    justify-content: flex-end;
    padding-left: 8rem;
}

.select-state-input {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
}

.learn-state-requirement-state {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
    text-align: left;
    transition: color 0.3s ease;
}

.learn-state-requirement-state:hover {
    color: #0056b3;
}


/* =============Forclosure Experience Section=============
-----=====================================================
========================================================== */

.foreclosure-experience-section {
    background-color: white;
    padding: 50px 20px;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
}

.foreclosure-experience-title {
    margin-bottom: 0.5rem;
    color: #00263e;
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 48px;
}

.foreclosure-experience-subtitle {
    margin-bottom: 40px;
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

.foreclosure-experience-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px 20px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.foreclosure-experience-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.foreclosure-faqs-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    margin-top: auto;
    width: 200px;
}

.foreclosure-faqs-button:hover {
    background-color: #0056b3;
}

.foreclosure-faqs-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 0 auto;
    /* padding-bottom: 70px; */
}

.foreclosure-faqs-hgroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.foreclosure-faqs-heading {
    font-size: 40px;
    margin-bottom: 0.5rem;
    color: #00263e;
    font-weight: 450;
    line-height: 60px;
}

.foreclosure-faqs-subheading {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    width: 51.5%;
    max-width: 800px;
}



/* ======================================
    =====Customer Resources==================
    ========================================= */

.customer-resource-container {
    text-align: center;
    /* padding: 2rem; */
    margin: auto;
    margin-bottom: 70px;
}

.customer-resource-title {
    font-size: 40px;
    margin-bottom: 0.1rem;
    color: #00263e;
    font-weight: 450;
    line-height: 40px;
}

.customer-resource-subtitle {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 40px;
}

.customer-resource-grid {
    display: flex;
    justify-content: center;
    padding-left: 150px;
    gap: 2rem;
}

.customer-resource-item {
    max-width: 280px;
    min-width: 270px;
    /* padding: .5rem; */
    text-align: left;
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.customer-resource-icon {
    width: 70px;
    height: 70px;
    /* margin-bottom: 1rem; */
}

.customer-resource-heading {
    color: #00263e;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 12px;
}

.customer-resource-description {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem;
}

.customer-resource-link {
    font-size: 0.875rem;
    color: #007bff;
    text-decoration: none;
}

.customer-resource-link:hover {
    text-decoration: underline;
}

@media screen and (max-width:1050px) {
    .foreclosure-section-one-cards {
        flex-direction: column;
        align-items: center;
    }
    .learn-state-requirement-states {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 35px;
        justify-content: flex-end;
        padding-left: 5rem;
    }
}

@media screen and (max-width:1000px) {
    .customer-resource-grid {
        justify-content: center;
        align-items: center;
        padding-left: 50px;
    }

    .foreclosure-bidding-process-title,
    .foreclosure-faqs-heading,
    .learn-state-requirement-title,
    .foreclosure-experience-title,
    .customer-resource-title {
        font-size: 30px;
    }
}

@media screen and (max-width:900px) {
    .customer-resource-grid {
        flex-direction: column;
        align-items: center;
        padding-left: 100px;
    }
    .learn-state-requirement-states {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 35px;
        justify-content: flex-end;
        padding-left: 2rem;
    }
}
@media screen and (max-width:800px){
    .foreclosure-faqs-subheading {
         width: 60%;
    }
}
@media screen and (max-width:768px) {
    .foreclosure-bidding-process-step {
        bottom: 50px;
    }
}
@media screen and (max-width:600px){
    .foreclosure-faqs-subheading {
        width: 90%;
   }
}
@media screen and (max-width:450px) {

    .foreclosure-bidding-process-title,
    .foreclosure-faqs-heading,
    .learn-state-requirement-title,
    .foreclosure-experience-title,
    .customer-resource-title {
        font-size: 24px;
    }

    .customer-resource-grid {
        padding-left: 50px;
    }
}