.author-card-slider-container {
    width: 84%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 80px;
  }
  .author-card-sliding-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }
  .author-card-slider-container h1{
    color: #00263e;
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
  }
  .autor-slider-horizontal-line{
      flex-grow: 1;
      height: 2px;
      background-color: rgb(0 0 0);
      margin-left: 10px;
      margin-right: 10px;
  }
  .author-card-slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 auto; */
  }
  
  .author-card-slider {
    display: flex;
    /* overflow-x: scroll;
    scroll-behavior: smooth; */
    overflow: hidden;
    /* width: calc(100% - 80px);  */
  }
  
  .author-card-arrow {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  .author-card-slider{
      gap: 20px;
      overflow-x: auto;
      scrollbar-width: none;
  }
  .author-card-arrow-left {
    margin-right: 5px;
  }
  
  .author-card-arrow-right {
    margin-left: 5px;
  }
  @media screen and (max-width:1000px){
    .author-card-slider-container h1{
      font-size: 40px;
    }
  }
  @media screen and (max-width:950px){
    .author-card-arrow-left{
       display: none;
    }
    .author-card-arrow-right{
       display: none;
    }
  }
  @media screen and (max-width:750px){
    .author-card-slider-container{
      width: 96%;
    }
  }
  @media screen and (max-width:450px){
    .author-card-slider-container h1{
        font-size: 30px;
    }
  }