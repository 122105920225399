.author-card-container {
    width: 300px;
    /* border: 1px solid #e0e0e0; */
    /* border-radius: 8px; */
    overflow: hidden;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
    /* margin-bottom: 10px; */
    min-width: 235px;
  }
  
  .author-card-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .author-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px solid black; */
    min-height: 155px;
    margin-top: 20px;
  }
  .author-card-category {
    font-size: 11px;
    color: rgb(3, 132, 251);
    margin-bottom: 8px;
    display: block;
    text-transform: uppercase;
    font-weight: 700;

  }
  
  .author-card-title {
    font-size: 18px;
    color: #333;
    margin: 0 0 8px;
  }
  
  .author-card-description {
    font-size: 14px;
    color: #555;
    margin: 0 0 16px;
  }
  
  .author-card-author {
    display: flex;
    align-items: center;
  }
  
  .author-card-author-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    filter: grayscale(100%);
  }
  
  .author-card-author-info {
    display: flex;
    flex-direction: column;
  }
  
  .author-card-author-name {
    font-size: 11px;
    color: #333;
    margin: 0;
    letter-spacing: 1px;
    font-weight: 700;
    transition: .1s ease-in-out;
    text-transform: uppercase;
  }
  .author-card-author-name:hover{
     border-bottom: 1px solid #333;
  }
  .author-card-author-title {
    font-size: 12px;
    color: #777;
    margin: 0;
  }
  @media screen and (max-width:750px){
    .author-card-container {
      width: 100%;
    }
    .author-card-image {
      height: 350px;
  }
  }
  @media screen and (max-width:420px){
    .author-card-image {
      height: 250px;
  }
  }