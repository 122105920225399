.conditions-background{
    background-image: url('https://images.ctfassets.net/bx9krvy0u3sx/74EQQDdcywgUeAOKCtrDmi/0e7b3986d9c29fea2744744369fafb8e/Desktop-concentric-rings_2x.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 45%; 
    background-position-y: 90px;
    background-position-x: 106%;

}

.privacy-term-main-container{
    width: 80%;
    margin: auto;
    
}
.privacy-content-updated-date{
    color: #0384FB;
    font-size: 16px;
    margin: 20px 0;
    margin-top: 100px;
    line-height: 24px;
    text-transform: uppercase;

}
.privacy-content-main-heading{
    font-size: 48px;
    font-weight: 450;
    line-height: 60px;
    margin: 20px, 0;
    color: #00263E;
    /* margin-bottom: 40px; */
}
.privacy-content-main-section{
    display: flex;
    margin-bottom: 100px;
   

}

.privacy-content-additional-section{
    width: 20%;
    margin-top: 40px;

}
.privacy-content-additional-section h2{
    color: #00263E;
    margin-top: 0px 0px 18px;
    font-weight: 500;
}
.privacy-content-additional-section h4{
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color:black;
}
.privacy-content-additional-section a{
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color:black;
    
}
.privacy-content-additional-section a:hover{
    text-decoration: underline;
}
.privacy-content-text-heading{
    /* text-decoration: underline; */
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-transform: uppercase;
    /* margin-top: 50px; */
    color:  RGB(51, 51, 51);
    margin-bottom: 26px;

}

.privacy-content-main-content-section{
    width: 80%;
    margin-top: 60px;
   color: RGB(51, 51, 51);
    text-align: justify;
}

.privacy-content-main-content-section p{
    line-height: 26px;
    vertical-align: baseline;
    margin: 0px 0px 26px;
    font-weight: 400;
    text-align: start;

}
.privacy-content-table{
    width: 100%;
    border-collapse: collapse;

}

.privacy-content-table th, .privacy-content-table td{
    border: 1px solid black;
    padding: 3px;
    text-align: center;
}


@media only screen and (max-width: 600px){

    .privacy-term-main-container{
        width: 95%;
        margin: auto;
    }

    .privacy-content-main-section{
        flex-direction: column;
    }
    .privacy-content-additional-section{
        width: 100%;
    
    }
    .privacy-content-main-content-section{
        width:100%;
    }


}