.admin-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
  }
  .admin-panel-search-bar {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 15px 13px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
  }
  
  .admin-panel-search-icon,.admin-panel-bar-icon {
    color: #697a8d;
    margin-right: 10px;
  }
  .admin-panel-bar-icon{
    display: none;
  }
  .admin-panel-search-bar input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .admin-panel-search-bar input::placeholder {
    color: #697a8d;
  }
  
  .admin-panel-avatar img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
  }
  .admin-name-dropdown {
    border-radius: 2px;
    padding: 10px;
    position: absolute;
    background-color: white;
    top: 80px; /* Adjust as needed */
    right: 20px;
    z-index: 1;
    box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
  }
  @media screen and (max-width:900px){
    .admin-panel-bar-icon{
      display: block;
      cursor: pointer;
    }
  }