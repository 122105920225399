.jobs-page-container {
  min-height: 220vh;
}

.job-banner {
  width: 100%;
  height: 450px;
  position: relative;
  margin-bottom: 60px;
}

.job-banner img {
  width: 100%;
  height: 450px;
}

.job-banner-text {
  position: absolute;
  left: 35%;
  top: 20%;
}

.job-banner-text h1 {
  color: #00263e;
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
}

.job-banner-text p {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 26px;
  width: 60%;
}

.jobs-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 50px;

}

.jobs-search-bar {
  margin-right: 20px;
  padding: 0px 8px 0px 24px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid rgb(3 132 251);
  border-radius: 25px;
}

.jobs-search-bar input {
  padding: 10px;
  border-radius: 20px;
  font-size: 16px;
  border: none;
  outline: none;
  width: 200px;
  font-weight: 500;
}

.jobs-search-bar input:focus {
  border: none !important;
  outline: none !important;
}

.jobs-search-bar input::placeholder {
  color: rgb(0 38 68);
}

.jobs-filters {
  display: flex;
}

.jobs-filter {
  position: relative;
  margin-right: 10px;
}

.jobs-dropdown-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
}

.job-openings-detail {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 26px;
  margin-top: 0;
  text-align: center;
}

.jobs-drawer-content {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 10px;
}

.jobs-drawer-filters {
  display: flex;
  justify-content: space-between;
}

.jobs-drawer-content {
  margin-top: 20px;
}

@media screen and (max-width:1000px) {
  .job-banner-text h1 {
    font-size: 40px;
  }
}

@media screen and (max-width:770px) {
  .job-openings-detail {
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media screen and (max-width:725px) {
  .job-banner-text {
    left: 5%;
  }
}

@media screen and (max-width:500px) {
  .jobs-filter-container {
    flex-direction: column;
    gap: 30px;
  }

  .jobs-search-bar {
    width: 85%;
    margin-right: 0px;
  }
}