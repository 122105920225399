.admin-panel-user-form-container {
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.admin-panel-user-container {
  width: 95%;
  margin: 0 auto;
}

.admin-panel-user-form-container h2 {
  text-align: left;
  margin-bottom: 20px;
}

.admin-panel-user-form-container form {
  display: flex;
  flex-direction: column;
}

.admin-panel-user-form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.admin-panel-user-form-group {
  width: 45%;
  margin-bottom: 15px;
}

.admin-panel-user-form-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1rem !important;
}

.admin-panel-user-form-container input {
  width: 100%;
  padding: 10px !important;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  transition: padding .2s ease-in-out;
}

.admin-panel-user-form-container input:focus {
  border: 2px solid #696cff;
  padding-left: 15px;
  outline: none;
}

.admin-panel-user-form-container button {
  background-color: #28a745;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 50%;
  margin-bottom: 10px;
}

.admin-panel-create-user-button {
  display: flex;
  justify-content: center;
}

.admin-panel-user-form-container button:hover {
  background-color: #218838;
}

@media (max-width: 600px) {
  .admin-panel-user-form-container button {
    width: 100%;
  }

  .admin-panel-user-form-row {
    flex-direction: column;
    gap: 0px;
  }

  .admin-panel-user-form-group {
    min-width: 98%;
  }
}