body {
    margin: 0;
    padding: 0;
    /* font-family: Arial, sans-serif; */
}

.faq-hero-container {
    background-image: url("../../../assets/images/vihara/hero_pattern-01.webp");
    background-size: cover;
    /* Cover the entire area of the div */
    background-position: center;
    /* Center the background image */
    background-repeat: no-repeat;
    background-color: #00263E;
    /* Do not repeat the image */
    height: 60vh;
    /* Or the height you desire */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
}

.faq-main-section-content {
    padding-left: 25%;
    color: #fff;
}
.faq-main-section-content h2{
    color: white;
    font-size: 48px;
    margin-bottom: -5px;
    margin-top: 80px;
    font-weight: 500;

}
.faq-main-section-content p{
    font-size: 1.2rem;
    width: 60%;
    margin-bottom: 30px;
    color: white;
    font-weight: 400;

}
.faq-main-search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #002b46; */
    padding: 20px;
    /* border-radius: 10px; */

}

.faq-main-search-heading {
    color: white;
    margin-bottom: 10px;
    font-size: 24px;
}

.faq-main-search-input {
    width: 300px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 50px 0;
}

.faq-main-search-input:focus {
    outline: none;
    border: 1px solid rgb(3, 132, 251);
}

.fac-background-hero {
    background-image: url('./background.png');
    /* Adjust path if necessary */
    background-size: cover;
    background-position: center;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */
}

.faqs-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333;
    background-color: white;
    margin: 0 auto;
    margin-bottom: 100px;
}

.faqs-container {
    width: 75%;
    padding-top: 20px;
    /* Adjust this based on your layout */
    background-color: white;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.faq-category {
    margin-bottom: 20px;
}

.faq-category h2 {
    margin-bottom: 10px;
    margin-top: 50px;
    font-weight: bold;
    color: #00263e;
    font-size: 24px;
}

.faq-item {
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.faq-question {
    font-size: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    color: rgb(3, 132, 251);
    font-weight: 500;
}

.faq-question:hover {
    color: rgb(4, 97, 204);
}

.faq-question span {
    font-size: 13px;
    font-weight: bold;
    color: #333;
}

.faq-answer {
    font-size: 16px;
    margin-top: 10px;
    color: #333;
    line-height: 28px;
}

.faq-answer ul {
    padding-left: 0;
    margin-left: 0;
    list-style-position: inside;
}

.faq-answer li {
    padding-left: 0;
    margin-left: 0;
}

@media screen and (max-width:768px) {
    .faqs-container {
        width: 92%;
    }

    .faq-question {
        font-size: 16px;
    }
}