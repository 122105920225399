/* Main container styles */
.print-property-container {
    padding: 2rem;
    max-width: 64rem;
    margin: 0 auto;
    visibility: hidden;
    position: absolute;
  }
  
  /* Header section with property title and price */
  .print-property-header {
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 1rem;
  }
  
  /* Property title styling */
  .print-property-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #1a1a1a;
  }
  
  /* Price display styling */
  .print-property-price {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2563eb;
  }
  
  /* Image container and styling */
  .print-property-image-container {
    margin-bottom: 1.5rem;
  }
  
  .print-property-image {
    width: 100%;
    height: 16rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  
  /* Two-column grid for property details */
  .print-property-details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  /* Common section styling (used for features, contact, etc.) */
  .print-property-section {
    margin-bottom: 1.5rem;
  }
  
  /* Section title styling */
  .print-property-section-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #1a1a1a;
  }
  
  /* Property features list styling */
  .print-property-feature-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  /* Individual feature item styling */
  .print-property-feature-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #4b5563;
  }
  
  /* Contact information list styling */
  .print-property-contact-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  /* Individual contact item styling */
  .print-property-contact-item {
    color: #4b5563;
  }
  
  /* Property description text styling */
  .print-property-description {
    line-height: 1.6;
    color: #4b5563;
  }
  
  /* Footer section styling */
  .print-property-footer {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  /* Print button styling */
  .print-property-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #d1deea;
    border-radius: 0.5rem;
    cursor: pointer;
    background: transparent;
    color: #1a1a1a;
    transition: background-color 0.2s;
  }
  
  /* Print button hover effect */
  .print-property-button:hover {
    background-color: #f3f4f6;
  }
  
  /* Print button icon styling */
  .print-property-button-icon {
    font-size: 20px;
    color: #2563eb;
  }
  
  /* Hidden container for print content */
  .print-property-hidden {
    display: none;
  }
  
  /* Print-specific styles */
  @media print {
    .print-property-container {
      padding: 0;
    }
    
    .print-property-button {
      display: none;
    }
  }