.otp-modal-box {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    padding: 32px;
    outline: none;
  }
  
.email-model-otp-container {
    display: flex;
    flex-direction: column;
    background-color: #f8faff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #f8faff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.email-modal-otp-input-container-otp-form {
    display: flex;
    align-items: center;
}

.email-modal-otp-input-container-otp-form div {
    display: flex;
    justify-content: flex-start;
}

.email-modal-otp-input-container-otp-form div input:nth-child(1) {
    border-left: 1px solid #ccc;
    ;
}

.email-modal-otp-input-container-otp-form div input:nth-child(1):focus {
    border-left: 1px solid rgb(25, 118, 210);
}

.email-model-otp-container h3 {
    font-weight: 500;
}

.email-model-otp-input-container-verify-button {
    background-color: #89c4f4;
    border: none;
    color: white;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 5px;
}

.email-modelotp-input-container-otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-left: none;
}
@media screen and (max-width:570px){
    .otp-modal-box{
         width: 85%;
         padding: 20px;
    }
}