.otp-input-container-otp-verification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8faff;
    padding: 20px;
    border-radius: 10px;
    width: 60%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
}

.otp-input-container h3 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.otp-input-container-otp-form {
    display: flex;
    align-items: center;
}

.otp-input-container-otp-form div {
    display: flex;
    justify-content: flex-start;
}

.otp-input-container-otp-form div input:nth-child(1) {
    border-left: 1px solid #ccc;
}

.otp-input-container-otp-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-left: none;
}

.otp-input-container-verify-button {
    background-color: #89c4f4;
    border: none;
    color: white;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 5px;
}

.otp-input-container-do-it-later-button {
    background-color: #1e90ff;
    border: none;
    color: white;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
}

.otp-input-container-or-text {
    margin: 0 10px;
    font-size: 14px;
    color: #333;
}

/* button:hover {
    opacity: 0.9;
} */