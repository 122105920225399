.privacy-section-header-section {

  /* light blue background */
  /* background-image: url("./assets/heroImage.png"); */
  background-size: cover;
  /* Cover the entire area of the div */
  background-position: center;
  /* Center the background image */
  background-repeat: no-repeat;
  background-color: #f0f6fe;
  /* padding: 120px 120px; */
  text-align: center;
  height: 70vh;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  color: #00263e;
  margin-bottom: 50px;
}

.privacy-section-header-content {
  max-width: 635px;
  margin: auto;
}

.privacy-section-header-icon {
  font-size: 50px;
  color: #007bff;
  /* bright blue color */
  margin-bottom: 0px;
}

.privacy-section-header-title {
  font-size: 36px;
  margin-bottom: 20px;
  line-height: 40px;
  color: #00263e;
}

.privacy-section-header-text {
  font-size: 18px;
  line-height: 1.6;
  color: #00263e;
  max-width: 591px;
  padding-left: 15px;
  padding-right: 15px;
}

/* ========================================================
=================================GP =====================
================================================== */
.guiding-principles-section {
  background-color: white;
  /* padding: 50px 20px; */
  text-align: center;
  margin: auto;
  margin-top: 120px;
}

.guiding-principles-title {
  font-size: 30px;
  margin-bottom: 30px;
  color: #00263e;
  font-weight: 500;
}

.guiding-principles-subtitle {
  margin-bottom: 40px;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  width: 90%;
  line-height: 26px;
  margin: 0 auto;
  margin-bottom: 26px;
}

.guiding-principle-box {
  display: flex;
  flex-direction: row;
  background-color: #f0f6fe;
  border-radius: 10px;
  /* padding: 20px; */
  align-items: stretch;
  justify-content: space-between;
  /* gap: 20px; */
  margin: auto;
  max-width: 950px;
  margin-bottom: 20px;
  width: 80%;
  transition: all 0.5s ease-in-out;
  scroll-margin-top: 100px;
}

.guiding-principle-text {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  /* padding-top: 20px; */
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 20px;
  height: 400px;
}

.guiding-principle-box-title {
  display: inline-block;
  font-size: 34px;
  font-weight: 450;
  letter-spacing: 0;
  line-height: 48px;
  color: #00263e;
  /* margin-bottom: 18px; */
  margin-top: 20px;
  max-width: 342px;
}

.guiding-principle-box-description {
  color: #333;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 26px;
  margin-top: 0;
  min-width: 350px;
  max-width: 400px;
}

.guiding-principle-more {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.guiding-principle-more .plus-icon-circle {
  font-size: 35px;
  color: #007bff;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.plus-icon-circle.move {
  transform: rotate(45deg);
  /* Example rotation */
}

.guiding-principle-more span {
  padding-left: 20px;
  line-height: 26px;
  color: #333;
  font-weight: 500;
  font-size: 19px;

}


.guiding-principle-more svg {
  margin-right: 5px;
}



.guiding-principle-image {
  width: 50%;
  /* border: 1px solid; */
  position: relative;
  height: 440px;
}


.guiding-principle-image img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 1;
  transition: opacity 0.3s ease;
  object-fit: cover;
  /* border-radius: 10px; */
}

.my-below-para {
  color: #333;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  padding: 20px;
  text-align: left;
  width: 90%;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s linear;
}

.my-below-para.show {
  opacity: 1;
  visibility: visible;
}

.guide-box-side-image.show {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.guide-box-side-image {
  top: 0;
  left: 0;
  position: absolute;
}

.privacy-samsara-section {
  text-align: center;
  padding: 20px;
}

.privacy-samsara-section h5 {
  color: #007bff;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.privacy-samsara-section h2 {
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 500;
  max-width: 630px;
  margin: 0 auto;
  margin-bottom: 80px;
}

.privacy-cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.privacy-card {
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  text-align: left;
  padding: 40px;
  width: 18%;
  max-width: 290px;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  padding-top: 30px;
  padding-bottom: 30px;
  /* Add transition for smooth hover effect */
  /* justify-content: space-between; */
}

.privacy-card:hover {
  transform: scale(1.05);
  /* Increase the size of the card on hover */
}

.privacy-card-icon {
  text-align: center;
  height: auto;
}

.privacy-card-icon img {
  width: 30%;
}

.privacy-card h3 {
  margin-bottom: 15px;
  font-size: 36px;
  font-weight: 500;
  color: #333;
}

.privacy-card p {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 26px;
  margin-top: 0;
}

.privacy-card-more {
  text-align: center;
  font-size: 24px;
  color: #007bff;
}

.privacy-membership-container {
  text-align: center;
  padding: 20px;
  margin-top: 40px;
}

.privacy-membership-title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 50px;
  color: #00263E;
}

.privacy-membership-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0 2px 12px #00000026;
  border-radius: 8px;
  padding: 45px 80px;
  width: 55%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.privacy-membership-logo {
  width: 20%;
  margin-right: 50px;

}

.privacy-membership-card-content {
  text-align: left;
}

.privacy-membership-card-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #00263e;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 26px;
}

.privacy-membership-card-text {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;

}

.privacy-contact-container {
  background-color: rgb(240 246 254);
  text-align: center;
  padding: 130px 130px;
}

.privacy-contact-title {
  color: #00263e;
  font-size: 48px;
  font-weight: 500;
  line-height: 0px;
}

.privacy-contact-text {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 20px;
}

.privacy-contact-button {
  background-color: rgb(3 132 251);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  min-width: 200px;
  padding: 14px 20px 12px;
}

.privacy-contact-button:hover {
  background-color: #0056b3;
}

.privacy-modal-description-container {
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (max-width:1000px) {
  .guiding-principle-box {
    flex-direction: column-reverse;
  }

  .privacy-section-header-title {
    font-size: 30px;
  }

  .guiding-principle-text {
    width: 90%;
    justify-content: flex-start;
    padding-left: 20px;
    height: auto;
  }

  .guiding-principle-box-description {
    max-width: 100%;
    width: 100% !important;
  }

  .guiding-principle-image {
    width: 100%;
    height: 375px;

  }

  .guiding-principle-image img {
    height: 375px;
  }

  .guiding-principle-image img {
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .guiding-principle-box-title {
    max-width: 100%;
    font-size: 30px;
  }

  .responsive-br {
    display: none;
  }

  .privacy-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .privacy-card {
    max-width: 100%;
    width: 65%;
  }

  .privacy-card-icon img {
    width: 12%;
  }


  .privacy-membership-title,
  .privacy-card h3,
  .privacy-samsara-section h2,
  .privacy-contact-title {
    font-size: 30px;
  }

  .privacy-membership-card {
    flex-direction: column;
    align-items: center;
  }

  .privacy-membership-card-title,
  .privacy-membership-card-text {
    text-align: center;
  }

  .privacy-membership-logo {
    width: 60%;
    margin-right: 0px;
  }
}

@media screen and (max-width:900px) {
  .guiding-principle-box {
    width: 90%;
  }
}

@media screen and (max-width:600px) {
  .privacy-modal-description-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .privacy-contact-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (max-width:450px) {

  .privacy-samsara-section h2,
  .privacy-membership-title,
  .privacy-contact-title,
  .privacy-card h3 {
    font-size: 24px;
  }

  .privacy-membership-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}