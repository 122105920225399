.subhead-main {
    background: rgb(3 132 251);
    /* width: 1000px; */
    margin: auto;
    padding: 24px 0;
    transition: width 0.3s ease-in-out; /* Smooth transition for width change */
    overflow-x: scroll;
    scrollbar-width: none;
    width: 80%;
}

.subhead-list {
    color: white;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    padding: 0; /* Remove padding to center items correctly */
    margin: 0; /* Remove margin to center items correctly */
    min-width: 900px;
}

.subhead-list li {
    font-size: 16px;
    padding: 5px 20px;
    cursor: pointer; /* Optional: change cursor on hover */
    font-weight: 400;
}
.subhead-list li a{
    color: rgb(255, 255, 255);
    text-decoration: none;
}
.subhead-list li a:hover{
    color: rgb(255, 255, 255);
}
/* Sticky styles */
.sticky {
    /* position: fixed; */
    top: 60px; /* Height of your navbar */
    width: 100%;
    z-index: 10; /* Ensure it's above other content */
    left: 0; /* Align to the left edge */
    right: 0; 
}
@media screen and (max-width:1190px){
    .subhead-main {
        width: 100%;
    }
}
@media screen and (max-width:850px){
    .subhead-list {
       min-width: 700px;
       justify-content: space-between;
    }
    
}
@media screen and (max-width:750px){
    .subhead-main {
        width: 100%;
    }
}
