#my-smallscreen-navbar {
  background: none;
  position: fixed;
  z-index: 999;
  height: 60px;
  /* line-height: 80px; */
  width: 100vw;
}

nav {
  background: none;
  position: fixed;
  z-index: 999;
  height: 60px;
  /* line-height: 80px; */
  width: 100vw;
}

#my-smallscreen-navbar .wrapper {
  margin: auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*   
  .logo-small {
  }
   */
nav .wrapper {
  margin: auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.wrapper .logo a {
  text-decoration: none;
  padding-left: 20px;


}

.wrapper .small-screen-nav-links {
  display: inline-flex;
  padding-right: 30px;
}

.small-screen-nav-links li {
  list-style: none;
  padding-right: 30px;

}

.small-screen-nav-links li a {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: #f1f1f1;
  /* transition: all 0.3s ease; */

}

.small-screen-nav-links li a:hover,
.small-screen-nav-links li a.active {
  border-bottom: 3px solid blue;
}



.login-signUp .login {
  color: #f1f1f1;
  padding-right: 20px;
}

.login-signUp .login:hover {
  color: blue;
}

.login-signUp a {
  text-decoration: none;
}

.login-signUp .Sign-Up {
  color: #f1f1f1;
  background: rgb(3 132 251);
  padding: 10px;
  border-radius: 5px;

}

.login-signUp .Sign-Up:hover {
  background: rgb(51, 154, 250);
}

.mega-box {
  position: absolute;
  top: 0px;
  background-color: aliceblue;
  width: 97%;
  height: 100vh;
  right: 0;
  visibility: hidden;
  display: flex;
  gap: 100px;
  z-index: 1000;
}

.small-screen-nav-links li:hover .mega-box {
  /* top: 80px; */
  visibility: visible;
  /* transition: all 0.3s ease; */
  height: 90vh;
}

.mega-box .content {
  line-height: normal;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;
  padding-top: 0px;
  padding-left: 20px;
  font-size: 16px;
}

.content-2 {
  display: flex;
  gap: 10px;
}

.content-2 div {
  width: 32%;
  line-height: 20px;
}

.content-2 div h5 {
  color: #979797;
  font-size: 13px;
  font-weight: 450;
  letter-spacing: 1px;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 55px;
}

.content-2 div img {
  width: 100%;
  height: 200px;
}

.content-2 p {
  width: 100%;
  color: rgb(3 132 251);
  cursor: pointer;
}

.content-2 span {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #00263e;
}

.nav-scrolled {
  background-color: white;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

/* You might want to change text color, etc., when scrolled */
.nav-scrolled .small-screen-nav-links a,
.nav-scrolled .login {
  color: #333;
  text-decoration: none;
  /* Darker text on light background */
}

.nav-scrolled .small-screen-nav-links a:hover {
  color: #333;
}

.nav-scrolled .Sign-Up a {
  color: #f1f1f1;

}

.mega-box .content h2 {
  font-size: 13px;
  color: #727272;
  padding-top: 0px;
  margin-top: 0px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-top: 1px solid #00263e;
  padding-top: 20px;
  width: 97%;
  font-weight: 700;
}

.buy-items {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 5px;
  cursor: pointer;

}

.buy-item-heading p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  padding-bottom: 5px;
}

.buy-item-text p {
  padding: 0;
  margin: 0;
  padding-bottom: 20px;
  font-size: 13px;
}

.explore-more {
  color: rgb(25, 0, 255);
  font-size: .9rem;
  font-weight: 600;
  cursor: pointer;
}

.mega-box .content .resources-list a {
  font-size: 16px;
  text-decoration: none;

}

.mega-box .content .resources-list a:hover {
  color: blue;
}

.buy-items .buy-item-heading:hover {
  color: blue;

}

.resources-my-icons {
  position: relative;
  top: 1.5px;
}

.menu-icon {
  padding-right: 20px;
  color: white;
  display: none;
}

.menu-icon-content {
  width: 98%;
  height: 97%;
  /* display: flex;
    flex-direction: column; */
  gap: 20px;
  align-items: center;
  color: #00263e;
  font-size: 20px;
  position: relative;
  padding-top: 10px;
  font-weight: 500;
}

/* .menu-icon-content div{
    border-bottom: 1px solid rgba(0, 0, 0, .15);
    width: 95%;
    height: 60px;
    padding-left: 10px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-right: 10px;
  } */
.small-screen-login-signup-button-container {
  /* position: absolute;
  bottom: 0px; */
  display: flex;
  justify-content: flex-start !important;
  gap: 10px;
  padding-left: 16px;
  margin-top: 20px;
  width: 92%;
}

.small-screen-navbar-register-link {
  margin-top: 15px;
  padding-left: 16px;
  font-size: 16px;
}

.small-screen-navbar-register-link span {
  color: rgb(3 132 251);
  cursor: pointer;
}

.small-screen-login-signup-button-container a {
  border: 1px solid rgb(3 132 251);
  padding-top: 15px;
  padding-bottom: 15px;
  height: 20px;
  border-radius: 8px;
  text-decoration: none;
  color: #FFF;
  width: 100%;
  text-align: center;
  background-color: rgb(3 132 251);
  font-size: 18px;
}

.small-Sign-Up {
  color: white !important;
  background-color: rgb(3 132 251);
}

.MuiAccordion-root:before {
  display: none;
}

.MuiAccordion-root {
  box-shadow: none;
}



.accordion-background-color {
  background-color: aliceblue;
}

.accordion-container-small-navbar {
  max-height: 80%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.mega-box .content a {
  text-decoration: none;
}

.horizontal-line-small-navbar {
  width: 100%;
  height: 1px;
  background-color: rgb(59 130 246 / 50%);
  position: absolute;
  bottom: 70px;
}

@media all and (max-width:1027px) {
  .wrapper .logo a {
    padding-left: 20px;
  }

  .small-screen-nav-links {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .content-2 p {
    width: auto;
    color: rgb(3 132 251);
    cursor: pointer;
  }

  .resources-mega-box {
    position: absolute;
    top: 0px;
    background-color: aliceblue;
    width: 100%;
    height: 90vh;
    left: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  .resources-mega-box .content {
    line-height: normal;
    padding-left: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .resources-mega-box .content-2 {
    width: 91%;
    margin: 0 auto;
  }

  .small-screen-nav-links li a {
    color: #00263e;
  }
}

@media all and (max-width:700px) {
  .content-2 div img {
    height: 150px;
  }

  .content-2 div h5 {
    font-size: 10px;
  }
}
@media screen and (max-width:515px){
  .small-screen-login-signup-button-container{
      width: 89%;
  }
}
@media all and (max-width:495px) {
  .content-2 {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .content-2 div {
    width: 80%;
  }

  .content-2 div img {
    width: 100%;
    height: auto;
  }

  .content-2 div h5 {
    font-size: 15px;
  }

  .mega-box .content {
    padding-left: 10px;
  }
}

@media all and (max-width:400px) {
  .logo-small {
    width: 100px;
    height: auto;
  }

  .wrapper .small-screen-nav-links {
    padding-right: 0px;
  }
}