/* src/components/Card.property-css */
.property-card {
  width: 90%;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  display: flex;
  height: 212px;
  overflow: hidden;
  margin-bottom: 10px;
}

.property-card:hover {
  box-shadow: 0 4px 6px 3px rgba(0, 0, 0, 0.15);
}

.property-card-image {
  position: relative;
  height: 212px;
}

.property-card-image img {
  width: 330px;
  height: 212px;
  /* filter: brightness(60%); */
}

.property-card-overlay {
  position: absolute;
  top: 0;
  left: 2%;
  width: 96%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
}

.property-icon-right {
  color: white;
  cursor: pointer;
  z-index: 999;

}

.property-overlay-text {
  color: rgb(92, 103, 110);
  display: flex;
  justify-content: center;
  gap: 2px;
  width: auto;
  font-size: 10px;
  visibility: hidden;
}

.property-overlay-text span {
  background: #e1f7fa;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
}

.property-number-of-images {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: auto;
  background-color: rgba(0, 0, 0, 0.493);
  padding: 8px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.property-type-of-property {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: auto;
  background-color: rgba(0, 0, 0, 0.493);
  padding: 8px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.propert-card-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: rgb(92, 103, 110);
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  text-align: left !important;
  flex: 1;
  padding-left: 0px;
  padding-right: 0px;
}

.propert-card-content>div {
  width: 95%;
  margin: 0 auto;
}

.propert-card-content>div:nth-child(-n+2) {
  display: flex;
  justify-content: space-between;
}

.propert-card-content>div:nth-child(1) {
  color: rgb(92, 103, 110);
  font-size: 14px;
  font-weight: 500;
}

.propert-card-content>div:nth-child(2) {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #333;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}

.propert-card-content>div:nth-child(3) {
  font-size: 14px;
  color: rgb(92, 103, 110);
  margin-bottom: 0px;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.propert-card-content>div:nth-child(4) {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 400;
  display: none;
}

.propert-card-content>div:nth-child(5) {
  font-size: 14px;
  color: rgb(92, 103, 110);
  font-weight: 400;
  margin-bottom: 10px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  max-height: 36px;
  min-height: 36px;
}

.propert-card-content>div:nth-child(7) {
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.property-card-value {
  font-size: 14px;
  color: #48525B;
  font-size: 12px;
  letter-spacing: 0;
  margin-right: 12px;
  font-weight: 400;
  line-height: 18px;
}

.property-card-value:nth-child(3) {
  margin-top: -5px;
}

.propert-card-content>div:nth-child(7) {
  font-size: 10px;
}

.propert-card-content>div:nth-child(8) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.property-card-property-type {
  font-size: 14px;
  color: rgb(92, 103, 110);
  margin-bottom: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-left: -5px;
}

.foreclosure-image {
  width: 17px;
  height: 17px;
  margin-right: 4px;
}

.property-card-view-now-button {
  border: none;
  border-radius: 8px;
  background-color: rgb(3 132 251);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  font-weight: 500;
  padding-top: 9px;
  padding-bottom: 10px;
  margin-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  font-style: normal;
}

.listed-information-property-card {
  color: #48525B;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 10px;
  font-weight: 400;
}

.property-card-address {
  color: #000000 !important;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  font-weight: 500 !important;
  line-height: 20px;
}

.property-card-icon {
  color: #118bf4;
  margin-right: 3px;
}

.property-card-starting-bid {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
}

.starting-bid-text {
  font-size: 15px;
  color: #48525b;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10px;
}

.clock-timer {
  color: #118bf4;
  margin-right: 10px;
  font-size: 25.5px;
}

.time-left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.time-left {
  color: #000000 !important;
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
  margin-left: 10px;
}

.two-dots-property-card {
  font-size: 16px;
  color: #000000 !important;
  font-weight: bold;
}

.auction-ended {
  margin-left: 5px;
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}

.time-left-container span:nth-child(2) {
  margin-left: 8px;
}

.countdown-timer {
  margin-top: 10px;
}

.property-card-horizontal-line {
  height: 1px;
  width: 100% !important;
  background-color: #e2eaf2;
}

.property-card-image-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: white;
}

.property-card-carousel-container {
  position: relative;
  width: 100%;
  height: 250px;
  /* Adjust based on your card design */
}

.property-card-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.property-card-carousel-arrow {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 2;
}

.left-arrow {
  left: -2px;
}

.right-arrow {
  right: -2px;
}

@media screen and (max-width:1060px) {
  .property-card {
    width: 290px;
    box-shadow: none;
    flex-direction: column;
    height: 402px;
  }
}