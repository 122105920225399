/* General container styles */
.my-bids-dashboard-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}
.my-bids-dashboard-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.my-bids-dashboard-stat-grid {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
}
/* Header styles */
.my-bids-dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.my-bids-dashboard-title {
  font-size: 24px;
  font-weight: bold;
}

.my-bids-dashboard-history-link {
  text-decoration: none;
  color: #007bff;
  font-size: 14px;
}

.my-bids-dashboard-history-link:hover {
  text-decoration: underline;
}

/* Flex container for cards */
.my-bids-dashboard-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Card styles */
.my-bids-dashboard-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 49%;
  min-width: 300px; /* Ensures cards don’t shrink too much */
  box-sizing: border-box;
  margin-bottom: 20px;
  padding-top: 5px;
  padding: 0px;
  overflow: hidden;
}

.my-bids-dashboard-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #f5f3f3d6;
}
.my-bids-dashboard-contracts-grid {
  margin-top: 15px;
}
.my-bids-dashboard-card-header h3 {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.my-bids-dashboard-badge {
  background-color: #007bff;
  color: #fff;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: bold;
}

.my-bids-dashboard-view-all {
  text-decoration: none;
  font-size: 14px;
  color: #007bff;
}

.my-bids-dashboard-view-all:hover {
  text-decoration: underline;
}

/* Stats container */
.my-bids-dashboard-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.my-bids-dashboard-stat-item {
  text-align: center;
  flex: 1;
  border-right: 1px solid #ddd;
  padding-top: 15px;
  padding-bottom: 15px;
}

.my-bids-dashboard-icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.my-bids-dashboard-icon.winning {
  color: #28a745;
}

.my-bids-dashboard-icon.outbid {
  color: #dc3545;
}

.my-bids-dashboard-icon.completed {
  color: #007bff;
}

.my-bids-dashboard-number {
  font-size: 22px;
  font-weight: bold;
  display: block;
  margin-top: 5px;
  text-align: center;
  text-decoration: underline;
  color: #007bff;
}
.my-bids-dashboard-stat-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
  border-right: 1px solid #ddd;
  height: 110px;
  padding-right: 5px;
}
.my-bids-dashboard-stat-box span:first-child {
  font-weight: bold;
  text-align: center;
}
/* Contracts section */
.my-bids-dashboard-contracts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.my-bids-dashboard-contract-row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 10px;
}

.my-bids-dashboard-contract-row span {
  font-weight: bold;
}

/* No favorites section */
.my-bids-dashboard-no-favorites {
  font-size: 14px;
  color: #666;
  margin-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.my-bids-dashboard-click-here {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.my-bids-dashboard-click-here:hover {
  text-decoration: underline;
}
@media screen and (max-width: 1140px) {
  .my-bids-dashboard-card {
    width: 100%;
  }
}

