.admin-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f6fa;
  }
  
  .admin-login-box {
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 50%;
  }
  
  .admin-login-box h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .admin-input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .admin-input-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .admin-input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .admin-remember-me {
    margin-bottom: 20px;
    float: left;
  }
  
  .admin-remember-me input {
    margin-right: 10px;
    margin-top: 0px;
  }
  .admin-remember-me label{
    display: inline-block;
  }
  .admin-login-button {
    width: 100%;
    padding: 10px;
    background-color: #6c63ff;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .admin-login-button:hover {
    background-color: #5a54d4;
  }
  
  .admin-forgot-password {
    margin-top: 10px;
  }
  
  .admin-forgot-password a {
    color: #6c63ff;
    text-decoration: none;
  }
  
  .admin-forgot-password a:hover {
    text-decoration: underline;
  }
  