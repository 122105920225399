/* Spinner.css */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
  }
  
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1); /* Light grey background */
    border-radius: 50%;
    border-top: 8px solid #3498db; /* Blue color */
    width: 50px; /* Size of the spinner */
    height: 50px; /* Size of the spinner */
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  