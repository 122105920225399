.change-email-modal-box {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: white; /* Adjust to your background variable or color */
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14); /* You can adjust the shadow as needed */
  padding: 16px;
  border-radius: 8px;
}
.change-email-modal-input {
    width: 100%;
    padding: 8px;
    font-size: 12px;
    color: rgb(0 38 68);
    border: 2px solid #d8d8d8;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 20px;
    background-color: white;
}

.change-email-modal-input-error {
    border: 1px solid red;
    background-color: #ffe6e6;
    margin-bottom: 5px;
  }
  
  .change-email-modal-error-text {
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .change-email-modal-button{
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    height: 40px;
  }
  @media screen and (max-width:530px){
    .change-email-modal-box{
       width: 85%;
    }
  }