/* .enterprise-grade-container {
    text-align: center;
    margin-top: 100px;
    background-color: rgb(248 248 248);
    padding-top: 50px;
    padding-bottom: 50px;
}

.enterprise-grade-container h1 {
    color: #00263e;
    font-size: 40px;
    font-weight: 450;
}

.enterprise-grade-container p {
    color: #333;
    font-size: 16px;
}

.enterprise-grade-stats {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.enterprise-grade-stats .box {
    width: 22%;
    text-align: center;
}

.enterprise-grade-stats .box h1 {
    color: rgb(3 132 251);
    font-size: 60px;
    line-height: 10px;
    font-weight: 400;

}

.enterprise-grade-stats .box span {
    color: #00263e;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@media all and (max-width:750px) {
    .enterprise-grade-container h1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .enterprise-grade-stats .box {
        width: 25%;
    }
    .enterprise-grade-container p {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media all and (max-width:500px) {
    .enterprise-grade-container h1 {
        font-size: 30px;
    }

    .enterprise-grade-stats {
        flex-direction: column;
        align-items: center;
    }

    .enterprise-grade-stats .box {
        width: 100%;
        text-align: center;
    }

    .enterprise-grade-stats .box h1 {
        font-size: 50px;
    }
} */

.enterprise-grade-container {
    text-align: center;
    margin-top: 100px;
    background-color: rgb(248 248 248);
    padding-top: 50px;
    padding-bottom: 50px;
    scroll-behavior: smooth;
}

.enterprise-grade-container h1 {
    color: #00263e;
    font-size: 40px;
    font-weight: 450;
}

.enterprise-grade-container p {
    color: #333;
    font-size: 16px;
}

.enterprise-grade-stats {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.enterprise-grade-stats .box {
    width: 22%;
    text-align: center;
}
.number-style{
    color: rgb(3, 132, 251);
    font-size: 60px;
    line-height: 70px;
    font-weight: 400;
    
}
.enterprise-grade-stats .box h1 {
    color: rgb(3 132 251);
    font-size: 60px;
    line-height: 70px;
    font-weight: 400;
}
.number-animation-effect{
    color: rgb(3 132 251);
    font-size: 60px;
    line-height: 70px;
    font-weight: 400;

}
.enterprise-grade-stats .box span {
    color: #00263e;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@media all and (max-width: 750px) {
    .enterprise-grade-container h1 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .enterprise-grade-stats .box {
        width: 25%;
    }
    .enterprise-grade-container p {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media all and (max-width: 500px) {
    .enterprise-grade-container h1 {
        font-size: 30px;
    }

    .enterprise-grade-stats {
        flex-direction: column;
        align-items: center;
    }

    .enterprise-grade-stats .box {
        width: 100%;
        text-align: center;
    }

    .enterprise-grade-stats .box h1 {
        font-size: 50px;
    }
}
