/* ResetPassword.css */


.reset-password-container {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    text-align: center;
    margin-top: 50px;
}

.reset-password-container .logo {
    /* width: 80px; */
    height: 30px;
    /* margin-bottom: 50px; */
}

.reset-password-container h2 {
    /* font-family: 'Courier New', Courier, monospace; */
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 50px;
    color: #343A41;
}

.reset-password-input-group {
    margin-bottom: 20px;
    text-align: center;
}

.reset-password-input-group label {
    font-size: 13px;
    margin-bottom: 5px;
    color: #343A41;
    display: flex;
    justify-content: space-between;
    width: 103%;
    padding-right: 5px;
}

.reset-password-input-group input {
    width: 100%;
    padding: 10px 0 10px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.reset-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.reset-password-container form a{
    text-decoration: none;
    display: block;
    width: 100%;
}

.reset-button:hover {
    background-color: #0056b3;
}

.back-to-signin {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    color: #000;
    text-decoration: none;
    width: 100%;
}

.back-to-signin span {
    color: #007bff;
}

.back-to-signin span:hover {
    text-decoration: underline;
}
.email-error-test-reset{
    font-size: 12px;
    color: red;
    margin-bottom: 10px;
    text-align: left;
}
.reset-password-wrong-email-text{
     font-size: 14px;
     text-align: left;
     width: 100%;
     font-weight: 400;
}
@media screen and (max-width:1000px){
    .reset-password-container h2{
        font-size:30px;
    }
}
@media screen and (max-width:450px){
    .reset-password-container h2{
        font-size:24px;
    }
}