.bid-confirmation-modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .bid-confirmation-modal-container {
    background-color: white;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 28rem;
  }
  
  .bid-confirmation-modal-header {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .bid-confirmation-modal-header-success {
    background-color: #22c55e;
    color: white;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .bid-confirmation-modal-title {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .bid-confirmation-modal-close-button {
    color: #6b7280;
  }
  
  .bid-confirmation-modal-close-button:hover {
    color: #374151;
  }
  
  .bid-confirmation-modal-close-button-success {
    color: white;
  }
  
  .bid-confirmation-modal-close-button-success:hover {
    color: #f3f4f6;
  }
  
  .bid-confirmation-modal-content {
    padding: 1.5rem;
  }
  
  .bid-confirmation-modal-timer {
    margin-bottom: 1.5rem;
  }
  
  .bid-confirmation-modal-timer-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  
  .bid-confirmation-modal-timer-icon {
    margin-right: 0.5rem;
    color: #6b7280;
  }
  
  .bid-confirmation-modal-timer-label {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .bid-confirmation-modal-timer-digits {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.25rem;
    font-weight: 700;
  }
  
  .bid-confirmation-modal-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .bid-confirmation-modal-detail-row {
    display: flex;
    justify-content: space-between;
  }
  
  .bid-confirmation-modal-detail-label {
    color: #6b7280;
  }
  
  .bid-confirmation-modal-detail-value {
    font-weight: 500;
  }
  
  .bid-confirmation-modal-detail-row-highlighted {
    font-weight: 600;
  }
  
  .bid-confirmation-modal-total-row {
    border-top: 1px solid #e5e7eb;
    padding-top: 0.5rem;
    font-weight: 600;
  }
  
  .bid-confirmation-modal-footer {
    padding: 1rem;
    background-color: #f9fafb;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    display: flex;
    justify-content: center;
  }
  
  .bid-confirmation-modal-submit-button {
    background-color: rgb(3 132 251);
    color: white;
    padding: 0.5rem 2rem;
    border-radius: 0.25rem;
    transition: background-color 0.2s;
    cursor :pointer;
  }
  
  /* .bid-confirmation-modal-submit-button:hover {
    background-color: #ea580c;
  } */
  
  .bid-confirmation-modal-help {
    padding: 1rem;
    text-align: center;
  }
  
  .bid-confirmation-modal-help-link {
    color: #2563eb;
    font-size: 0.875rem;
    cursor: pointer;
    display:none;
  }
  
  .bid-confirmation-modal-help-link:hover {
    text-decoration: underline;
  }