.alert-drawer-title {
    color: #000000;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 35px;
    margin-bottom: 20px;
}

.alert-drawer-register-title {
    color: #2A69A3;
    font-size: 24px;
    letter-spacing: 0.4px;
    line-height: 16px;
    border: none;
    background: none;
    outline: none;
    text-align: left;
    margin-bottom: 20px;
}

.register-subtitle-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
}

.alert-drawer-sign-up-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid #d8d8d8;
    border-radius: 4px;
    width: 90%;
}

.alert-drawer-sign-up-subscribe-check-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 20px;
}