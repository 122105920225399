/* SubmitCheckoutForm.css */

/* Container and Modal Styles */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
       max-width:1000px !important;
       width:  1000px !important;
}
.submit-checkout-form-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.submit-checkout-form-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 20px;
  color: #6b7280;
  cursor: pointer;
  z-index: 10;
  padding: 4px;
  border-radius: 4px;
}

.submit-checkout-form-close:hover {
  background-color: #f3f4f6;
}

/* Progress Steps */
.submit-checkout-form-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top:20px;
}

.submit-checkout-form-step {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 1;
  justify-content: center;
}

/* .submit-checkout-form-step:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -50%;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: #e5e7eb;
  z-index: 0;
}

.submit-checkout-form-step.active::after {
  background-color: #d97706;
}

.submit-checkout-form-step.completed::after {
  background-color: #10b981;
} */

.submit-checkout-form-step-number {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #e5e7eb;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}

.submit-checkout-form-step.active .submit-checkout-form-step-number {
  background-color: #d97706;
  color: white;
}

.submit-checkout-form-step.completed .submit-checkout-form-step-number {
  background-color: #10b981;
  color: white;
}

.submit-checkout-form-step-text {
  font-size: 14px;
  color: #6b7280;
  font-weight: 500;
}

.submit-checkout-form-step.active .submit-checkout-form-step-text {
  color: #d97706;
  font-weight: 600;
}

.submit-checkout-form-step.completed .submit-checkout-form-step-text {
  color: #10b981;
}

/* Content Area */
.submit-checkout-form-content {
  padding: 24px;
  max-height: calc(100vh - 245px);
  overflow-y: auto;
}

.submit-checkout-form-section {
  margin-bottom: 32px;
}

.submit-checkout-form-section-title {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 20px;
}

.submit-checkout-form-section-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 16px;
}

/* Price Information */
.submit-checkout-form-price-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.submit-checkout-form-price-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.submit-checkout-form-price-label {
  font-size: 14px;
  color: #6b7280;
}

.submit-checkout-form-price-value {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
}

/* Form Fields */
.submit-checkout-form-field {
  margin-bottom: 14px;
}

.submit-checkout-form-field label {
  display: block;
  font-size: 14px;
  color: #374151;
  margin-bottom: 6px;
  font-weight: 500;
}

.submit-checkout-form-required {
  color: #ef4444;
  margin-left: 4px;
}

.submit-checkout-form-input,
.submit-checkout-form-select {
  width: 94%;
  padding: 10px 12px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 14px;
  color: #111827;
  transition: all 0.2s ease;
}

.submit-checkout-form-input:focus,
.submit-checkout-form-select:focus {
  outline: none;
  border-color: #d97706;
  box-shadow: 0 0 0 3px rgba(217, 119, 6, 0.1);
}

.submit-checkout-form-field-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0px; /* Adjust vertical gap (e.g., 10px) */
  column-gap: 20px; /* Horizontal gap remains 20px */
}

/* Radio and Checkbox Groups */
.submit-checkout-form-radio-group {
  display: flex;
  gap: 24px;
  margin-top: 8px;
  margin-bottom:18px;
}

.submit-checkout-form-radio-label,
.submit-checkout-form-checkbox-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}

.submit-checkout-form-checkbox-label span {
  font-size: 14px;
  color: #374151;
  width:95%;
}
.submit-checkout-form-checkbox{
    width:18px !important;
    height:18px !important;
}
/* Legal Disclosures */
.submit-checkout-form-disclosures {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.submit-checkout-form-disclosure-item {
  background-color: #f9fafb;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.submit-checkout-form-disclosure-text {
  margin-top: 8px;
  font-size: 14px;
  color: #6b7280;
  line-height: 1.5;
}

/* Review Step */
.submit-checkout-form-review-section {
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.submit-checkout-form-review-section h3 {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 16px;
}

.submit-checkout-form-review-item {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 12px 0;
  border-bottom: 1px solid #e5e7eb;
}

.submit-checkout-form-review-item:last-child {
  border-bottom: none;
}

.submit-checkout-form-review-label {
  color: #6b7280;
  font-size: 14px;
}

.submit-checkout-form-review-value {
  color: #111827;
  font-weight: 500;
  font-size: 14px;
}

/* Contract Step */
.submit-checkout-form-contract-notice {
  background-color: #fff7ed;
  border: 1px solid #fdba74;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}

.submit-checkout-form-contract-notice h4 {
  color: #c2410c;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.submit-checkout-form-warning-icon {
  color: #c2410c;
}

.submit-checkout-form-contract-notice p {
  color: #9a3412;
  font-size: 14px;
  line-height: 1.5;
}

.submit-checkout-form-contract-terms {
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 24px;
}

.submit-checkout-form-contract-terms h3 {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 16px;
}

.submit-checkout-form-contract-terms ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.submit-checkout-form-contract-terms li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  font-size: 14px;
  color: #4b5563;
  line-height: 1.5;
}

.submit-checkout-form-contract-terms li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #d97706;
}

/* Navigation Buttons */
.submit-checkout-form-navigation {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 20px;
  border-top: 1px solid #e5e7eb;
  background-color: #f9fafb;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.submit-checkout-form-back-button,
.submit-checkout-form-next-button,
.submit-checkout-form-submit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
}

.submit-checkout-form-back-button {
  background-color: white;
  border: 1px solid #d1d5db;
  color: #374151;
  cursor:pointer;
}

.submit-checkout-form-back-button:hover {
  background-color: #f3f4f6;
}

.submit-checkout-form-next-button,
.submit-checkout-form-submit-button {
  background-color: #d97706;
  color: white;
  border: none;
  cursor:pointer;
}

.submit-checkout-form-next-button:hover,
.submit-checkout-form-submit-button:hover {
  background-color: #b45309;
}

.submit-checkout-form-next-button:disabled,
.submit-checkout-form-submit-button:disabled {
  background-color: #d1d5db;
  cursor: not-allowed;
}
.submit-checkout-form-contract-item > span:first-child {
  display:inline-block;
    width:190px;
    font-weight:bold;
} 
/* Responsive Design */
@media (max-width: 768px) {
  .submit-checkout-form-progress {
    padding: 16px;
  }

  .submit-checkout-form-step-text {
    display: none;
  }

  .submit-checkout-form-price-info {
    grid-template-columns: 1fr;
  }

  .submit-checkout-form-field-grid {
    grid-template-columns: 1fr;
  }

  .submit-checkout-form-navigation {
    padding: 16px;
  }
}

/* Tooltips and Info Icons */
.submit-checkout-form-info-icon {
  color: #6b7280;
  margin-left: 4px;
  cursor: help;
}

/* Animation for step transitions */
.submit-checkout-form-step-content {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}