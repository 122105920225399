/* src/App.css */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');


.event-calender-main-container {
    width: 80%;
    margin: auto;
    margin-top: 70px;
    /* font-family: (stack) Nunito, "Helvetica Neue", helvetica, arial sans-serif; */
    margin-bottom: 40px;
}

.date-picker-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    background-color: #e0e1e2;
    border-radius: 4px;
}

.calendar-icon {
    margin-right: 10px;
    /* Adjust spacing as needed */
    margin-left: 10px;
    color: rgb(191 191 191 / 87%);
}

.event-calendar-date-picker {
    flex: 1;
    /* This ensures the DatePicker takes up the remaining space */
}

.event-calender-header-main {
    /* margin-bottom: 20px; */
    border-bottom: 4px solid #C0C8CC;
    padding-bottom: 30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.filter-button {
    width: 96%;
    padding: 9px 15px;
    font-size: 18px;
    line-height: 24px;
    background-color: #fff;
    border-color: #036eb5;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    color: #036eb5;
    margin-bottom: 40px;
    cursor: pointer;
}

.filter-button:hover {
    background-color: #f0fbfe;
}

.event-calender-main-container h1 {
    /* font-family: (stack) Nunito, "Helvetica Neue", helvetica, arial sans-serif; */
    font-weight: 450;
    color: #00263e;
    margin-bottom: 40px;
    font-size: 36px;
}

.event-calender-event-name {
    color: #838a8f;

}

.event-calender-table-header {
    /* font-family: Nunito; */
    color: RGB(0, 38, 62);
    font-weight: 400;
    font-size: 16px;

}

.event-calendar-date-picker {
    width: 19%;
    height: 42px;
}
.event-calender-label-hide-past-event{
    font-weight: 500;
    color: #75757e;
}
.eventer-calender-select {
    width: 18%;
    padding: 10px;
    padding-right: 30px;
    /* Space for the arrow */
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    appearance: none;
    cursor: pointer;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5"><path fill="%23999" d="M0 0l5 5 5-5H0z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px 10px;
}

/* .eventer-calender-select::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
} */

/* EventCalendar.css */
label {
    /* margin-left: 15px; */
    user-select: none;
    /* prevents text selection */
    cursor: pointer;
}

/*  */
input[type="checkbox"] {
    margin-right: 5px;
}

.event-calender-table-data td {
    font-size: 14px;

    font-weight:
        400;
    text-align:
        start;
    text-indent:
        0px;

    padding: 20px 10px;
}

/* src/App.css or in your component's CSS file */
.react-datepicker-wrapper {
    width: 200px;
    /* Adjust width as needed */
}

.react-datepicker__input-container input {
    width: 200px;
    padding: 0px;
    border: 0px solid #ccc;
    border-left: 1px solid #ccc;
    /* border-radius: 4px; */
    padding-right: 30px;
    /* Make space for the icon */
    box-sizing: border-box;
    padding-left: 10px;
    font-size: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* font-weight: bold; */
}
.event-calendar-date-picker::placeholder {
    color: #75757e; /* Change this to your desired color */
    font-weight: 500;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
}
.event-calendar-date-picker:focus{
       border: 1px solid rgb(3, 132, 251);
       outline: none;
}   

/* Example of adding an icon inside the input field */
.react-datepicker__input-container input::after {
    content: "\1F4C5";
    /* Calendar Emoji as an example */


}


table {
    width: 100%;
    /* margin-top: 50px; */
    border-collapse: collapse;

}

th,
td {
    /* border-bottom: 1px solid #8f8d8d; */
    border-bottom: 1px solid #8f8f8f;

    padding: 8px;
    text-align: left;
}

.eventer-calender-select {
    margin: 0 10px;
}

/* EventCalendar.css */
.pagination {
    display: flex;
    list-style-type: none;
    justify-content: center;
}

.pagination .page-item {
    margin: 0 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.pagination .page-item a {
    display: block;
    padding: 5px 10px;
    color: black;
    text-decoration: none;
}

.pagination .page-item.active a {
    background-color: #007bff;
    color: white;
}

.pagination .page-item a:hover {
    background-color: #0056b3;
    color: white;
}

.event-calendar-pagination-container {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    font-weight: 400;
    color: #000000;
}

.event-calendar-pagination-info {
    margin-bottom: 10px;
    font-size: 14px;
}

.event-calendar-pagination-controls {
    display: flex;
    align-items: center;
}

.event-calendar-pagination-button {
    background-color: #fff;
    border: 1px solid #ccc;
    color: rgb(0 0 0 / 95%);
    cursor: pointer;
    padding: 13px 16px;
    transition: background-color 0.3s, color 0.3s;
}

.event-calendar-pagination-button.event-calendar-active {
    background-color: rgb(0 0 0 / 5%);
}

.event-calendar-pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.event-calendar-pagination-ellipsis {
    padding: 5px 10px;
    color: #999;
}

.event-calendar-pagination-controls .event-calendar-pagination-button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.event-calendar-pagination-controls .event-calendar-pagination-button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

@media screen and (max-width:1140px) {
    .event-calender-main-container {
        width: 96%;
    }

    .react-datepicker-wrapper {
        width: 150px;
        /* Adjust width as needed */
    }

    .eventer-calender-select {
        width: 150px;
    }

    .react-datepicker__input-container {
        width: 100%;
    }

    .react-datepicker__input-container input {
        width: 100%;
    }
}
@media screen and (max-width:1000px){
    .event-calender-main-container h1 {
        font-size: 30px;
    }
}
@media screen and (max-width:930px) {
    .event-calender-header-main {
        /* margin-bottom: 20px; */
        border-bottom: 0px solid #C0C8CC;
        padding-bottom: 30px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
        width: 250px;
        padding-top: 10px;
        padding-left: 10px;
        height: auto;
    }

    .react-datepicker-wrapper {
        width: 160px;
        /* Adjust width as needed */
        margin-left: 10px;
        height: 40px;
    }

    .eventer-calender-select {
        width: 200px;
        height: 40px;
    }

    .react-datepicker__input-container {
        width: 100%;
        height: 40px;
    }

    .react-datepicker__input-container input {
        width: 100%;
        height: 40px;
    }
    .event-calender-dropdown-input{
        width: 172px !important;
    }
    .event-calender-dropdown-menu{
        width: 204px !important;
    }
}
@media screen and (max-width:630px){
    .event-calendar-pagination-container{
        flex-direction: column;
    }
    .event-calendar-pagination-button{
        padding: 10px 13px;
    }
}
@media screen and (max-width:450px){
    .event-calender-main-container h1 {
        font-size: 24px;
    }
}
/* Add more styles as needed */