/* UserPortal.css */
.user-portal-container {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    margin-bottom: 150px;
    font-size: 16px;
    font-weight: 400;
}

.user-portal-sidebar {
    width: 20%;
    padding: 10px;
    border: 1px solid rgb(209 222 234);
    max-height: 365px;
}

.user-portal-menu {
    list-style: none;
    padding: 0;
}

.user-portal-menu li {
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: bold;
    border-bottom: 1px solid #D1DEEA;
    padding-left: 0px;
}
.user-portal-menu li:last-child{
    border-bottom: 0px;
}
.user-portal-menu li.active {
    color: rgb(3 132 251);
}

.user-portal-content {
    width: 100%;
    width: 69%;
    border: 1px solid rgb(209 222 234);
}

.portal-horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #D1DEEA;
    margin-bottom: 30px;
}
@media screen and (max-width:836px){
    .user-portal-sidebar{
         width: 25%;
    }
}
@media screen and (max-width:768px){
    .user-portal-sidebar{
         display: none;
    }
    .user-portal-content{
        width: 97%;
    }
}
@media screen and (max-width:500px){
    .user-portal-content{
        width: 97%;
    }
}
