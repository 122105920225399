.making-bidding-easier-home {
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 50px;
  width: 100%;
}

.making-bidding-image-container {
  position: relative;
}

.making-bidding-easier-home button {
  border: none;
  border-radius: 5px;
  background-color: rgb(3 132 251);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: auto;
  text-align: center;
  text-transform: uppercase;
  margin-top: 30px;
}

.making-bidding-easier-home button:hover {
  background-color: #0056b3;
}

.making-bidding-easier-home h1 {
  font-size: 36px;
  line-height: 42px;
  color: #00263e;
  font-weight: 500;
}

.making-bidding-my-image {
  filter: brightness(80%);
}

.making-bidding-easier-home p {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  width: 69%;
  margin: 0 auto;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 20px;
}

.making-bidding-image-row {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 1px;
  width: 100%;
  margin: auto;
  margin-top: 40px;
}

.making-bidding-image-container {
  position: relative;
  width: 28%;
  /* aspect-ratio: 1/1; */
  /* overflow: hidden; */
  /* background-color: red; */
}

.making-bidding-image-container .making-bidding-overlay {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0));
  z-index: 1;
  /* transition: opacity 0.5s ease; */
}

.making-bidding-image-container:hover .making-bidding-overlay {
  opacity: 0;
}

.making-bidding-image-container div,
.making-bidding-image-container span {
  position: absolute;
  z-index: 2;
  /* Ensure text appears above the overlay */
}

.making-bidding-image-container img {
  width: 100%;
  aspect-ratio: 1/1;
  transition: transform 0.5s ease;
  position: relative;
  z-index: 0;
  border-radius: 4px;
  /* Ensure image appears below the overlay */
}

.making-bidding-image-container:hover img {
  transform: scale(1.08, 1.1);
  filter: brightness(60%);
}

.making-bidding-image-container div h2 {
  font-size: 24px;
  color: white;
  line-height: 30px;
  font-weight: 500;
}

.making-bidding-image-container p {
  font-size: 15px;
  width: 100%;
  color: white;
  text-align: left;
  height: 0px;
  /* overflow: hidden; */
  opacity: 0;
  transition: all 0s ease-in-out;
}

.making-bidding-image-container span a {
  text-decoration: none;
  color: white;
  font-size: 16px;

}

.making-bidding-image-container div {
  position: absolute;
  text-align: left;
  margin-right: 20px;
  /* margin-left: 20px; */
  bottom: 10%;
  left: 1%;
  z-index: 1;
  /* border-top: 1px solid #e5e7eb; */
  width: 93%;
  border-top: 1px solid white;
}

.making-bidding-image-container span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  bottom: 5%;
  /* padding-left: 20px; */
  left: 1%;
  font-size: 20px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  z-index: 1;
}

.making-bidding-image-container:hover {
  z-index: 10;
}

.making-bidding-image-container:hover p {
  height: auto;
  opacity: 1;
  margin-bottom: 10px;
}
@media all and (max-width:1000px) {
  .making-bidding-image-container {
    width: 49%;
  }
  .making-bidding-easier-home h1{
    font-size: 30px;
  }
}

@media all and (max-width: 875px) {
  .making-bidding-image-row {
    /* display: none; */
    flex-direction: column;
  }

  .making-bidding-image-container .making-bidding-overlay {
    position: absolute;
    top: 0;
    left: -4%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0));
    z-index: 1;
    /* transition: opacity 0.5s ease; */
  }

  .making-bidding-image-container {
    position: relative;
    width: 450px;
    margin: 0 auto;
  }
}
@media screen and (max-width:650px){
  .making-bidding-easier-home p {
      width: 74%;
  }
  
}
@media all and (max-width:450px) {
  .making-bidding-easier-home h1 {
    font-size: 24px;
    font-weight: 500;
}
  .making-bidding-image-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
}
@media screen and (min-width:1100px){
  .making-bidding-para {
     width: 55% !important;
  } 
}