.breadcrumb-nav {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  padding: 10px 20px;
  padding-left: 38px;
}

.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.breadcrumb-link {
  text-decoration: none;
  color: #0073e6;
  font-weight: bold;
}

.breadcrumb-link:hover {
  text-decoration: underline;
}

.breadcrumb-separator {
  margin: 0 5px;
  color: #666;
}

.breadcrumb-current {
  color: black;
  font-weight: bold;
}
@media screen and (max-width: 900px) {
  .breadcrumb-nav {
    width: 90%;
  }
}
@media screen and (max-width:810px){
  .breadcrumb-nav {
    display:none;
  }
}
