/* AuctionRegistrationForm.css - Part 1 */

/* Container and Modal Styles */
.auction-registration-form-container {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; /* For better iOS scrolling */
  }
  
  .auction-registration-form-close {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    font-size: 20px;
    color: #6b7280;
    cursor: pointer;
    z-index: 10;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: all 0.2s ease;
  }
  
  .auction-registration-form-close:hover {
    background-color: #f3f4f6;
    color: #374151;
  }
  
  .auction-registration-form-header {
    padding: 24px 32px;
    border-bottom: 1px solid #e5e7eb;
    background-color: #f9fafb;
  }
  
  .auction-registration-form-header h2 {
    font-size: 22px;
    font-weight: 600;
    color: #111827;
    margin: 0 0 8px 0;
  }
  
  .auction-registration-form-header p {
    font-size: 14px;
    color: #6b7280;
    margin: 0;
  }
  /* AuctionRegistrationForm.css - Part 2 */

/* Progress Steps */
.auction-registration-form-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 40px;
    border-bottom: 1px solid #e5e7eb;
    position: relative;
    background-color: #fff;
  }
  
  .auction-registration-form-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    z-index: 2;
  }
  
  .auction-registration-form-step::before,
  .auction-registration-form-step::after {
    content: '';
    position: absolute;
    top: 20px;
    height: 2px;
    background-color: #e5e7eb;
    z-index: 1;
  }
  
  .auction-registration-form-step::before {
    left: 0;
    right: 50%;
  }
  
  .auction-registration-form-step::after {
    left: 50%;
    right: 0;
  }
  
  .auction-registration-form-step:first-child::before,
  .auction-registration-form-step:last-child::after {
    display: none;
  }
  
  .auction-registration-form-step.active::after,
  .auction-registration-form-step.completed::after,
  .auction-registration-form-step.completed + .auction-registration-form-step::before {
    background-color: #0384fb;
  }
  
  .auction-registration-form-step-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e5e7eb;
    color: #6b7280;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    z-index: 2;
    margin-bottom: 8px;
    transition: all 0.3s ease;
  }
  
  .auction-registration-form-step.active .auction-registration-form-step-number {
    background-color: #0384fb;
    color: white;
  }
  
  .auction-registration-form-step.completed .auction-registration-form-step-number {
    background-color: #10b981;
    color: white;
  }
  
  .auction-registration-form-step-text {
    font-size: 14px;
    color: #6b7280;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  /* AuctionRegistrationForm.css - Part 3 */

.auction-registration-form-step.active .auction-registration-form-step-text {
    color: #0384fb;
    font-weight: 600;
  }
  
  .auction-registration-form-step.completed .auction-registration-form-step-text {
    color: #10b981;
  }
  
  /* Content Area */
  .auction-registration-form-content {
    padding: 24px 32px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
  }
  
  .auction-registration-form-section {
    margin-bottom: 32px;
  }
  
  .auction-registration-form-section-title {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 20px;
  }
  
  .auction-registration-form-section-subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #374151;
    margin-bottom: 16px;
  }
  
  /* Animation for step transitions */
  .auction-registration-form-step-content {
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  /* Price Information */
  .auction-registration-form-price-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    background-color: #f9fafb;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 24px;
    border: 1px solid #e5e7eb;
  }
  
  .auction-registration-form-price-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .auction-registration-form-price-label {
    font-size: 14px;
    color: #6b7280;
  }
  
  .auction-registration-form-price-value {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
  }
  /* AuctionRegistrationForm.css - Part 4 */

/* Form Fields */
.auction-registration-form-field {
    margin-bottom: 20px;
  }
  
  .auction-registration-form-field label {
    display: block;
    font-size: 14px;
    color: #374151;
    margin-bottom: 6px;
    font-weight: 500;
  }
  
  .auction-registration-form-required {
    color: #ef4444;
    margin-left: 4px;
  }
  
  .auction-registration-form-input,
  .auction-registration-form-select {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 14px;
    color: #111827;
    transition: all 0.2s ease;
    background-color: #fff;
  }
  
  .auction-registration-form-input:focus,
  .auction-registration-form-select:focus {
    outline: none;
    border-color: #0384fb;
    box-shadow: 0 0 0 3px rgba(3, 132, 251, 0.1);
  }
  
  .auction-registration-form-input::placeholder {
    color: #9ca3af;
  }
  
  .auction-registration-form-field-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;  
    column-gap: 35px; 
  }
  
  .auction-registration-form-buyer-type {
    margin-bottom: 24px;
  }
  
  .auction-registration-form-company-info {
    background-color: #f9fafb;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 24px;
    border: 1px solid #e5e7eb;
  }
  
  .auction-registration-form-personal-info,
  .auction-registration-form-address-info {
    margin-bottom: 24px;
  }
  
  .auction-registration-form-highest-best {
    margin-bottom: 24px;
  }
  /* AuctionRegistrationForm.css - Part 5 */

/* Radio and Checkbox Groups */
.auction-registration-form-radio-group {
    display: flex;
    gap: 24px;
    margin-top: 8px;
    margin-bottom: 18px;
  }
  
  .auction-registration-form-radio-label,
  .auction-registration-form-checkbox-label {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;
    user-select: none;
  }
  
  .auction-registration-form-radio-label input[type="radio"] {
    margin-top: 3px;
    width: 16px;
    height: 16px;
    accent-color: #0384fb;
  }
  
  .auction-registration-form-checkbox-label span {
    font-size: 14px;
    color: #374151;
  }
  
  .auction-registration-form-checkbox {
    margin-top: 3px;
    width: 18px !important;
    height: 18px !important;
    accent-color: #0384fb;
  }
  
  /* Legal Disclosures */
  .auction-registration-form-disclosures {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .auction-registration-form-disclosure-item {
    background-color: #f9fafb;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
  }
  
  .auction-registration-form-disclosure-text {
    margin-top: 8px;
    margin-left: 26px;
    font-size: 14px;
    color: #6b7280;
    line-height: 1.5;
  }
  
  .auction-registration-form-info-icon {
    color: #6b7280;
    margin-left: 4px;
    font-size: 14px;
    cursor: help;
  }
  /* AuctionRegistrationForm.css - Part 6 */

/* Review Step */
.auction-registration-form-review-section {
    background-color: #f9fafb;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 24px;
    border: 1px solid #e5e7eb;
  }
  
  .auction-registration-form-review-section h3 {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
    margin-top: 0;
    margin-bottom: 16px;
  }
  
  .auction-registration-form-review-item {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 8px 0;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .auction-registration-form-review-item:last-child {
    border-bottom: none;
  }
  
  .auction-registration-form-review-label {
    color: #6b7280;
    font-size: 14px;
    min-width: 120px;
  }
  
  .auction-registration-form-review-value {
    color: #111827;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
  }
  
  .auction-registration-form-review-checklist {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  
  .auction-registration-form-review-check {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #374151;
  }
  
  .auction-registration-form-review-check svg {
    color: #10b981;
  }
  
  .text-green-500 {
    color: #10b981;
  }
  /* AuctionRegistrationForm.css - Part 7 */

/* Contract Step */
.auction-registration-form-contract-notice {
    background-color: #fff7ed;
    border: 1px solid #fdba74;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
  }
  
  .auction-registration-form-contract-notice h4 {
    color: #c2410c;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .auction-registration-form-warning-icon {
    color: #c2410c;
  }
  
  .auction-registration-form-contract-notice p {
    color: #9a3412;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 0;
  }
  
  .auction-registration-form-contract-summary {
    margin-bottom: 24px;
  }
  
  .auction-registration-form-contract-summary h3 {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
    margin-top: 0;
    margin-bottom: 16px;
  }
  
  .auction-registration-form-contract-details {
    background-color: #f9fafb;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
  }
  
  .auction-registration-form-contract-terms {
    background-color: #f9fafb;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 24px;
    border: 1px solid #e5e7eb;
  }
  
  .auction-registration-form-contract-terms h3 {
    font-size: 16px;
    font-weight: 600;
    color: #111827;
    margin-top: 0;
    margin-bottom: 16px;
  }
  
  .auction-registration-form-contract-terms ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  /* AuctionRegistrationForm.css - Part 8 */

.auction-registration-form-contract-terms li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;
    font-size: 14px;
    color: #4b5563;
    line-height: 1.5;
  }
  
  .auction-registration-form-contract-terms li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #0384fb;
    font-size: 18px;
    line-height: 1;
  }
  
  .auction-registration-form-contract-terms li:last-child {
    margin-bottom: 0;
  }
  
  .auction-registration-form-contract-agreements {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .auction-registration-form-contract-agreements .auction-registration-form-disclosure-item {
    background-color: #f8fafc;
  }
  
  /* Navigation Buttons */
  .auction-registration-form-navigation {
    display: flex;
    justify-content: space-between;
    padding: 20px 32px;
    border-top: 1px solid #e5e7eb;
    background-color: #f9fafb;
  }
  
  .auction-registration-form-back-button,
  .auction-registration-form-next-button,
  .auction-registration-form-submit-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  
  .auction-registration-form-back-button {
    background-color: white;
    border: 1px solid #d1d5db;
    color: #374151;
  }
  
  .auction-registration-form-back-button:hover {
    background-color: #f3f4f6;
  }
  /* AuctionRegistrationForm.css - Part 9 */

.auction-registration-form-next-button,
.auction-registration-form-submit-button {
  background-color: #0384fb;
  color: white;
  border: none;
}

.auction-registration-form-next-button:hover,
.auction-registration-form-submit-button:hover {
  background-color: #0369a1;
}

.auction-registration-form-next-button:disabled,
.auction-registration-form-submit-button:disabled {
  background-color: #d1d5db;
  cursor: not-allowed;
  opacity: 0.7;
}

.auction-registration-form-back-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Info icons and tooltips */
.auction-registration-form-tooltip {
  position: relative;
  display: inline-block;
}

.auction-registration-form-tooltip .auction-registration-form-tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #374151;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.auction-registration-form-tooltip:hover .auction-registration-form-tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Success state */
.auction-registration-form-success {
  text-align: center;
  padding: 40px 20px;
}

.auction-registration-form-success-icon {
  font-size: 48px;
  color: #10b981;
  margin-bottom: 16px;
}
/* AuctionRegistrationForm.css - Part 10 */

.auction-registration-form-success h3 {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 8px;
  }
  
  .auction-registration-form-success p {
    font-size: 16px;
    color: #6b7280;
    margin-bottom: 24px;
  }
  
  .auction-registration-form-success-button {
    background-color: #0384fb;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-block;
  }
  
  .auction-registration-form-success-button:hover {
    background-color: #0369a1;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .auction-registration-form-progress {
      padding: 16px;
    }
  
    .auction-registration-form-step-text {
      display: none;
    }
  
    .auction-registration-form-content {
      padding: 16px;
    }
  
    .auction-registration-form-price-info {
      grid-template-columns: 1fr;
    }
  
    .auction-registration-form-field-grid {
      grid-template-columns: 1fr;
    }
  
    .auction-registration-form-navigation {
      padding: 16px;
    }
    
    .auction-registration-form-back-button,
    .auction-registration-form-next-button,
    .auction-registration-form-submit-button {
      padding: 10px 16px;
      font-size: 13px;
    }
    
    .auction-registration-form-review-checklist {
      grid-template-columns: 1fr;
    }
  
    .auction-registration-form-review-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  
    .auction-registration-form-review-value {
      text-align: left;
    }
  }
  
  /* Dark mode support (if needed) */
  @media (prefers-color-scheme: dark) {
    /* Dark mode styles can be added here */
  }