.news-page-newsroom {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  margin-bottom: 100px;
}

.news-page-news-feature {
  display: flex;
  background-color: #002b49;
  color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.news-page-news-feature-text {
  width: 40%;
  padding: 80px;

}

.news-page-news-feature-title {
  color: rgb(3 132 251);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 12px;
}

.news-page-news-feature-heading {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  margin-bottom: 12px;
  color: white;
}

.news-page-news-feature-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: white;
}

.news-page-news-feature-image {
  width: 60%;
}

.news-page-news-feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width:768px) {
  .news-page-news-feature {
    flex-direction: column-reverse;
  }

  .news-page-news-feature-image {
    width: 100%;
  }

  .news-page-news-feature-text {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .news-page-news-feature-heading {
    width: 90%;
  }

  .news-page-news-feature-description {
    width: 90%;
  }
}

@media screen and (max-width:430px) {
  .news-page-news-feature-text {
    padding-bottom: 30px;
  }
}