.admin-panel-sidebar {
    width: 17%;
    background:white;
    padding: 20px;
    padding-top: 20px;
    position: fixed;
    height: 100%;
    min-width: 150px;
  }
  .admin-panel-sidebar-header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #696cff;
    display: flex;
    align-items: center;
  }
  
  .admin-panel-sidebar-nav ul {
    width: 100%;
    overflow: hidden;
    padding: 0;
  }
  
  .admin-panel-sidebar-nav li {
    margin: 10px 0;
    margin-bottom: 20px;
    cursor: pointer;
    width: 100% !important;
    overflow: hidden;
  }
  .admin-sidebar-icon{
    margin-right: 10px;
  }
  .admin-panel-sidebar-close-icon{
     margin-left: 20px;
     display: none;
     cursor: pointer;
  }
  .admin-panel-sidebar-sublist {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    opacity: 0;
  }
  
  .admin-panel-sidebar-sublist.open {
    max-height: 200px; /* Adjust based on the number of items */
    opacity: 1;
  }

  .admin-panel-sidebar-sublist.closed {
    max-height: 0;
    opacity: 0;
  }
  
  .admin-panel-sidebar-sublist ul {
    padding-left: 20px;
  }
  
  .admin-panel-sidebar-sublist li {
    
    padding: 5px 0;
  }
  
  .admin-sidebar-icon-chevron-1 {
     margin-left: 52px;
  }
  .admin-sidebar-icon-chevron-2 {
    margin-left: 30px;
 }
  .admin-sidebar-accordion {
    cursor: pointer;
  }
  @media screen and (max-width:900px){
    .admin-panel-sidebar {
      min-width: 200px;
    }
    .admin-panel-sidebar-close-icon{
      display: block;
   }
  }