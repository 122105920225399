.buy-Navbar-font {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;

}

.house-circle-icon {
    background-color: rgb(3, 110, 181);
    width: 17px;
    height: 17px;
    border-radius: 100px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .2) !important;
    margin-top: -1px;
    margin-right: 5px;
}

.buy-navbar-property-dropdown-input {
    border-radius: 5px;
    height: 40px;
    width: 120px;
    font-size: 16px;
}

.condition-dropddown-buynavbar {
    padding-top: 0px;
    padding-bottom: 0px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 300;
    padding-left: 0px;
    display: flex;
    align-items: flex-start;
    width: 350px;
    cursor: pointer;
}

.condition-dropdown-end-para {
    width: 250px;
    padding-left: 15px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, .87);
    font-weight: 400;
}

.condition-dropddown-icon-text {
    margin-top: 9px;
    font-size: 14px;
    color: rgba(0, 0, 0, .87);
    font-weight: 400;
}

.condition-dropddown-icon-text p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    padding-right: 40px;
    margin-bottom: 0px;
    color: rgb(151, 160, 166);
    font-weight: 400;
}

.MoreFilter-hide-text {
    color: #263238;
    font-size: 15px;
    font-weight: 600;
    padding-left: 10px;
    margin-top: 20px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.more-filter-dropddown-hide-text {
    font-size: 14px;
    margin-top: 30px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    color: rgb(38, 50, 56);
    font-weight: 400;
}

.more-filter-dropddown-below-text {
    font-size: 14px;
    margin-top: 4px;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    color: rgb(38, 50, 56);
    font-weight: 400;
}

.hide-text-seven-days {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    padding-right: 40px;
    margin-bottom: 10px;
    color: rgb(151, 160, 166);
}
.property-dropdown-type-text{
    margin-top: 10px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    padding-left: 10px;
    font-size: 16px;
    color: #5c676e;
    width: 100%;
    margin-bottom: 10px;
}