.admin-panel-container {
    display: flex;
    font-family:"Roboto", Helvetica, Arial, sans-serif;
    background-color: #f5f5f9;
    color: #697a8d;
    min-height: 100vh;
    justify-content: space-between;
  }
  .admin-panel-sidebar-content{
    width: 20%;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .admin-panel-main-content {
    display: flex;
    flex-direction: column;
    width: 80%;
  }
    @media (max-width: 900px) {
      .admin-panel-main-content{
        width: 100%;
      }
      .admin-panel-sidebar-content {
        position: fixed;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%; /* Full height of viewport */
        transform: translateX(-300px); /* Initially off-screen */
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
        z-index: 1200; /* Higher z-index to overlay main content */
      }
    
      .admin-panel-sidebar-content.open {
        transform: translateX(0); /* Slide in sidebar */
        visibility: visible;
        width: 100%;
        background-color:  #43597177;
      }
    }