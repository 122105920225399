/* ResetPassword.css */


.new-password-container {
    max-width: 400px;
    margin: 100px auto;
    padding: 20px;
    text-align: center;
    margin-top: 50px;
}

.new-password-container .logo {
    /* width: 80px; */
    height: 30px;
    /* margin-bottom: 50px; */
}

.new-password-container h2 {
    /* font-family: 'Courier New', Courier, monospace; */
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 50px;
    color: #343A41;
}

.new-password-input-group {
    margin-bottom: 20px;
    text-align: center;
}

.new-password-input-group label {
    font-size: 13px;
    margin-bottom: 5px;
    color: #343A41;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 5px;
}

.new-password-input-group input {
    width: 100%;
    font-size: 16px;
    border-radius: 4px;
}

.set-new-password-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.new-password-container form a {
    text-decoration: none;
    display: block;
    width: 100%;
}

.set-new-password-button:hover {
    background-color: #0056b3;
}

.email-error-test-reset {
    font-size: 12px;
    color: red;
    margin-bottom: 10px;
    text-align: left;
}

.new-password-wrong-email-text {
    font-size: 14px;
    text-align: left;
    width: 100%;
    font-weight: 400;
}

.new-password-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    flex: 1 1;
}

.sign-up-input-label span:nth-child(1) {
    color: rgb(0 38 62);
    font-size: 13px;
    font-weight: 700;
}

.sign-up-input-label span:nth-child(2) {
    color: red;
}

@media screen and (max-width:1000px) {
    .new-password-container h2 {
        font-size: 30px;
    }
}

@media screen and (max-width:450px) {
    .new-password-container h2 {
        font-size: 24px;
    }
}
