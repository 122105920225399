.leadership-hero-section {
    display: flex;
    /* Adjust based on your design */
    padding: 20px;
    justify-content: center;
    margin: 0 auto;
    /* Adjust this to your desired height */
    height: 60vh;
    /* Adjust this to your desired height */
    background-color: transparent;
    /* Default background color */
    position: relative;
    gap: 100px;
    padding-top: 80px;
}

.leadership-hero-section::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 90%;
    background-color: rgb(240 246 254);
    /* Second background color */
    clip-path: inset(0 0 0 40%);
    z-index: -1;
}

.leadership-hero-image {
    width: 45%;
    display: flex;
    justify-content: flex-end;
}

.leadership-hero-image img {
    width: 62%;
    height: 72%;
}

.leadership-hero-text {
    width: 40%;
}

.leadership-hero-text h2 {
    margin-top: 0px;
    color: #00263e;
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
}

.leadership-hero-text p {
    color: #333;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    max-width: 400px;
    /* Adjust based on your design */
}

@media screen and (max-width:1100px) {
    .leadership-hero-image img {
        width: 90%;
        height: 85%;
    }
}

@media screen and (max-width:1000px) {
    .leadership-hero-text h2 {
        font-size: 40px;
    }
}

@media screen and (max-width:1050px) {
    .leadership-hero-image img {
        width: 100%;
        height: 85%;
    }
}

@media screen and (max-width:860px) {
    .leadership-hero-image img {
        height: 75%;
    }
}

@media screen and (max-width:768px) {
    .leadership-hero-section {
        flex-direction: column-reverse;
        gap: 0px;
        padding-top: 60px;
        height: auto;
    }

    .leadership-hero-text {
        width: 97%;
    }

    .leadership-hero-text p {
        max-width: 100%;
    }

    .leadership-hero-image {
        width: 100%;
    }

    .leadership-hero-image img {
        height: auto;
    }
}

@media screen and (max-width:450px) {
    .leadership-hero-text h2 {
        font-size: 30px;
    }
}

/* @media screen and (max-width:900px){
    .leadership-hero-section {
       flex-direction: column;
    }
    
} */