.resources {
    background: url("./assests/resources-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-position: center;
}

.resources-container {
    margin-top: 60px;
    width: 100%;
    height: auto;
    padding-bottom: 100px;
    padding-top: 70px;
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1039%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='rgba(240%2c 246%2c 254%2c 1)'%3e%3c/rect%3e%3cpath d='M 0%2c365 C 57.6%2c347.2 172.8%2c259.8 288%2c276 C 403.2%2c292.2 460.8%2c464 576%2c446 C 691.2%2c428 748.8%2c186.8 864%2c186 C 979.2%2c185.2 1036.8%2c430.6 1152%2c442 C 1267.2%2c453.4 1382.4%2c282.8 1440%2c243L1440 560L0 560z' fill='rgba(206%2c 229%2c 252%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1039'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
    ; */

    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
}

.resources-container h3 {
    color: #a5a5a5;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    text-align: center;
}

.resources_heading {
    font-size: 36px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 42px;
    margin-bottom: 10px;
    color: #00263E;
    margin: 0;
    margin-bottom: 18px;
    padding: 0%;
    padding-top: 17px;
    padding-bottom: 30px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.resources-container .resources-container-main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 0px;
    width: 85%;
    margin: 0 auto;
    padding-left: 70px;
}

.image-and-text-container {
    width: 40%;
    height: fit-content;
    display: flex;
    /* justify-content: space-between; */
    padding: 5px 0;
}

.image-and-text-container .resources-blog-images {
    width: 141px;
    height: 93px;
    margin-right: 1%;
    /* border-radius: 5px; */
}

.image-and-text-container .text-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    /* justify-content: space-around; */
    padding-left: 20px;

}

.image-and-text-container .text-container .hyper-links-blog-linking {
    font-weight: 450;
    text-align: left;
    text-decoration: none;
    font-size: 15px;
    width: 95%;
}

.text-container span {
    color: #333;
    font-size: 17px;
    font-weight: 450;
    text-align: left;
    line-height: 30px;
    width: 90%;
    padding-bottom: 5px;
    color: #333333;
}

.text-container :nth-child(2) {
    color: rgb(3 132 251);
    cursor: pointer;
    width: auto;
}
.image-and-text-container {
    overflow: hidden;
}
@media screen and (max-width:1150px) {
    .resources-container .resources-container-main {
        width: 95%;
        padding-left: 40px;
    }

    .image-and-text-container {
        width: 45%;
    }
}
@media screen and (max-width:1000px){
    .resources_heading{
        font-size: 30px;
    }
}

@media all and (max-width:990px) {
    .text-container span {
        font-size: 15px;
    }

    .image-and-text-container img {
        width: 30%;
    }

    .resources-container {
        width: 100%;
    }
}

@media screen and (max-width:830px) {
    .resources-container .resources-container-main {
        width: 98%;
        padding-left: 5px;
    }

    .image-and-text-container {
        width: 49%;
    }

}

@media all and (max-width:750px) {
    .resources-container {
        width: 100%;

    }

    .image-and-text-container {
        width: 100%;
        padding-left: 0px;
    }

    .image-and-text-container img {
        width: 30%;
    }

    .resources .resources-container {
        justify-content: flex-start;
        align-items: center;
        /* margin: 10px; */


    }

    .text-container span {
        font-size: 20px;
    }

    .image-and-text-container .text-container {
        justify-content: flex-start;
        width: 55%;
    }
}

@media screen and (max-width:500px) {
    .resources_heading {
        font-size: 24px;
        font-weight: 500;
    }
}