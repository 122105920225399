.news-update-container {
    display: flex;
    justify-content: space-between;
}

.news-update-container a {
    text-decoration: none;
}

.news-update-press-releases {
    width: 60%;
    height: 100%;
}

.news-update-in-the-news {
    width: 35%;
    height: 100%;
}

.news-update-press-releases h5,
.news-update-in-the-news h5 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 30px;
    margin-top: 0;
    text-transform: uppercase;
    color: #00263e;
}

.news-update-press-releases ul,
.news-update-in-the-news ul {
    list-style-type: none;
    padding: 0;
}

.news-update-in-the-news p {
    color: #333;
    /* font-size: 16px; */
    font-weight: 400;
    line-height: 26px;
}

.news-update-press-releases li,
.news-update-in-the-news li {
    margin-bottom: 40px;
}

.news-update-press-releases h3,
.news-update-in-the-news h3 {
    color: #00263e;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 12px;
    margin-top: 0;
}

.news-update-press-releases p {
    font-size: 0.9em;
    color: gray;
}

.news-update-view-all a {
    display: block;
    margin-top: 10px;
    text-decoration: none;
    color: #007bff;
}

.news-update-press-releases a:hover,
.news-update-in-the-news a:hover {
    text-decoration: underline;
}

.news-image {
    width: 140px;
    height: 35px;
}

.news-update-view-all {
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;
}

.small-screen-news-update-view-all {
    display: none;
}

@media screen and (max-width:1000px) {

    .news-update-press-releases h3,
    .news-update-in-the-news h3 {
        font-size: 22px;
    }
}

@media screen and (max-width:768px) {
    .news-update-container {
        flex-direction: column;
        align-items: center;
    }

    .news-update-press-releases {
        width: 100%;
    }

    .news-update-in-the-news {
        width: 100%;
    }

    .small-screen-news-update-view-all {
        display: block;
        margin-bottom: 40px;
    }

    .small-screen-news-update-view-all a {
        text-decoration: none;
        color: #007bff;
    }
}

@media screen and (max-width:450px) {
    .news-update-press-releases {
        width: 95%;
    }

    .news-update-in-the-news {
        width: 95%;
    }
}