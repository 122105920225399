/* src/components/RegistrationForm.add-auction-css */
.add-auction-registration-form {
  width: 95%;
  max-width: 1200px;
  margin: auto;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
  margin-top: 20px;
  margin-bottom: 20px;
}
.add-aution-container{
  width: 95%;
  margin: 0 auto;
}
.add-auction-registration-form h2 {
  text-align: left;
  margin-bottom: 20px;
}

.add-auction-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.add-auction-form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1rem !important;
}

.add-auction-form-group input,
.add-auction-form-group select,
.add-auction-form-group textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.add-auction-form-group input[type="file"] {
  padding: 3px;
}

.add-auction-form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.add-auction-form-row .add-auction-form-group {
  flex: 1;
  min-width: 280px;
  margin-right: 15px;
}

.add-auction-form-row .add-auction-form-group:last-child {
  margin-right: 0;
}

.add-auction-submit-button-container {
  display: flex;
  justify-content: center;
}

.add-auction-submit-button {
  width: 50%;
  padding: 15px;
  font-size: 16px;
  color: white;
  background: #4CAF50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 0 auto;
  margin-bottom: 10px;
}

.add-auction-submit-button:hover {
  background: #45a049;
}
.biider-permission-container{
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}
.permitted-email{
  padding: 10px;
  border: 1px solid #ccc;
  width: auto;
  border-radius: 5px;
}
.permittted-email:hover{
  border: 2px solid #ccc;
}
/* Responsive design */
.email-removal-icon{
  margin-right: 5px;
  cursor: pointer;
}
.bidder-email-list{
    list-style: none !important;
    padding-left: 0px !important;
}
.bidder-email-list li{
  padding: 10px;
  cursor: pointer;
}
.bidder-email-list li:hover{
  box-shadow: 0 0 2px 4px rgba(161, 172, 184, 0.15);
}
@media (max-width: 850px) {
  .add-auction-form-row {
    flex-direction: column;
  }

  .add-auction-form-row .add-auction-form-group {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .add-auction-submit-button {
    width: 100%;
  }
}