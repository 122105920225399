/* PortalProfile.css */

.portal-profile-container {
    width: 100%;
    margin: 0 auto;
}

.profile-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.profile-header-container a {
    font-size: 12px;
}

.portal-profile-header {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: 500;
}

.buyer-agent-container {
    display: flex;
    gap: 40px;
}

.portal-profile-form {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
}

.portal-profile-row {
    margin-bottom: 15px;
    align-items: center;
    flex: 1;
    gap: 10%;
}

.portal-profile-label-container {
    position: relative;
    width: 94%;
    color: #00263e;
    display: block;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: .5rem;
}

.portal-profile-row label {
    font-size: 14px;
}

.portal-profile-row input[type="text"],
.portal-profile-row input[type="email"],
.portal-profile-row select {
    width: 95%;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    color: rgb(0 38 68);
    border: 2px solid #d8d8d8;
    border-radius: 4px;
    box-sizing: border-box;
}

.portal-profile-change-email {
    color: #007bff;
    text-decoration: underline;
    font-size: 12px;
}

.user-profile-change-email-container {
    display: flex;
    flex-direction: column;
}

.portal-profile-row input[type="radio"] {
    margin-right: 5px;
}

.portal-profile-submit-btn {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    align-self: center;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding: .875rem 3rem;
}

.portal-profile-submit-btn:hover {
    background-color: #0056b3;
}

.portal-profile-download {
    margin-top: 20px;
    text-align: right;
}

.portal-profile-download a {
    color: #007bff;
    text-decoration: underline;
}

.portal-profile-download a:hover {
    color: #0056b3;
}

.profile-state-select {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    width: 95%;
}

.profile-state-select-input {
    font-size: 12px;
    cursor: pointer;
    border: 2px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 0px;
    color: rgb(0 38 68);
    background-color: white;
    width: 100%;
    text-align: left;
    padding-left: 10px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.profile-state-select-input::placeholder {
    color: rgb(0 38 68);
}

/* .profile-state-select-input:focus {
      border: 1px solid rgb(3, 132, 251);
      outline: none;
      border-bottom: none;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    } */

.profile-state-select-menu {
    position: absolute;
    left: 0;
    z-index: 1000;
    display: block;
    width: 99%;
    max-height: 136px;
    /* Adjust height as needed */
    overflow-y: auto;
    padding: 5px 0;
    margin: 2px 0 0;
    margin-top: 0px;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */
    color: rgb(0 0 0 / 95%);
    font-weight: 400;
    scrollbar-width: none;
}

.profile-state-select-menu span {
    color: rgb(3 132 251);
    font-size: 13px;
}

.profile-state-select-menu::-webkit-scrollbar {
    width: 8px;
    /* Adjust width as needed */
}

.profile-state-select-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.profile-state-select-menu::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.profile-state-select-menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.profile-state-select-item {
    padding: 10px;
    cursor: pointer;
}

.profile-state-select-item:hover {
    background-color: #f5f5f5;
}

/* .profile-state-select:hover .profile-state-select-input,
.profile-state-select:hover .dropdown-icon {
    color: white;
    cursor: pointer;
} */

/* .profile-state-select:hover .profile-state-select-input {
    background-color: #007bff;
} */

.profile-state-select-input.clicked {
    animation: clickAnimation 0.3s ease;
}

.portal-profile-label-container span {
    /* color: red; */
    position: absolute;
    right: 0;
    top: 0;
}

.user-profile-input-row {
    display: flex;
    width: 96%;
    gap: 4px;
}

.portal-profile-disclaimer {
    color: #333;
    font-size: 13px;
    margin-bottom: 0;
}

.portal-profile-disclaimer a {
    color: #00263e;
}

.portal-profile-get-support {
    font-size: 16px;
}
.portal-profile-get-support a{
    color: #333;
}
@keyframes clickAnimation {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(2px);
    }

    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width:938px) {
    .profile-state-select-item {
        padding-left: 10px;
    }
}

@media screen and (max-width:794px) {

    .portal-profile-row input[type="text"],
    .portal-profile-row input[type="email"],
    .portal-profile-row select {
        width: 96%;
    }

    .profile-state-select {
        width: 96%;
    }
}

@media screen and (max-width:450px) {
    .portal-profile-header {
        font-size: 18px;
    }

    .profile-header-container {
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 20px;
    }
}

@media screen and (max-width:445px) {

    .portal-profile-row input[type="text"],
    .portal-profile-row input[type="email"],
    .portal-profile-row select {
        width: 97%;
    }
    .portal-profile-form {
          padding-left: 0px;
          padding-right: 0px;
    }
    .profile-state-select {
        width: 98%;
        margin-right: 0px;
    }
}