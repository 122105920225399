/* .signup-container {
   
} */

.signup-signup-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    margin-top: 30px;
}

.signup-form-container {
    width: 35%;
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 25px;
    padding-left: 110px;
    padding-right: 105px;
}

.signup-logo {
    color: #d63447;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
}

.welcome-to-vihara-auth {
    font-size: 24px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
    color: #00263e;
    padding-bottom: 20px;
    text-align: left;
    width: 100%;
}

.regsitration-details-sign-up {
    width: 100%;
    text-align: left;
    line-height: 22px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 400;
}

.signup-logo img {
    width: 100px;

    /* margin-bottom: 100px; */
    margin-top: 20px;
}

.signup-title {
    margin-bottom: 20px;
    font-weight: 400;
    margin-top: 0;
}

.signup-form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.signup-input {
    padding: 10px;
    /* margin-bottom: 20px; */
    border: 2px solid #d8d8d8;
    border-radius: 4px;
    flex: 1;
}

.password-container {
    padding: 10px;
    border: 2px solid #d8d8d8;
    border-radius: 4px;
    display: flex;
    flex-direction: row !important;
}

.password-container input {
    border: none;
    outline: none;
    background-color: inherit;
    flex: 1;
}

.password-container input:focus {
    border: none;
    outline: none;
}

.input-error {
    padding: 10px;
    margin-bottom: 4px;
    border: 2px solid #d8d8d8;
    border-radius: 4px;
    flex: 1;
    border: 1px solid red !important;
    background-color: #ffe6e6;
    /* light red background */
    color: red;
}

input.input-error::-webkit-input-placeholder {
    color: red;
}

input.input-error::-moz-placeholder {
    color: red;
}

input.input-error:-ms-input-placeholder {
    color: red;
}

input.input-error::placeholder {
    color: red;
}

.input-error::placeholder {
    color: red;
}

.signup-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    margin-top: 10px;
}

/* .signup-button:hover {
    background-color: #007bff;
} */

.signup-links {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    width: 100%;
}

.signup-link {
    color: #050505;
    padding: 0 10px;
}

.signup-help-link {
    display: block;
    margin-top: 20px;
    font-size: 1rem;
    font-weight: 500;
    padding-left: 30px;
    color: #050505;
    text-align: left;
    margin-bottom: 40px;
}

.signup-image-container {
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../../../assets/images/discoverComponent/discoverComponent.svg') no-repeat center center;
    background-size: cover;
    border-left: 1px solid #E7EAEE;
}

.signup-image-container img {
    width: 80%;
}

.sign-up-input-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    flex: 1;
}

.sign-up-input-label span:nth-child(2) {
    color: red;
}

.sign-up-input-label span:nth-child(1) {
    color: rgb(0 38 62);
    font-size: 13px;
    font-weight: 700;
}

.sign-up-input-group {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 24px;
}

.sign-up-input-group div {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 60px;
    margin-bottom: 10px;
}

.password-icon {
    color: #70a9e79c;
    cursor: pointer;
}

.sign-up-terms-conditions {
    font-size: 15px;
    font-size: "Nitti Grotesk";
}

.sign-up-with-google-account-text {
    text-align: left;
    line-height: 22px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
}

.continue-with-google-sign-up {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #007bff;;
    border-radius: 7px;
    width: 100%;
    margin-top: 10px;
    justify-content: center;
    font-size: 14px;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 15px;
}

.continue-with-google-sign-up img {
    width: 25px;
    height: 25px;
}

@media screen and (max-width: 900px) {
    .signup-signup-container {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .signup-image-container {
        width: 90%;
        border: none;
    }

    .signup-form-container {
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 80%;
    }

    .signup-help-link {
        margin-top: 0;
        padding-left: 0;
        text-align: center;
    }
}

@media screen and (max-width:796px) {
    .sign-up-input-group {
        flex-direction: column;
        gap: 0px;
    }

    .signup-button {
        margin: 0 auto;
    }
}

@media screen and (max-width:550px) {
    .signup-button {
        width: 100%;
    }

    .sign-up-with-google-account-text {
        width: 100%;
    }

    .continue-with-google-sign-up {
        width: 100%;
    }

    .signup-image-container {
        display: none;
    }
}