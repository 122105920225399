/* PasswordResetConfirmation.css */
.password-reset-confirmation-container {
    max-width: 450px;
    margin: 40px auto;
    padding: 20px;
    text-align: center;
    /* font-family: Arial, sans-serif; */
}

.password-reset-confirmation-container .logo {
    /* width: 80px; */
    height: 30px;
    margin-bottom: 0px;
}

.password-reset-confirmation-container h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #343A41;
    text-align: center;
    font-weight: 500;
}

.conformation-para-part p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.5;
    text-align: left;

}

.conformation-para-part a {
    color: #007bff;
    text-decoration: none;
    text-align: left;
}

.conformation-para-part a:hover {
    /* text-decoration: underline; */
    color: #007bff;
}

.conformation-para-part .return-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #007bff;
     background-color: #fff;
    border: 1px solid #007bff;
    border-radius: 8px;
    text-decoration: none;
    width: fit-content;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.conformation-para-part .return-button:hover {
    color: #fff;
    background-color:#007bff ;
}

.conformation-para-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: baseline; */
}