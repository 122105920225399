.leadership-members-container {
    text-align: center;
    margin: 20px;
    margin-bottom: 200px;
}

.leadership-members-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;
}

.leadership-members-tabs button {
    background: none;
    border: none;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s;
    text-transform: uppercase;
    color: rgb(134 152 163);
}

.leadership-members-tabs button.active {
    border-bottom: 2px solid #007bff;
    color: #00263e;
}

.leadership-members-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.leadership-members-member {
    max-width: 200px;
    margin: 10px;
    min-width: 200px;
}

.leadership-members-image {
    width: 75%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    cursor: pointer;
}

.leadership-members-name {
    font-size: 1.125rem;
    font-weight: 500;
    color: rgb(0 38 62);
    margin: 10px 0 5px;
}

.leadership-members-title {
    color: #747476;
    font-size: 15px;
    font-weight: 400;
}

.leadership-members-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 70%;
    background-color: white;
    padding: 80px;
    border-radius: 8px;
    outline: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-align: center;
    overflow-y: auto;
    padding-top: 0px;
    padding-right: 0px;
}

.leadership-members-modal-image {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.leadership-members-modal-name {
    font-size: 36px;
    font-weight: 500;
    margin: 10px 0;
}

.leadership-members-modal-title {
    color: #747476;
    font-size: 15px;
    margin-bottom: 20px;
    text-align: left;
}

.leadership-members-modal-description {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 26px;
    text-align: left;
    padding-right: 70px;
}

.modal-image-heading-leadership {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    padding-left: 40px;
}

.membership-modal-close-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

@media screen and (max-width:1000px) {
    .leadership-members-modal-name {
        font-size: 30px;
    }
}

@media screen and (max-width:768px) {
    .modal-image-heading-leadership {
        flex-direction: column;
        padding-left: 0px;
    }

    .leadership-members-modal {
        padding-left: 0px;
    }

    .modal-image-heading-leadership {
        gap: 10px;
    }

    .leadership-members-modal-title {
        text-align: center;
    }

    .leadership-members-modal-description {
        padding-left: 50px;
        padding-right: 50px;
    }

    .leadership-members-modal-image {
        width: 150px;
        height: 150px;
    }
}

@media screen and (max-width:500px) {
    .leadership-members-member {
        max-width: 150px;
        min-width: 150px;
    }

    .leadership-members-modal-description {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width:450px) {
    .leadership-members-modal-name {
        font-size: 24px;
    }
}

@media screen and (max-width:382px) {
    .leadership-members-member {
        max-width: 130px;
        min-width: 130px;
    }
}