.event-calender-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .event-calender-dropdown-input {
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px; /* Adjust width as needed */
    padding: 16px;
    padding-top: 11px;
    padding-bottom: 11px;
    color: rgb(191 191 191 / 87%);
  }
  
  /* .event-calender-dropdown-input:focus {
    border: 1px solid rgb(3, 132, 251);
    outline: none;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  } */
  
  .event-calender-dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    width: 182px;
    max-height: 204px; /* Adjust height as needed */
    overflow-y: auto;
    padding: 5px 0;
    margin: 2px 0 0;
    margin-top: 0px;
    font-size: 16px;
    background-color: #fff;
    border: 1px solid rgb(3, 132, 251);
    border-radius: 4px;
    /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 0px;
    color: rgb(0 0 0 / 95%);
    font-weight: 400;
  }
  
  .event-calender-dropdown-menu::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
  }
  
  .event-calender-dropdown-menu::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 4px;
  }
  
  .event-calender-dropdown-menu::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px;
  }
  
  .event-calender-dropdown-menu::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  
  .event-calender-dropdown-item {
    padding: 16px;
    cursor: pointer;
  }
  
  .event-calender-dropdown-item:hover {
    background-color: #5897fb;
    color: white;
  }
  
  .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the right position as needed */
    transform: translateY(-50%);
    cursor: pointer;
  }
  .event-calender-dropdown input::placeholder{
     font-weight: 500;
  }