.home-page-blog-card {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    width: 100%;
    margin: 20px auto;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.home-page-blog-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    padding-left: 100px;
    padding-top: 50px;
}

.blog-image-contaioner {
    width: 50%;
}

.blog-image-contaioner img {
    width: 100%;
    height: auto;
}

.home-page-blog-title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #00263e;
    max-width: 400px;
}

.subtitle-blog-header {
    font-size: 13px;
    font-weight: 700;
    color: rgb(3 132 251);
    letter-spacing: 1px;
}

.home-page-blog-date {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.home-page-blog-author {
    display: flex;
    align-items: center;
}

.home-page-author-image {
    width: 50px;
    height: auto;
    border-radius: 50%;
    margin-right: 10px;
    filter: grayscale(100%);
}

.home-page-blog-author p {
    margin: 0;
}

.my-icon-social-media {
    font-size: 30px;
    color: rgb(3 132 251);
    ;
}

.social-media-subscribe-outer-container {
    width: 81%;
    margin: 0 auto;
}

.social-media-subscribe-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(240 246 254);
    border-top: 2px solid rgb(191 201 207);
    padding-bottom: 5px;
}

.home-page-blog-social {
    display: flex;
    justify-content: flex-start;
    padding-left: 60px;
    gap: 10px;
    width: 50%;
}

.home-page-blog-social a {
    margin-right: 10px;
}

.home-page-blog-image-section {
    width: 50%;
    text-align: center;
    padding: 0px;
    display: flex;
    justify-content: center;
}

.home-page-blog-image-section p {
    color: rgb(3 132 251);
    font-size: 18px;
    font-weight: 400;
    margin-right: 20px;
}

.home-page-profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.home-page-blog-subscribe {
    margin-top: 10px;
}

.home-page-blog-subscribe p {
    margin: 0;
    margin-bottom: 10px;
}

.home-page-blog-subscribe button {
    border: none;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid rgb(3 132 251);
    color: #00263e;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 150px;
    text-align: center;
    text-transform: uppercase;
}

.home-page-blog-subscribe button:hover {
    background-color: #0056b3;
    color: white;
}

.danger-html {
    width: 50%;
    margin: 0 auto;
}

.danger-html li {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 26px;
    margin-top: 0;
    padding-left: 0px;
}

.danger-html li {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 26px;
}

.danger-html ul {
    padding-left: 0px;
}

.danger-html h2 {
    color: #00263e;
    font-size: 36px;
    font-weight: 500;
    line-height: 48px;
}

@media screen and (max-width:1000px) {
    .home-page-blog-title {
        font-size: 30px;
    }

    .home-page-blog-content {
        padding-left: 50px;
    }

    .home-page-blog-card {
        justify-content: space-between;
    }

    .danger-html h2 {
        font-size: 30px;
    }
}

@media screen and (max-width:950px) {
    .home-page-blog-card {
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .blog-image-contaioner {
        width: 100%;
    }

    .home-page-blog-content {
        width: auto;
    }

    .home-page-blog-title {
        max-width: 100%;
    }

    .home-page-blog-content {
        padding-left: 20px;
        padding-top: 20px;
    }

    .social-media-subscribe-container {
        flex-direction: column;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .home-page-blog-image-section {
        flex-direction: column;
    }

    .home-page-blog-social {
        justify-content: center;
        padding-left: 0px;
    }

    .home-page-blog-subscribe {
        margin-top: 0px;
    }

    .home-page-blog-subscribe button {
        width: 90%;
    }

    .home-page-blog-image-section {
        width: 100%;
    }
}
@media screen and (max-width:720px){
    .danger-html {
        width: 70%;
    }
}
@media screen and (max-width:450px){
    .home-page-blog-title{
        font-size: 24px;
    }
    .danger-html {
        width: 80%;
    }
    .danger-html ul {
        padding-left: 10px;
    }
    .danger-html ol {
        padding-left: 10px;
    }
    .danger-html h2{
        font-size: 24px;
    }
}