@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;500;700&display=swap');

/* font-family roboto */


.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}
@media (hover: none) {
  body {
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
.logo-small {
  width: 140px;
}

p {
  color: #333;
  /* font-size: 16px; */
  font-weight: 400;
  line-height: 26px;
}

h1,
h2,
h3,
h4,
h5 {
  color: #00263e;
}

ul {
  color: #333;
}

h1,
h2 {
  font-size: 36px;
}

input::placeholder {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

@media screen and (max-width:1000px) {

  h1,
  h2 {
    font-size: 30px;
  }
}

@media screen and (max-width:500px) {

  h2,
  h1 {
    font-size: 24px;
  }
}

/* * {
  -webkit-tap-highlight-color: transparent !important;
} */