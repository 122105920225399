.contact-us-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  overflow-y: auto;
  text-align: left;
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.contact-us-modal-content {
  background-color: #fefefe;
  padding: 2rem;
  /* Increased padding for better spacing */
  border: 1px solid #888;
  width: 63%;
  max-height: 650px;
  /* Default max width */
  height: fit-content;
  position: relative;
  /* border-radius: 10px; */
  box-sizing: border-box;
  margin: auto;
  padding: 0 10%;
  scroll-behavior: initial;
  padding-bottom: 20px;
  overflow-y: auto;
  scrollbar-width: none;
  border-radius: 5px;
}

.contact-us-modal-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.contact-us-modal-close svg {
  width: 2rem;
  height: 2rem;
}

.contact-us-modal h3 {
  text-align: center;
  margin-bottom: 1rem;
  /* font-size: 1.625rem; */
  font-weight: 500;
  /* color: RGB(0, 38, 62); */
  color: #00263e;
  font-size: 24px;
  font-weight: 500;
}

.contact-us-get-in-touch {
  text-align: center;
  padding-bottom: .5rem;
  /* color: RGB(51, 51, 51); */
  color: #333;
  font-size: 16px;
  font-weight: 400;
}

.contact-us-modal .contact-us-get-support {
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.contact-us-modal form {
  display: flex;
  flex-direction: column;
}

.contact-us-modal-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
}

.contact-us-modal-col {
  flex: 1;
  margin: 0.5rem;
  position: relative;
}

.contact-us-modal-col label {
  display: block;
  margin-bottom: 0.5rem;
  color: rgb(0 38 62);
  /* font-size: 0.875rem;
  font-weight: 500; */
  font-size: 13px;
  font-weight: 700;
}

.contact-us-modal-required {
  color: red;
  position: absolute;
  right: 0;
  top: 0;
}

/* input[type="text"], */
/* input[type="email"],
input[type="tel"],
select,
textarea {
  width: 100%;
  padding: 0.625rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  border: 2px solid #d8d8d8;
  border-radius: 4px;
}

textarea {
  resize: vertical;
} */

.contact-us-button-submit[type="submit"] {
  padding: 0.875rem 3rem;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  /* margin-top: 1rem; */
  align-self: center;
}

.contact-us-modal-disclaimer {
  font-size: 13px;
  color: RGB(51, 51, 51);
  margin-top: .5rem;
  margin-bottom: .0rem;
}

.contact-us-modal-disclaimer a {
  color: RGB(0, 38, 62);
  text-decoration: underline;
}

.contact-us-modal-disclaimer a:hover {
  text-decoration: underline;
}

.contact-us-get-support a {
  color: RGB(51, 51, 51);
}

.contact-us-get-support a:hover {
  color: #007bff;
}

@media (min-width: 769px) {
  .contact-us-modal-content {
    max-width: 800px;
    /* Larger max width for medium screens */
  }
}

@media (min-width: 1200px) {
  .contact-us-modal-content {
    max-width: 1000px;
    /* Largest max width for large screens */
  }
}

@media (max-width: 768px) {
  .contact-us-modal-content {
    padding: .5rem;
    width: 95%;
    /* Reduced padding for smaller screens */
  }

  .contact-us-modal-close {
    font-size: 1.5rem;
  }

  .contact-us-modal h2 {
    font-size: 1.5rem;
  }

  .contact-us-modal label {
    font-size: 0.75rem;
  }

  .contact-us-modal-content input[type="text"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    padding: 0.5rem;
  }

  .contact-us-button-submit[type="submit"] {
    padding: 0.75rem 2rem;
  }

  .contact-us-modal-disclaimer {
    font-size: 0.625rem;
  }

  .contact-us-modal {
    padding: 0rem;
  }
}


.contactus-modal-input {
  width: 100%;
  padding: 0.625rem;
  box-sizing: border-box;
  /* margin-bottom: 0.5rem; */
  border: 2px solid #d8d8d8;
  border-radius: 4px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

textarea.contactus-modal-input {
  resize: vertical;
}

/* Other existing styles */
.trisha-email-link {
  text-decoration: none !important;
}