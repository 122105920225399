.news-boxes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(0deg, #f0f6fe 70%, #fff 0);
    padding: 50px 0;
    position: relative;
    height: 90vh;
}

.news-boxes-content {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 50px;
    gap: 0px;
}

.news-boxes-box {
    background: white;
    padding: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 200px;
    margin: 0 10px;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    cursor: pointer;
}

.news-boxes-box:hover {
    transform: scale(1.13);
}

.news-boxes-icon img {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
}

.news-boxes-box h3 {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 12px;
    margin-top: 0;
    text-transform: uppercase
}

.news-boxes-box p {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 26px;
    margin-top: 0;
}

.news-boxes-footer {
    text-align: center;
    padding: 20px;
    width: 70%;
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 100px;
}

.news-boxes-footer p {
    color: #00263e;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 12px;
    margin-top: 0;
    text-align: left;
}

.news-boxes-footer a {
    color: #0066cc;
    font-size: 16px;
}

.news-boxes-social-media {
    text-align: left;
    margin-top: 20px;
}

.news-boxes-social-media a {
    color: #555;
    text-decoration: none;
    font-size: 16px;
}

.news-boxes-social-media a:hover {
    color: #000;
}

.news-bottom-icon {
    display: flex;
    gap: 30px;
}

.bottom-follow-vihara {
    padding-top: 20px;
}

@media screen and (max-width:1000px) {
    .news-boxes-footer p {
        font-size: 22px;
    }
}

@media screen and (max-width:954px) {
    .news-boxes-footer {
        width: 90%;
    }
}

@media screen and (max-width:768px) {
    .news-boxes-content {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .news-boxes-container {
        background: linear-gradient(0deg, #f0f6fe 85%, #fff 0);
        height: auto;
    }

    .news-boxes-box {
        width: 375px;
        padding: 0px;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .news-boxes-footer {
        flex-direction: column;
        gap: 30px;
        width: auto;
        margin: 0 auto;
        align-items: flex-start;
    }

    .news-update-view-all {
        display: none;
    }
}

@media screen and (max-width:400px) {
    .news-boxes-box {
        width: 96%;
    }
}