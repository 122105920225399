/* LoadingSpinner.css */
.loading-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .loading-spinner {
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .spinner-small {
    width: 24px;
    height: 24px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid #0384fb;
  }
  
  .spinner-medium {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #0384fb;
  }
  
  .spinner-large {
    width: 80px;
    height: 80px;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top: 6px solid #0384fb;
  }
  
  .loading-message {
    margin-top: 16px;
    font-size: 16px;
    color: #4b5563;
    text-align: center;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Dark mode support */
  @media (prefers-color-scheme: dark) {
    .spinner-small,
    .spinner-medium,
    .spinner-large {
      border-color: rgba(255, 255, 255, 0.1);
      border-top-color: #0384fb;
    }
    
    .loading-message {
      color: #9ca3af;
    }
  }