.new-page-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 50px;
}

.news-page-title {
    color: #00263e;
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    text-align: left;
    margin-bottom: 100px;
    margin-top: 100px;
}

@media screen and (max-width:1000px) {
    .news-page-title {
        font-size: 40px;
    }
}

@media screen and (max-width:450px) {
    .news-page-title {
        font-size: 30px;
    }
}

@media screen and (max-width:400px) {
    .new-page-container {
        width: 86%;
    }
}