.save-properties-whole-container{
    margin-bottom: 100px;
}
.saved-assets-container{
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 100px;
    margin: 0 auto;
    padding-left: 60px;
    min-height: 100vh;
}
.auction-bid-container {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: auto;
    font-family: Arial, sans-serif;
}
.saved-updates-outer-container{
    display: flex;
    width: 100%;
    border: 1px solid #ddd;
    border-left:5px solid #bd4001 ;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 9px 3px 17px -3px rgba(148,133,148,1);
    color: #949ba0;
    background-color: #f8f9fa;
    min-height: 470px;
}
.auction-details, .bid-section {
    width: 65%;
    padding: 10px;
}
.auction-details{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.auction-date-header{
    color: #121213;
}
.bid-section{
   width: 35%;
   background-color: white;
   border-top-right-radius: 8px;
   border-bottom-right-radius: 8px;
}
.auction-duration {
    color: #666;
    margin-bottom: 10px;
}

.outbid-notice {
    color: #c00;
    margin-bottom: 10px;
}

.outbid-text {
    font-weight: bold;
    font-size: 1.5rem;
}

.bid-again {
    margin-top: -10px;
    color: #677178;
    padding-left: 25px;
}

.bid-info, .purchase-info {
    margin-bottom: 20px;
}
.bid-info{
    display: flex;
    gap: 35px;
    padding-left: 25px;
}
.info-item {
    margin-bottom: 5px;
}
.purchase-details{
    display: flex;
    justify-content: space-between;
}
#total-purchase-price{
    font-weight: bold;
    color: #677178;
    font-weight: bold;;
}
.auction-buyers-premium{
    border-bottom:  2px solid #e8e9ea;;;
}
#earnest-money-deposit{
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 15px;
}
#earnest-money-deposit p:nth-child(2){
    font-size: 15px;
}
/* .label {
    font-weight: bold;
} */

.value {
    color: #677178;
    font-weight: bold;
}
#not-met{
    color: #c00;
}
.additional-links{
    display: flex;
    justify-content: space-between;
}
.faq-link{
    padding-left: 25px;
    border-top: 2px solid #e8e9ea;
    padding-top: 10px;
}
.faq-link, .additional-links .link {
    color: #6e9ecd;
    text-decoration: none;
}
.link{
    cursor: pointer;
}
.faq-link:hover {
    text-decoration: underline;
}

.bidding-ends {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
}

.time {
    font-size: 1.2em;
    font-weight: bold;
    background-color: #5c676e;
    color: white;
    padding: 10px;
    border-radius: 5px;
}

.next-bid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

#next-bid-input {
    width: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: right;
    color: #7b848a;
    font-weight: 600;
}

.place-bid-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #286eb5;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    margin-bottom: 20px;
}

.place-bid-button:hover {
    background-color: #0056b3;
}
.nothing-to-show-image{
    display: flex;
    justify-content: center;
    align-items: center;
}