.contact-us {
    width: 100%;
    position: relative;
    overflow: hidden;
}
.contact-us .contact-us-image-container {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    /* Ensures nothing goes outside the container */
}

.contact-us .contact-us-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.contact-us .contact-us-image-container .overlay {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    /* Dark overlay */
    pointer-events: none;
    /* Allows click events to pass through */
    z-index: 1;
    /* Ensure overlay is above the image */
}

.contact-us .contact-us-inner-content {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
}

.contact-us-inner-content h1 {
    font-size: 48px;
    font-weight: 500;
    line-height: 10px;

}

.context-text-white-color{
    color:white;
}

.contact-us-inner-content .context-text-para{
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.contact-us-inner-content button {
    background-color: white;
    color: #0384fb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 150px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
}

.contact-us .contact-button:hover {
    background-color: #0384fb;
    color: white;
}
@media screen and (max-width:500px){
    .contact-us-inner-content h1 {
        font-size: 30px;
        font-weight: 500;
    
    }
}
