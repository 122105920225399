 .otp-verification-container {
     display: flex;
     width: 100%;
     min-height: 100vh;
     margin-top: 50px;
 }

 .otp-verification-form-container {
     width: 50%;
     padding: 20px;
     text-align: center;
     display: flex;
     flex-direction: column;
     align-items: center;
 }

 .phone-number-verification {
     font-size: 24px;
     font-weight: 500;
     line-height: 16px;
     letter-spacing: 0.4px;
     text-align: left;
     color: #00263e;
     padding-bottom: 20px;
     text-align: left;
     width: 65%;
     margin-bottom: 20px;
     line-height: 26px;
 }
.otp-mobile-number{
    width: 65%;
    font-size: 15px;
    text-align: left;
    line-height: 22px;
    margin-bottom: 20px;
    font-weight: normal;
    color: #118bf4;
}

 .otp-notification {
     width:65%;
     font-size: 15px;
     text-align: left;
     line-height: 22px;
     margin-bottom: 20px;
     font-weight: 400;
 }

 .gray-line-sign-page {
     width: 53%;
     height: 1px;
     background-color: #eee;
     margin-bottom: 10px;
     margin-top: 10px;
 }
 .otp-verification-image-container {
    width: 50%;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* background: url('../../../assets/images/discoverComponent/discoverComponent.svg') no-repeat center center;
    background-size: cover; */
    border-left: 1px solid #E7EAEE;
}
.otp-verification-image-container img{
    width: 70%;
    height: 70%;
}
