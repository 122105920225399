.image-carousel-carousel-container {
  position: relative;
  width: 100%;
  margin: auto;
}

.carousel-content {
  height: 100%;
}

.slick-track {
  height: 100%;
}

.slick-list {
  height: 100%;
}

.slick-slider {
  height: 100%;
}

.image-carousel-carousel-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* Adjust the height as needed */
  cursor: pointer;
  border-radius: 10px;
}

.image-carousel-carousel-image {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.image-carousel-exit-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 0, 0, 0.8);
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1;
  color: white;
}

.image-carousel-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.image-carousel-close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.image-carousel-modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.image-carousel-prev,
.image-carousel-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  user-select: none;
  z-index: 1001;
}

.image-carousel-prev {
  left: 10px;
}

.image-carousel-next {
  right: 10px;
}

.slick-slide img {
  display: inline;
}

.image-carousel-map-container {
  width: 90%;
  height: 300px;
  margin-top: 20px;
}

.image-carousel-location-button,
.image-carousel-street-view-button,
.image-carousel-photos-button {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.image-carousel-location-button:hover,
.image-carousel-street-view-button:hover,
.image-carousel-photos-button:hover {
  background-color: #f0f0f0;
}

.image-carousel-button-container {
  display: flex;
  flex-direction: initial;
  gap: 10px;
  /* Adjust the gap as needed */
}

.image-carousel-button-overlay {
  /* position: absolute; */
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 60px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 10px;
}

.image-carousel-photos-view {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-carousel-photo-image {
  margin: 5px;
  width: calc(20% - 10px);
  height: auto;
}

.image-carousel-bottom {
  width: 100%;
}

.image-carousel-fancy-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.image-carousel-fancy-button:hover {
  background-color: #0056b3;
}

.property-detail-carousel {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.property-detail-carousel-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 400px;
  /* Smooth slide transition */
}

.property-detail-carousel-image {
  width: 100%;
  flex-shrink: 0;
  /* Prevents images from shrinking */
}

.property-detail-carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  color: white;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
  font-size: 35px;
}

.property-detail-prev {
  left: 10px;
}

.property-detail-next {
  right: 10px;
}

.property-detail-carousel-caption {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
}

.property-detail-carousel-options {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
}

.property-detail-carousel-option {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.property-detail-carousel-option:hover {
  background-color: #f0f0f0;
}
.property-detail-icon {
  color: rgb(17, 139, 244);
  margin-right: 5px;
}
@media screen and (max-width: 470px) {
  .property-detail-carousel-options {
    display: none;
  }
  .property-detail-carousel-caption {
    display: none;
}
}
@media screen and (max-width: 450px) {
  .property-detail-carousel-content {
    height: 320px;
  }
}
