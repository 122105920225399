/* BidHistoryModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 10px;
    color: #5e6364;
    font-size:1rem;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.modal-body {
    font-family: Arial, sans-serif;
}

.address {
    color: #888;
    margin-bottom: 10px;
}

.bid-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.bid-list li {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    padding: 5px 0;
    padding-left: 10px;
}

.bid-rank {
    color: #666;
}

.bid-amount {
    font-weight: bold;
    color: #888;
}

.bid-you {
    color: #888;
}

.your-bid {
    border-radius: 4px;
    padding: 5px;
    padding-left: 10px;
}
.your-bid:hover{
    background-color: #e7e9eb;
}
