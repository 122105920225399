/* General Styles */
.landing-page-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navbar Styles */
.landing-page-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #004080;
  color: white;
  padding: 1rem 2rem;
}

.landing-page-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.landing-page-nav-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

.landing-page-nav-links li {
  cursor: pointer;
}

/* Hero Section Styles */
.landing-page-hero {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  background: linear-gradient(to right, #004080, #0080ff);
  color: white;
  padding-left: 8rem;
  padding-right: 8rem;
}

.landing-page-hero-content {
  max-width: 50%;
  margin-top: 100px;
}

.landing-page-hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
  letter-spacing: 1px;
}

.landing-page-hero-content p {
  margin-bottom: 1rem;
  line-height: 1.5;
  color: white;
}

/* Form Styles */
.landing-page-form {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #333;
  margin-top: 100px;
}

.landing-page-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.landing-page-form label {
  font-weight: bold;
}

.landing-page-form input[type="text"],
.landing-page-form input[type="email"] {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.landing-page-radio-group {
  display: flex;
  gap: 1rem;
}

.landing-page-radio-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.landing-page-form button {
  background-color: #004080;
  color: white;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.landing-page-form button:hover {
  background-color: #003366;
}
.landing-page.landing-page--meeting {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f5f5f5;
}

.landing-page__video-container {
  flex: 1;
  max-width: 40%;
  height: 350px;
  margin-right: 2rem;
}

.landing-page__content {
  flex: 1;
  max-width: 400px;
}

.landing-page__title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.landing-page__description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.landing-page__schedule-meeting-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 10px;
}

.landing-page__schedule-meeting-button:hover {
  background-color: #0056b3;
}
.mortgage-servicing-container {
  background: linear-gradient(to right, #004080, #0080ff);
  color: white;
  padding: 100px 20px;
  display: flex;
  justify-content: space-around;
}
.mortgage-servicing-header {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mortgage-servicing-header h2 {
  font-size: 34px;
  color: white;
}

.mortgage-servicing-header p {
  font-size: 18px;
  color: white;
}

.mortgage-servicing-wheel {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 40%;
}
.mortgage-servicing-wheel img {
  width: 100%;
  border-radius: 10%;
}
.client-feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem;
  background-color: #f9fafb;
  padding-top: 120px;
  padding-bottom: 120px;
}

.client-feedback-title {
  font-size: 36px;
  font-weight: 400;
  color: #2563eb;
  margin-bottom: 2rem;
}

.client-feedback-card {
  background: linear-gradient(to right, #3b82f6, #2563eb);
  color: white;
  border-radius: 1rem;
  padding: 1.5rem;
  max-width: 40rem;
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.client-feedback-quote {
  font-style: italic;
  margin-bottom: 1rem;
  font-size: 22px;
  color: white;
  font-weight: 300;
}

.client-feedback-author {
  font-weight: bold;
  font-size: 1rem;
  font-weight: 400;
  color: white;
}
.landing-page-radio-group input{
   margin-top: -2px;
}
@media screen and (max-width: 1050px) {
  .landing-page-hero {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@media screen and (max-width: 900px) {
  .landing-page-hero {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .landing-page-hero {
    flex-direction: column;
  }
  .landing-page-hero-content {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 100px;
  }
  .landing-page-form {
    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
  }
}
@media screen and (max-width: 600px) {
  .landing-page-hero {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .landing-page-hero-content h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 450px) {
  .landing-page-hero {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .landing-page-form {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
  }
}
