.termsOfUses-text-box{
    /* width: 100%; */
    height: fit-content;
    padding: 40px;
    text-align: center;
    background:#DFE8F1 ;
    font-style: italic;
}

.alphabet-list{
    list-style-type: upper-alpha;
}