/* HeroBanner.css */
.common-hero-section {
  position: relative;
  width: 100%;
  height: 500px;
  /* Adjust height as needed */
  color: white;
  /* Text color */
  text-align: left;
  /* Align text to the left */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f6fe;
  overflow: hidden;
  /* padding-top: 50px; */
  margin-bottom: 50px;
  
}

.common-hero-background-video {
  height: 500px;
  width: 50%;
  border-top-left-radius: 480px;
  border-bottom-left-radius: 480px;
  object-fit: cover;
  /* padding-right: 200px; */
}

.common-hero-overlay-content {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  /* padding-top: 50px; */
  padding: 100px 100px;
}

.common-hero-overlay-content h1 {
  font-size: 40px;
  /* Adjust based on your design */
  margin-bottom: 0.5rem;
  color: #00263e;
  font-weight: 450;
  line-height: 60px;
}

.common-hero-overlay-content p {
  font-size: 16px;
  line-height: 26x;
  /* Adjust based on your design */
  margin-bottom: 1rem;
  color: #333;
  font-size: 18px;
  color: #666;
}

.common-hero-watch-button {
  padding: 14px 20px;
  background-color: rgb(3 132 251);
  /* Adjust background color as needed */
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  width: 250px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  
}

.common-hero-watch-button:hover {
  background-color: #096dd9;
  /* Darker shade for hover effect */
}
@media screen and (max-width:1180px){
  .common-hero-section {
    align-items: flex-start;
  }
  .common-hero-background-video {
    width: 500px;
  }
  .common-hero-overlay-content {
    padding-top: 40px;
    padding-left: 50px;
  }
}
@media screen and (max-width:1000px){
  .common-hero-overlay-content h1 {
     font-size: 30px;
  } 
}
@media screen and (max-width:930px){
  .common-hero-overlay-content {
    width: 45%;
  }
  .common-hero-overlay-content {
    padding-top: 20px;
    padding-left: 20px;
  }
  .common-hero-background-video {
    width: 400px;
  }
}

@media screen and (max-width:790px){
      .common-hero-section{
        flex-direction: column-reverse;
        height: auto;
        padding-bottom: 20px;
        align-items: flex-start;
      }
      .common-hero-background-video {
        width: 100%;
        height: 400px;
        border-top-left-radius: 380px;
        border-bottom-left-radius: 380px;
      }
      .common-hero-overlay-content {
        width: 90%;
        padding-left: 10px;
      }
      .common-hero-overlay-content p {
        width: 80%;
        font-size: 1.2rem;
      }
      .common-hero-overlay-content h1 {
          font-size: 1.8rem;
      }
      .learn-more-graphic{
        display: none;
      }
      .common-hero-overlay-content h1 br{
        display: none;
      }
} 