
.login-signup-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    margin-top: 30px;
}
.login-form-container {
    width: 50%;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.welcome-to-vihara-auth-login-page{
     width: 65%;
     font-size: 24px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #00263e;
    padding-bottom: 20px;
    text-align: left;
}
.login-logo {
    color: #d63447;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    width: 65%;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
}

.login-logo img {
    width: 100px;
}

.login-title {
    margin-bottom: 20px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 1rem;
    color: #00263e;
    font-size: 24px;
    font-weight: 500;
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-input {
    width: 50%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-button {
    width: 65%;
    padding: 10px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: bold;
    margin-top: 20px;
}

/* .login-button:hover {
    background-color: #0056b3;
} */

.login-links {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    font-size: 15px;
    font-weight: 400;
}

.login-link {
    color: #050505;
    padding: 0 20px;
}

.login-help-link {
    position: absolute;
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 20px;
    color: #050505;
    left: 1%;
    bottom: 5%;
}

.login-image-container {
    background: url('../../../assets/images/discoverComponent/discoverComponent.svg');
    width: 40%;
    height: 90vh;
    background-size: cover;
    background-position: top;
    border-left: 1px solid #E7EAEE;

}


.sign-in-details-sign-in-page {
    width: 65%;
    font-size: 15px;
    text-align: left;
    line-height: 22px;
    margin-bottom: 20px;
    font-weight: 400;
}

.input-error {
    padding: 10px;
    margin-bottom: 20px;
    border: 2px solid #d8d8d8;
    border-radius: 4px;
    flex: 1;
    border: 1px solid red !important;
    background-color: #ffe6e6;
    /* light red background */
    color: red;
}
input.input-error::-webkit-input-placeholder {
    color: red;
}

input.input-error::-moz-placeholder {
    color: red;
}

input.input-error:-ms-input-placeholder {
    color: red;
}

input.input-error::placeholder {
    color: red;
}
.input-error::placeholder {
    color: red;
}
.gray-line-sign-page{
     width: 65%;
     height: 1px;
     background-color:#eee;
     margin-bottom: 10px;
     margin-top: 10px;
}
.email-error-text{
     font-size: 13px;
     color: #F25D49;
     margin-bottom: 10px;
     text-align: left;
     width: 65%;
     margin-top: 4px;
}
.authentication-faild-on-submit{
    background-color: #FCF0D7;
    border-radius: 8px;
    color: #48525B;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    padding: 10px;
    width: 65%;
    margin-bottom: 20px;
    font-weight: 400;
}
.login-page-input-container{
     width: 65%;
     margin-top: 0px;
}
.sign-in-with-google-account-text{
    width:65% ;
}
.continue-with-google-sign-in {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid rgb(3 132 251);
    border-radius: 7px;
    width: 65%;
    margin-top: 10px;
    justify-content: center;
    font-size: 14px;
    gap: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 15px;
}

.continue-with-google-sign-in img {
    width: 25px;
    height: 25px;
}
@media screen and (max-width:900px){
    .login-signup-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .login-form-container{
        width: 95%;
        padding: 0px;
    }
    .login-image-container {
        width: 90%;
        border: none;
    }
}
@media screen and (max-width:830px) {
    .login-input {
        width: 80%;
    }

    .login-form-container label {
        width: 83%;
    }
}

@media screen and (max-width:700px) {
    .login-form-container {
        width: 100%;
    }

    .login-input {
        width: 60%;
    }

    .login-form-container label {
        width: 63%;
    }
}
@media screen and (max-width:550px){
    .login-image-container {
        display: none;
    }
}
@media screen and (max-width:500px) {
    .continue-with-google-sign-in{
        width: 90% !important;
    }
    .sign-in-with-google-account-text{
        width:90% ;
    }
    .sign-in-with-google-account-text{
        width:90% ;
    }
    .welcome-to-vihara-auth-login-page {
       width: 90%;
    }
    .sign-in-details-sign-in-page{
        width: 90%;
    }
    .login-page-input-container{
           width: 90%;
    }
    .email-error-text{
        margin-top: 3px;
        width: 90%;
    }
    .login-button{
        width: 90%;
    }
    .gray-line-sign-page{
        width: 90%;
    }
    .login-input {
        width: 80%;
    }

    .login-form-container label {
        width: 83%;
    }
}