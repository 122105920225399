.policy-choice-request-form {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding-top: 40px;
}

.policy-choice-request-form label {
  margin: 0 auto;
  margin-bottom: .5rem;
  width: 98%;
  color: rgb(0 38 62);
  font-size: 13px;
  font-weight: 700;
  position: relative;
}

.policy-choice-request-form label span {
  color: red;
  position: absolute;
  top: 0;
  right: 0;
}

.policy-choice-request-form input[type="text"],
.policy-choice-request-form input[type="email"],
.policy-choice-request-form input[type="tel"],
.policy-choice-request-form select {
  width: 93%;
  padding: 8px;
  border: 2px solid #d8d8d8;
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.policy-choice-request-form input[type="text"]:focus,
.policy-choice-request-form input[type="email"]:focus,
.policy-choice-request-form input[type="tel"]:focus,
.policy-choice-request-form select:focus {
  border: 1px solid #007bff;
}

.policy-choice-request-form button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  width: 150px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0.875rem 3rem;
  border-radius: 5px;
  align-items: center;
}

.policy-choice-request-form button:hover {
  background-color: #0056b3;
}

.policy-choice-acknowledgement {
  width: 98%;
  margin: 0 auto;
  color: rgb(0 38 62);
  color: RGB(51, 51, 51);
  margin-top: .5rem;
  font-size: 16px;
}

.privacy-choice-input-row {
  display: flex;
  gap: 18px;
  width: 100%;
}

.privacy-choice-input-col {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.privacy-choice-get-support {
  text-align: center !important;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.privacy-choice-get-support a {
  color: RGB(51, 51, 51);
}

.privacy-choice-disclaimer {
  font-size: 13px;
  color: RGB(51, 51, 51);
  margin-top: .5rem !important;
  margin-bottom: 0 !important;
}
.privacy-choice-disclaimer a{
  color: RGB(51, 51, 51);
}