/* LiveAuctionRoom.css - Part 1: Base Styles */

:root {
  --primary-color: #0384fb;
  --primary-dark: #0369a1;
  --primary-light: #dbeafe;
  --secondary-color: #10b981;
  --secondary-dark: #059669;
  --secondary-light: #d1fae5;
  --warning-color: #f59e0b;
  --warning-dark: #d97706;
  --warning-light: #fff7ed;
  --error-color: #ef4444;
  --error-dark: #b91c1c;
  --error-light: #fee2e2;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
  --border-radius-sm: 4px;
  --border-radius: 8px;
  --border-radius-lg: 12px;
  --box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  --box-shadow-lg: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  --transition: all 0.2s ease;
}

.live-auction-room {
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 20px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--gray-900);
  position: relative;
}

/* Loading and error states */
.live-auction-loading,
.live-auction-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px auto;
  padding: 30px;
  max-width: 500px;
  background-color: var(--gray-50);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
}

.loader {
  border: 4px solid var(--gray-100);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-icon {
  width: 60px;
  height: 60px;
  background-color: var(--error-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.live-auction-error h2 {
  color: var(--error-dark);
  margin-bottom: 10px;
  font-size: 24px;
}

.live-auction-error p {
  color: var(--gray-600);
  margin-bottom: 25px;
  font-size: 16px;
}

.back-button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border-radius: var(--border-radius-sm);
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  gap: 8px;
}

.back-button:hover {
  background-color: var(--primary-dark);
}
/* LiveAuctionRoom.css - Part 2: Auction Header Styles */

/* Auction header */
.auction-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray-200);
}

.property-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.property-info h1 {
  font-size: 28px;
  font-weight: 700;
  color: var(--gray-900);
  margin: 0;
  line-height: 1.2;
}

.property-location {
  font-size: 16px;
  color: var(--gray-600);
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.auction-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.status-label {
  font-size: 14px;
  color: var(--gray-500);
  display: flex;
  align-items: center;
  gap: 6px;
}

.status-value {
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-900);
}

.status-value.time {
  font-family: 'Courier New', monospace;
  font-size: 22px;
  padding: 5px 10px;
  background-color: var(--gray-50);
  border-radius: var(--border-radius-sm);
}

.ended {
  color: var(--error-color);
}

.not-started {
  color: var(--secondary-color);
}

.final-minutes {
  animation: countdown-pulse 1s infinite;
  font-weight: 700;
}

@keyframes countdown-pulse {
  0% { color: var(--error-color); }
  50% { color: var(--error-light); }
  100% { color: var(--error-color); }
}

.extension-notice {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--warning-light);
  padding: 8px 12px;
  border-radius: var(--border-radius-sm);
  font-size: 14px;
  color: var(--warning-dark);
  font-weight: 500;
}

.pulse-dot {
  width: 8px;
  height: 8px;
  background-color: var(--warning-color);
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.end-time {
  margin-left: 5px;
  color: var(--gray-500);
  font-size: 12px;
}

@keyframes pulse {
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
}

/* Auction status labels */
.auction-status-label {
  display: inline-block;
  padding: 4px 8px;
  border-radius: var(--border-radius-sm);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 8px;
}

.status-active {
  background-color: var(--secondary-light);
  color: var(--secondary-dark);
}

.status-ended {
  background-color: var(--error-light);
  color: var(--error-dark);
}

.status-upcoming {
  background-color: var(--primary-light);
  color: var(--primary-dark);
}
/* LiveAuctionRoom.css - Part 3: Main Layout and Bidding Section */

/* Main auction content */
.auction-main {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 30px;
}

/* Bidding section */
.bidding-section {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 25px;
}

.current-bid {
  margin-bottom: 25px;
  text-align: center;
}

.current-bid h2 {
  font-size: 18px;
  color: var(--gray-600);
  margin: 0 0 10px 0;
  font-weight: 500;
}

.bid-amount {
  font-size: 36px;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 5px;
}

.bidder {
  font-size: 14px;
  color: var(--gray-500);
}

.bid-form {
  margin-bottom: 24px;
}

.input-group {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.input-group input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid var(--gray-300);
  border-radius: var(--border-radius-sm);
  font-size: 16px;
  transition: var(--transition);
}

.input-group input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(3, 132, 251, 0.2);
}

.bid-form button {
  padding: 12px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

.bid-form button:hover {
  background-color: var(--primary-dark);
}

.bid-form button:disabled {
  background-color: var(--gray-400);
  cursor: not-allowed;
}

.bid-form button.processing {
  background-color: var(--gray-400);
  cursor: wait;
}

.error-message {
  color: var(--error-color);
  font-size: 14px;
  margin-top: 6px;
}

/* Winning and losing indicators */
.winning-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--secondary-light);
  color: var(--secondary-dark);
  padding: 10px 15px;
  border-radius: var(--border-radius-sm);
  margin-top: 10px;
  font-weight: 500;
}

.winning-indicator-icon {
  width: 20px;
  height: 20px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.losing-indicator {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--error-light);
  color: var(--error-dark);
  padding: 10px 15px;
  border-radius: var(--border-radius-sm);
  margin-top: 10px;
  font-weight: 500;
}

/* Bid increments section */
.bid-increments {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid var(--gray-200);
}

.bid-increments h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--gray-900);
  margin: 0 0 10px 0;
}

.increment-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.increment-button {
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-radius: var(--border-radius-sm);
  padding: 8px 12px;
  font-size: 14px;
  color: var(--gray-900);
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  gap: 4px;
}

.increment-button:hover {
  background-color: var(--gray-200);
}

.increment-button.recommended {
  background-color: var(--primary-light);
  border-color: var(--primary-color);
  color: var(--primary-dark);
  font-weight: 500;
}

.increment-button.recommended:hover {
  background-color: rgba(219, 234, 254, 0.8);
}

.increment-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
/* LiveAuctionRoom.css - Part 4: Auto Bidding Styles */

/* Auto bidding section */
.auto-bidding {
  padding: 20px;
  background-color: var(--gray-50);
  border-radius: var(--border-radius);
  margin-bottom: 24px;
  border: 1px solid var(--gray-200);
}

.auto-bidding h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-900);
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.auto-bid-description {
  font-size: 14px;
  color: var(--gray-600);
  margin-bottom: 15px;
  line-height: 1.5;
}

.auto-bid-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.auto-bid-inputs {
  display: flex;
  gap: 10px;
}

.auto-bid-inputs input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid var(--gray-300);
  border-radius: var(--border-radius-sm);
  font-size: 16px;
  transition: var(--transition);
}

.auto-bid-inputs input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(3, 132, 251, 0.2);
}

.auto-bid-inputs input::placeholder {
  color: var(--gray-400);
}

.auto-bid-form button {
  padding: 12px 20px;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius-sm);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
}

.auto-bid-form button:hover {
  background-color: var(--secondary-dark);
}

.auto-bid-form button:disabled {
  background-color: var(--gray-400);
  cursor: not-allowed;
}

.auto-bid-form button.active {
  background-color: var(--secondary-dark);
  cursor: default;
}

.auto-bid-active {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
  padding: 10px;
  background-color: var(--secondary-light);
  border-radius: var(--border-radius-sm);
  color: var(--secondary-dark);
  font-size: 14px;
  font-weight: 500;
}

.active-icon {
  width: 10px;
  height: 10px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  animation: pulse 2s infinite;
}

/* Property details toggle button */
.property-details-toggle {
  width: 100%;
  padding: 12px;
  background-color: var(--gray-50);
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius-sm);
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-700);
  cursor: pointer;
  transition: var(--transition);
  margin-top: 8px;
}

.property-details-toggle:hover {
  background-color: var(--gray-100);
}

/* Auction result banner */
.auction-result-banner {
  background-color: white;
  border-radius: var(--border-radius);
  padding: 25px;
  margin-bottom: 30px;
  text-align: center;
  border: 1px solid var(--gray-200);
  box-shadow: var(--box-shadow);
}

.result-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--gray-900);
}

.winning-result {
  color: var(--secondary-dark);
}

.result-message {
  font-size:
  16px;
  color: var(--gray-600);
  margin-bottom: 20px;
  line-height: 1.5;
}

.result-details {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-900);
  padding: 10px 20px;
  background-color: var(--gray-100);
  border-radius: var(--border-radius-sm);
}
/* LiveAuctionRoom.css - Part 5: Bid History Section */

/* Bid history section */
.bid-history-section {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 25px;
  height: fit-content;
}

.bid-history-section h3 {
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-900);
  margin: 0 0 15px 0;
  border-bottom: 1px solid var(--gray-200);
  padding-bottom: 10px;
}

.bid-history-list {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--gray-300) var(--gray-100);
}

.bid-history-list::-webkit-scrollbar {
  width: 8px;
}

.bid-history-list::-webkit-scrollbar-track {
  background: var(--gray-100);
  border-radius: 10px;
}

.bid-history-list::-webkit-scrollbar-thumb {
  background-color: var(--gray-300);
  border-radius: 10px;
}

.bid-history-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 12px 8px;
  border-bottom: 1px solid var(--gray-200);
  transition: var(--transition);
}

.bid-history-item:last-child {
  border-bottom: none;
}

.bid-history-item:hover {
  background-color: var(--gray-50);
}

.bid-history-item.my-bid {
  background-color: var(--primary-light);
}

.bid-history-item.my-bid:hover {
  background-color: rgba(219, 234, 254, 0.8);
}

.bid-history-amount {
  font-weight: 600;
  color: var(--gray-900);
}

.bid-history-bidder {
  color: var(--gray-600);
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.auto-bid-tag {
  font-size: 10px;
  background-color: var(--secondary-light);
  color: var(--secondary-dark);
  padding: 2px 5px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
}

.bid-history-time {
  color: var(--gray-500);
  font-size: 14px;
  text-align: right;
}

.no-bids {
  color: var(--gray-500);
  text-align: center;
  padding: 30px 0;
  font-style: italic;
  background-color: var(--gray-50);
  border-radius: var(--border-radius-sm);
  margin-top: 10px;
}

/* Auction rules panel */
.auction-rules {
  margin-top: 30px;
  padding: 20px;
  background-color: var(--gray-50);
  border-radius: var(--border-radius-sm);
  border-left: 4px solid var(--primary-color);
}

.auction-rules h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--gray-900);
  margin: 0 0 10px 0;
}

.rule-list {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
}

.rule-list li {
  margin-bottom: 8px;
  color: var(--gray-600);
  font-size: 14px;
  line-height: 1.5;
}

.rule-list li:last-child {
  margin-bottom: 0;
}
/* LiveAuctionRoom.css - Part 6: Property Details */

/* Property details section */
.property-details {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid var(--gray-200);
}

.property-details h3 {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-900);
  margin: 0 0 15px 0;
}

.property-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-bottom: 20px;
}

.stat-item {
  background-color: var(--gray-50);
  padding: 15px;
  border-radius: var(--border-radius-sm);
  text-align: center;
  border: 1px solid var(--gray-200);
}

.stat-value {
  font-size: 22px;
  font-weight: 700;
  color: var(--gray-900);
  margin-bottom: 5px;
}

.stat-label {
  font-size: 14px;
  color: var(--gray-500);
}

.property-description {
  margin-bottom: 20px;
}

.property-description p {
  font-size: 15px;
  line-height: 1.6;
  color: var(--gray-700);
}

.property-details-table {
  background-color: var(--gray-50);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
  border: 1px solid var(--gray-200);
}

.detail-row {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-bottom: 1px solid var(--gray-200);
}

.detail-row:last-child {
  border-bottom: none;
}

.detail-label {
  font-size: 14px;
  color: var(--gray-600);
  font-weight: 500;
  padding: 12px 15px;
  background-color: rgba(0, 0, 0, 0.02);
  border-right: 1px solid var(--gray-200);
}

.detail-value {
  font-size: 14px;
  color: var(--gray-900);
  padding: 12px 15px;
}

/* Special styling for property images */
.property-images {
  margin-bottom: 20px;
}

.main-image {
  width: 100%;
  height: 300px;
  border-radius: var(--border-radius-sm);
  object-fit: cover;
  margin-bottom: 10px;
}

.thumbnail-container {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.thumbnail {
  width: 80px;
  height: 60px;
  border-radius: 4px;
  object-fit: cover;
  cursor: pointer;
  transition: var(--transition);
  border: 2px solid transparent;
}

.thumbnail:hover {
  opacity: 0.9;
}

.thumbnail.active {
  border-color: var(--primary-color);
}
/* LiveAuctionRoom.css - Part 7: Notifications and Alerts */

/* Bid confirmations and notifications */
.bid-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 350px;
  padding: 15px;
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-lg);
  animation: slide-in 0.3s ease-out forwards;
  z-index: 1000;
}

@keyframes slide-in {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

.notification-success {
  background-color: var(--secondary-light);
  border-left: 4px solid var(--secondary-color);
}

.notification-error {
  background-color: var(--error-light);
  border-left: 4px solid var(--error-color);
}

.notification-warning {
  background-color: var(--warning-light);
  border-left: 4px solid var(--warning-color);
}

.notification-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.notification-success .notification-title {
  color: var(--secondary-dark);
}

.notification-error .notification-title {
  color: var(--error-dark);
}

.notification-warning .notification-title {
  color: var(--warning-dark);
}

.notification-message {
  font-size: 14px;
}

.notification-success .notification-message {
  color: var(--secondary-dark);
}

.notification-error .notification-message {
  color: var(--error-dark);
}

.notification-warning .notification-message {
  color: var(--warning-dark);
}

/* Tooltips */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: var(--gray-800);
  color: white;
  text-align: center;
  border-radius: var(--border-radius-sm);
  padding: 8px 10px;
  position: absolute;
  z-index: 10;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  box-shadow: var(--box-shadow);
  line-height: 1.4;
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--gray-800) transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Connection status indicator */
.connection-status {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 8px 12px;
  border-radius: 20px;
  background-color: var(--gray-800);
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  box-shadow: var(--box-shadow);
  z-index: 100;
}

.status-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.status-dot.connected {
  background-color: var(--secondary-color);
  box-shadow: 0 0 5px var(--secondary-color);
}

.status-dot.disconnected {
  background-color: var(--error-color);
  box-shadow: 0 0 5px var(--error-color);
}

.status-dot.connecting {
  background-color: var(--warning-color);
  animation: pulse 1.5s infinite;
}
/* LiveAuctionRoom.css - Part 8: Responsive Design and Dark Mode */

/* Responsive adjustments */
@media (max-width: 1024px) {
  .live-auction-room {
    max-width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 768px) {
  .auction-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  
  .auction-status {
    margin-top: 10px;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
  }
  
  .status-item {
    align-items: flex-start;
  }
  
  .auction-main {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .bid-history-section {
    order: 2;
  }
  
  .property-stats {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .live-auction-room {
    margin: 20px auto;
    padding: 0 10px;
  }
  
  .auction-header {
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
  
  .property-info h1 {
    font-size: 22px;
  }
  
  .property-location {
    font-size: 14px;
    flex-wrap: wrap;
  }
  
  .status-value.time {
    font-size: 20px;
  }
  
  .auction-status {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .bid-amount {
    font-size: 28px;
  }
  
  .input-group {
    flex-direction: column;
  }
  
  .auto-bid-inputs {
    flex-direction: column;
  }
  
  .bid-history-item {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 4px;
  }
  
  .bid-history-time {
    grid-column: 1 / -1;
    text-align: left;
    color: var(--gray-400);
    font-size: 12px;
  }
  
  .property-stats {
    grid-template-columns: 1fr;
  }
  
  .detail-row {
    grid-template-columns: 1fr;
  }
  
  .detail-label {
    border-right: none;
    border-bottom: 1px solid var(--gray-200);
    padding: 8px 12px;
  }
  
  .detail-value {
    padding: 8px 12px;
  }
  
  .auction-result-banner {
    padding: 15px;
  }
  
  .result-title {
    font-size: 20px;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  :root {
    --primary-light: #1e3a8a;
    --secondary-light: #064e3b;
    --warning-light: #422006;
    --error-light: #450a0a;
    --gray-50: #1f2937;
    --gray-100: #111827;
    --gray-200: #374151;
    --gray-300: #4b5563;
    --gray-400: #6b7280;
    --gray-500: #9ca3af;
    --gray-600: #d1d5db;
    --gray-700: #e5e7eb;
    --gray-800: #f3f4f6;
    --gray-900: #f9fafb;
  }
  
  .live-auction-room {
    color: var(--gray-200);
  }
  
  .live-auction-loading,
  .live-auction-error,
  .bidding-section,
  .bid-history-section,
  .auction-result-banner {
    background-color: var(--gray-800);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
  
  .loader {
    border-color: var(--gray-700);
    border-top-color: var(--primary-color);
  }
  
  .property-info h1,
  .bid-history-section h3,
  .bidding-section h3,
  .current-bid h2,
  .bid-increments h4,
  .property-details h3,
  .auction-rules h4 {
    color: var(--gray-100);
  }
  
  .property-location {
    color: var(--gray-400);
  }
  
  .status-value {
    color: var(--gray-200);
  }
  
  .status-value.time {
    background-color: var(--gray-900);
  }
  
  .current-bid .bid-amount {
    color: var(--primary-color);
  }
  
  .bidder,
  .status-label {
    color: var(--gray-400);
  }
  
  input {
    background-color: var(--gray-900);
    border-color: var(--gray-700);
    color: var(--gray-200);
  }
  
  input::placeholder {
    color: var(--gray-500);
  }
  
  .bid-history-item {
    border-bottom-color: var(--gray-700);
  }
  
  .bid-history-item:hover {
    background-color: var(--gray-900);
  }
  
  .bid-history-item.my-bid {
    background-color: var(--primary-light);
  }
  
  .bid-history-item.my-bid:hover {
    background-color: rgba(30, 58, 138, 0.8);
  }
  
  .bid-history-amount {
    color: var(--gray-100);
  }
  
  .bid-history-bidder {
    color: var(--gray-400);
  }
  
  .bid-history-time {
    color: var(--gray-500);
  }
  
  .no-bids {
    color: var(--gray-400);
    background-color: var(--gray-900);
  }
  
  .auto-bidding {
    background-color: var(--gray-900);
    border-color: var(--gray-700);
  }
  
  .auto-bid-description {
    color: var(--gray-400);
  }
  
  .auto-bid-active {
    background-color: var(--secondary-light);
    color: var(--secondary-color);
  }
  
  .auction-rules {
    background-color: var(--gray-900);
    border-left-color: var(--primary-color);
  }
  
  .rule-list li {
    color: var(--gray-400);
  }
  
  .property-details-table {
    background-color: var(--gray-900);
    border-color: var(--gray-700);
  }
  
  .detail-row {
    border-bottom-color: var(--gray-700);
  }
  
  .detail-label {
    background-color: rgba(0, 0, 0, 0.2);
    border-right-color: var(--gray-700);
    border-bottom-color: var(--gray-700);
    color: var(--gray-400);
  }
  
  .detail-value {
    color: var(--gray-300);
  }
  
  .stat-item {
    background-color: var(--gray-900);
    border-color: var(--gray-700);
  }
  
  .stat-value {
    color: var(--gray-100);
  }
  
  .stat-label {
    color: var(--gray-500);
  }
  
  .property-description p {
    color: var(--gray-300);
  }
  
  .increment-button {
    background-color: var(--gray-900);
    border-color: var(--gray-700);
    color: var(--gray-300);
  }
  
  .increment-button:hover {
    background-color: var(--gray-800);
  }
  
  .increment-button.recommended {
    background-color: var(--primary-light);
    color: var(--gray-100);
  }
  
  .property-details-toggle {
    background-color: var(--gray-900);
    border-color: var(--gray-700);
    color: var(--gray-300);
  }
  
  .property-details-toggle:hover {
    background-color: var(--gray-800);
  }
}