.event-detail-container{
    /* background-color: red; */
    margin-top: 20px;
    color: #263238;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 2px solid rgb(240 242 244);
    padding-bottom: 15px;
}
.event-detail-container-heading{
    font-weight: 600;
    font-size: 20px;
}
.event-detail-container-data{
    font-size: 16px;
    font-weight: 500;
}