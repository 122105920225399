
.about-main-container{
    height: 70vh;
    top: 80px;
    width: 100%;
    display: flex;
    /* height: 80%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;


}
.about-us-background-image{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 73%;
    object-fit: cover;
    z-index: -2;
}
.about-us-background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 73%;
    object-fit:cover;
    z-index: -2;
}
.dark-overlay-about-us {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 73%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
    pointer-events: none;
    /* Dark, semi-transparent overlay */
    z-index: -1;
}

.about-main-container p{
    font-size: 48px;
    font-weight: 500;
    line-height: 40px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    color: white;
    text-align: center;
    width: 95%;

}
.about-main-container a{
    background: rgb(0, 128, 247);
    color: white;
    text-decoration: none;
    border-radius: 5px;
    padding: 13px  50px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
}
.about-main-container a:hover{
    background: rgb(2, 104, 200);
}
@media screen and (max-width:1000px){
    .about-main-container p{
         font-size: 40px;
    }
}
@media screen and (max-width:415px){
    .about-main-container p{
         font-size: 30px;
    }
}