/* Navbar.css */
.property-detail-navbar-container {
  position: fixed;
  top: 50;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 997;
  background-color: white;
  padding-top: 10px;
}
.property-detail-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 20px;
  z-index: -1;
}

.property-detail-navbar-left {
  display: flex;
}
.property-save-button {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #d1deea;
  border-radius: 8px;
  cursor: pointer;
}
.property-save-button:hover {
  background-color: #f3f4f6;
}
.property-detail-navbar-item {
  margin-right: 20px;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  color: #000;
}

.property-detail-navbar-item.active {
  font-weight: bold;
  border-bottom: 3px solid #007bff;
  /* Blue underline when active */
}

.property-detail-navbar-item:hover {
  color: #007bff;
}

.property-detail-navbar-right {
  display: flex;
  gap: 20px;
  z-index: 1;
}

.property-detail-navbar-icon {
  font-size: 20px;
  cursor: pointer;
  color: #007bff;
  margin-right: 3px;
}
@media screen and (max-width: 900px) {
  .property-detail-navbar {
    width: 90%;
  }
  .breadcrumb-nav {
    width: 90%;
  }
}
@media screen and (max-width: 720px) {
  .property-detail-navbar {
    display: none;
  }
}
